import { ChatMessage } from 'src/models/chat';
import { PostEntity, PostMediaType } from 'src/models/post';
import { LocationAreaEntity } from 'src/pages/location-area/LocationArea';
import { Base64 } from 'js-base64';
import { appConfig } from 'src/app-config';
import { CommonUser } from 'src/models/user';
import { LocationEntity, getLocationName } from 'src/models/location';
import { RequestEntity } from 'src/models/request';

// export const AIBotUserID = "anylocal-ai-bot";

export const IsAI = (user) => {
  return user.type === "AI";
}

export const IsVisitor = (user) => {
  return user.type === "visitor";
}

export enum MessageType {
  AI_Answer = "ai_answer",
  Audio = "audio",
  Video = "video",
  Image = "image",
  Text = "text",
  Share_Post = "share_post",
} ;

export const getPostLabel = (row: ChatMessage, t) => {
  const post = JSON.parse(row.content) as PostEntity;
  if (post) {
    if (post.message_content?.title || post.message_content?.description) {
      const title = post.message_content?.title || '';
      const description = post.message_content?.description || '';
      return title && description ? `${title} ${description}` : title || description;
    } else {
      const uris = post.message_content.uri;
      if (uris?.length > 0) {
        const media1 = uris[0];
        if (media1.type === PostMediaType.Photo) {
          return `${uris?.length} ${uris?.length > 1 ? t("app.post.photos") : t("app.post.photo")}`;
        } else if (media1.type === PostMediaType.Video) {
          return `${uris?.length} ${uris?.length > 1 ? t("app.post.videos") : t("app.post.video")}`;
        } else {
          return "";
        }
      } else {
        return t("app.post.no-content");
      }
    }
  } else {
    return "";
  }
}

export const handleClickLocationArea = (row: ChatMessage) => {
  const locationArea = JSON.parse(row.content) as LocationAreaEntity;
  let queryParams = "";
  if (locationArea?.message_id) {
    const encodeLocName = locationArea?.region_name ? Base64.encodeURI(locationArea?.region_name) : "";
    queryParams = `?loc_name=${encodeLocName}&message_id=${locationArea?.message_id}&level=${locationArea.level}`;
  } else {
    const encodeLocName = locationArea?.loc_name ? Base64.encodeURI(locationArea?.loc_name) : "";
    queryParams = `?loc_name=${encodeLocName}`;
  }
  window.open(`${window.location.protocol}//${window.location.host}${appConfig.paths.locationArea}${queryParams}`, "_blank");
}

export const getSharedLocationAreaName = (row: ChatMessage) => {
  const location = JSON.parse(row.content) as LocationAreaEntity;
  return location.loc_name || location.region_name || location.region_full_name;
}

export const getSharedUserName = (row: ChatMessage) => {
  const user = JSON.parse(row.content) as CommonUser;
  return user ? (user.user_name || user.user_id) : "";
}

export const locationData = (row: ChatMessage) => {
  return JSON.parse(row.content) as LocationEntity;
}

export const getSharedLocationLinkName = (row: ChatMessage) => {
  const location = locationData(row);
  return location?.loc_name;
}

export const getSharedLocationName = (row: ChatMessage) => {
  const location = locationData(row);
  return getLocationName(location);
}

export const getSharePostName = (row: ChatMessage) => {
  const user = JSON.parse(row.content) as PostEntity;
  return user ? (user?.owner?.user_name || user?.owner?.user_id) : "";
}

export const requestData = (row: ChatMessage) => {
  return JSON.parse(row.content) as RequestEntity;
}

export const getSharedRequestName = (row: ChatMessage) => {
  const request = requestData(row);
  return request.message_content.description || "None";
}

export const getDisplayUserName = (row, i18n, visitorName?: string) => {
  const userName = row?.sender_name || row?.user_name;
  const userId = row?.sender_id || row?.user_id;
  if (IsVisitor({ type: row.sender_type || row.type })){
    return visitorName || row.sender_name_cn || row.user_name_cn || userName;
  }
  if (IsAI({ type: row.sender_type || row.type }) && i18n.language === "zh-CN") {
    return row.sender_name_cn || row.user_name_cn || userName;
  } else {
    if (!userName) {
      return userId;
    } else if (userName === userId) {
      return userName.trim();
    } else if (userName.includes("##")) {
      return userName.replace("##", " ").trim();
    } else {
      return userName.trim();
    }
  }
}

export const getRoomName = (room, t) => {
  const suffix = t("app.chatroom.chatroom");
  return (room.name || room.city || room.province || room.nation) + suffix;
}

export const welcomeMessageID = "ai-welcome-message";