import { toolkitStore } from "../toolkit/store";
import { clearIdentity, setIdentity } from 'src/service/redux/app-slice';
import { clearRefreshToken, clearToken } from '../../service/redux/access-slice';



export const logout = () => {
  toolkitStore.dispatch(setIdentity(undefined));
  toolkitStore.dispatch(clearIdentity())
  toolkitStore.dispatch(clearToken())
  toolkitStore.dispatch(clearRefreshToken())
}