import { createApi } from "@reduxjs/toolkit/query/react";
import { Response, Result, Value } from "../../models/common";
import { baseQueryWithReauth } from "../../tools/toolkit/helper";

import {
  RequestResponse,
  RequestTotalResponse,
} from "src/models/admin-request";

export const adminRequestApi = createApi({
  reducerPath: "adminRequest",
  baseQuery: baseQueryWithReauth,
  endpoints(builder) {
    return {
      getRequestList: builder.mutation<Result<RequestResponse>, {
        sort: number,
        status: number,
        startKey: string
      }>({
        query: ({
          sort,
          status,
          startKey
        }) => ({
          url: `trade_market`,
          method: "POST",
          body: {
            request_type: "admin_query_trade",
            request_data: {
              trade_type: "request",
              sort_by: sort,
              status: status,
              page_size: 6,
              forward_order: false,
              start_key: startKey,
              gps: ""
            }
          },
        }),
        transformResponse: (res: Response<RequestResponse>) => new Value(res),
      }),
      getRequestTotal: builder.mutation<Result<RequestTotalResponse>, {}>({
        query: () => ({
          url: `trade_market`,
          method: "POST",
          body: {
            request_type:"admin_count"
          },
        }),
        transformResponse: (res: Response<RequestTotalResponse>) =>
          new Value(res),
      }),
    };
  },
});

export const { useGetRequestListMutation, useGetRequestTotalMutation } =
  adminRequestApi;
