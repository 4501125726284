import { createApi } from "@reduxjs/toolkit/query/react";
import { Response, Result, Value } from "../../models/common";
import { baseQueryWithReauth } from "../../tools/toolkit/helper";
import { NewsDetailResponseData, NewsListResponseData } from "src/models/news";

export const newsApi = createApi({
  reducerPath: "news",
  baseQuery: baseQueryWithReauth,
  endpoints(builder) {
    return {
      getNewsDetail: builder.mutation<
        Result<NewsDetailResponseData>,
        { id: string }
      >({
        query: ({ id }) => ({
          url: `announcement`,
          method: "POST",
          body: {
            operation: "getannouncementbyid",
            announcement_id: id,
          },
        }),
        transformResponse: (res: Response<NewsDetailResponseData>) =>
          new Value(res),
      }),
      getUserNewsList: builder.mutation<
        Result<NewsListResponseData>,
        { pageSize: number; startKey: string }
      >({
        query: ({ pageSize, startKey }) => ({
          url: `announcement`,
          method: "POST",
          body: {
            operation: "getannouncementlist",
            pagesize: pageSize,
            startkey: startKey,
          },
        }),
        transformResponse: (res: Response<NewsListResponseData>) =>
          new Value(res),
      }),
    };
  },
});

export const { useGetNewsDetailMutation, useGetUserNewsListMutation } = newsApi;
