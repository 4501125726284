import { Tab, Theme, Tabs, } from "@mui/material";
import { useEffect, useState } from "react";
import { appConfig } from "src/app-config";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import { paths } from "src/app-config";
import { makeStyles } from "tss-react/mui";
import IcoHome from "src/images/main/home/ico-home.svg"
import IcoHomeSelected from "src/images/main/home/ico-home-selected.svg"
import IcoMarket from "src/images/main/home/ico-market.svg"
import IcoMarketSelected from "src/images/main/home/ico-market-selected.svg"
import IcoWorldView from "src/images/main/home/ico-worldview.svg"
import IcoWorldViewSelected from "src/images/main/home/ico-worldview-selected.svg"
import IcoFollowing from "src/images/main/home/ico-following.svg"
import IcoFollowingSelected from "src/images/main/home/ico-following-selected.svg"
import IcoAbout from "src/images/main/home/ico-about.svg"
import IcoAboutSelected from "src/images/main/home/ico-about-selected.svg"
import IcoChatSelected from "src/images/chatroom/ico-chat-selected.svg"
import IcoChat from "src/images/chatroom/ico-chat.svg"
import IcoTravel from "src/images/travel/ico-travel.svg"
import IcoTravelSelected from "src/images/travel/ico-travel-selected.svg"
import clsx from "clsx";

const useStyles = makeStyles()((theme: Theme) => {
  return {
    tab: {
      color: "#717171",
      background: "white",
      fontSize: "10px !important",
      textTransform: "none",
      fontFamily: "Helvetica Neue !important",
      minWidth: "40px",
      margin: "0 20px",
      paddingTop: '0px',
      paddingBottom: '0px',
      minHeight: "48px",
      "&.Mui-selected": {
        color: "black",
      },
      [theme.breakpoints.down("md")]: {
        margin: "0 10px",
      }
    },
  };
});

export const MainTabValue = {
  None: false,
  Home: 0,
  Market: 1,
  Worldview: 2,
  Following: 3,
  AboutUs: 4,
  ChatroomList: 5,
  Travel:6
};

interface Props {
  hasUnreadChatroomMessage: boolean
}

const MainTab = ({ hasUnreadChatroomMessage }: Props) => {
  const { t, i18n } = useTranslation();
  const { classes } = useStyles();
  const [tabValue, setTabValue] = useState<boolean | number>(MainTabValue.None);
  const location = useLocation();

  useEffect(() => {
    const pathSegments = "/" + location.pathname.split("/")[1];
    if (location.pathname === appConfig.paths.root) {
      setTabValue(MainTabValue.Home);
    } else if (location.pathname === appConfig.paths.following) {
      setTabValue(MainTabValue.Following);
    } else if (
      location.pathname.startsWith(appConfig.paths.worldview)) {
      setTabValue(MainTabValue.Worldview);
    } else if (pathSegments === appConfig.paths.market) {
      setTabValue(MainTabValue.Market);
    } else if (pathSegments === appConfig.paths.chatroomList) {
      setTabValue(MainTabValue.ChatroomList);
    } else if (pathSegments === appConfig.paths.travel){
      setTabValue(MainTabValue.Travel);
    }
  }, [location]);

  return (

    <Tabs
      value={tabValue}
      variant="scrollable"
      scrollButtons
      allowScrollButtonsMobile
      // onChange={handleChange}
      TabIndicatorProps={{ children: <span /> }}
      sx={{ minHeight: "60px", marginTop: "14px" }}
    >
      <Tab
        icon={<img src={tabValue === MainTabValue.Home ? IcoHomeSelected : IcoHome} alt="home" />}
        className={classes.tab}
        component={Link}
        to={paths.root}
        aria-label={t("app.home.for_you")}
        value={MainTabValue.Home}
        label={t("app.home.for_you")}
      />

      <Tab
        icon={<img src={tabValue === MainTabValue.Travel ? IcoTravelSelected : IcoTravel} alt="travel" />}
        className={classes.tab}
        component={Link}
        to={paths.travel}
        aria-label={t("app.home.travel")}
        value={MainTabValue.Travel}
        label={t("app.home.travel")}
      />

      <Tab
        icon={<img src={tabValue === MainTabValue.ChatroomList ? IcoChatSelected : IcoChat} alt="chatroom" />}
        sx={{
          position: 'relative',
          '&.show-badge::before': {
            content: '""',
            position: 'absolute',
            top: '0px',
            right: i18n.language === 'zh-CN' ? '13px' : "22px",
            width: '6px',
            height: '6px',
            borderRadius: '50%',
            background: 'rgba(239, 62, 62, 1)',
          }
        }}
        className={clsx(classes.tab, { "show-badge": hasUnreadChatroomMessage && tabValue != MainTabValue.ChatroomList })}
        component={Link}
        to={paths.chatroomList}
        aria-label={t("app.home.chatroom")}
        value={MainTabValue.ChatroomList}
        label={t("app.home.chatroom")}
      />

      <Tab
        icon={<img src={tabValue === MainTabValue.Market ? IcoMarketSelected : IcoMarket} alt="market" />}
        className={classes.tab}
        component={Link}
        to={paths.market}
        aria-label={t("app.home.market")}
        value={MainTabValue.Market}
        label={t("app.home.market")}
      />

      <Tab
        icon={<img src={tabValue === MainTabValue.Worldview ? IcoWorldViewSelected : IcoWorldView} alt="worldview" />}
        className={classes.tab}
        component={Link}
        to={paths.worldview}
        aria-label={t("app.home.world-view")}
        value={MainTabValue.Worldview}
        label={t("app.home.world-view")}
      />

      <Tab
        icon={<img src={tabValue === MainTabValue.Following ? IcoFollowingSelected : IcoFollowing} alt="following" />}
        className={classes.tab}
        component={Link}
        to={paths.following}
        aria-label={t("app.home.following")}
        value={MainTabValue.Following}
        label={t("app.home.following")}
      />

      <Tab
        icon={<img src={tabValue === MainTabValue.AboutUs ? IcoAboutSelected : IcoAbout} alt="about" />}
        className={classes.tab}
        component={Link}
        to={paths.aboutUs}
        aria-label={t("app.footer.aboutUs")}
        value={MainTabValue.AboutUs}
        label={t("app.footer.aboutUs")}
      />
    </Tabs>
  );
};

export default MainTab;
