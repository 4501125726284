import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { settings } from "../settings";
const keys = settings.keys;

interface SearchState {
    // searchValue: string;
    searchHistory: string[];
}

const storedState = localStorage.getItem(keys.search);
const initialState: SearchState = storedState
    ? JSON.parse(storedState)
    : {
        // searchValue: "",
        searchHistory: [],
    };

const searchSlice = createSlice({
    name: "search",
    initialState,
    reducers: {
        // setSearchValue(state, action: PayloadAction<string>) {
        //     state.searchValue = action.payload;
        //     localStorage.setItem(keys.search, JSON.stringify(state));
        // },
        // clearSearchValue(state) {
        //     state.searchValue = "";
        //     localStorage.setItem(keys.search, JSON.stringify(state));
        // },
        addSearchHistory(state, action: PayloadAction<string>) {
            const newHistory = action.payload;
            // Remove the item if it already exists
            const filteredHistory = state.searchHistory.filter(
                (item) => item !== newHistory
            );
            // Add new item to the front and limit to 6 items
            state.searchHistory = [newHistory, ...filteredHistory].slice(0, 8);
            localStorage.setItem(keys.search, JSON.stringify(state));
        },
        clearSearchHistory(state) {
            state.searchHistory = [];
            localStorage.setItem(keys.search, JSON.stringify(state));
        },
        removeSearchHistoryItem(state, action: PayloadAction<string>) {
            state.searchHistory = state.searchHistory.filter(
                (item) => item !== action.payload
            );
            localStorage.setItem(keys.search, JSON.stringify(state));
        },
    },
});

export const {
    // setSearchValue,
    // clearSearchValue,
    addSearchHistory,
    clearSearchHistory,
    removeSearchHistoryItem,
} = searchSlice.actions;

export default searchSlice.reducer;
