import { type FC, type ReactNode } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { type RootState } from 'src/tools/toolkit/store';

interface ProtectedRouteProps {
  children: ReactNode;
  redirectTo?: string;
}

const ProtectedRoute: FC<ProtectedRouteProps> = ({ children, redirectTo = '/' }) => {
  const location = useLocation();
  const { credential } = useSelector((state: RootState) => state.app);
  if (!credential) {
    return <Navigate to={redirectTo} state={{ from: location }} replace />;
  }
  return <>{children}</>;
};

export default ProtectedRoute;
