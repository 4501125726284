import { Button } from '@mui/material';
import { memo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import SvgComponent from 'src/components/svgComponent';
import { paths } from 'src/app-config';

const appId = 'wx879765cd52004796'

interface Props {
    handleSuccess: (code: string) => void;
}

let listenerCallback;


const WechatLoginButton: React.FC<Props> = ({ handleSuccess }) => {
    const { t } = useTranslation();
    // const redirectUri = `${window.location.origin}${paths.wechatLoginCallback}`;
    const redirectUri = `https://www.anylocal.cn${paths.wechatLoginCallback}`;

    const handleClick = () => {

        const screenX = window.screenX !== undefined ? window.screenX : window.screenLeft;
        const screenY = window.screenY !== undefined ? window.screenY : window.screenTop;
        const windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        const windowHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

        const openWindowWidth = windowWidth > 700 ? 700 : windowWidth;
        const openWindowHeight = windowHeight > 700 ? 700 : windowHeight;


        const left = screenX + (windowWidth - openWindowWidth) / 2;
        const top = screenY + (windowHeight - openWindowHeight) / 2;
        const wechatUrl = `https://open.weixin.qq.com/connect/qrconnect?appid=${appId}&redirect_uri=${encodeURIComponent(redirectUri)}&response_type=code&scope=snsapi_login&state=STATE#wechat_redirect`
        window.open(wechatUrl, 'wechatWindow', `width=${openWindowWidth},height=${openWindowHeight},left=${left},top=${top}`);
    }

    useEffect(() => {

        if (listenerCallback) {
            console.log('remove listenerCallback', listenerCallback);
            window.removeEventListener('message', listenerCallback);
        }

        listenerCallback = (event) => {
            if (event.data?.type === 'wechatLogin' && event.data?.code) {
                console.log('WechatLoginButton event.data:', event.data);
                handleSuccess(event.data.code)
            }
        }
        window.addEventListener('message', listenerCallback);
    }, [])

    return (
        <Button size="large" variant="text" onClick={handleClick}
            sx={{
                textTransform: 'none', color: '#000000', fontFamily: 'Helvetica Neue', fontStyle: 'normal', fontWeight: 400, fontSize: '14px', textAlign: "center",
                height: '44px', marginBottom: '20px', whiteSpace: 'nowrap', width: '100%',
                background: '#F9F9F9',
                border: '1px solid #E6E6E6', borderRadius: '22px', boxShadow: 'none',
                mixBlendMode: 'normal',
                position: "relative",
            }}>
            <SvgComponent iconName="ico-wechat" style={{ width: '20px', height: '20px', position: "absolute", left: "20px", }} />
            {t("app.login.wechat-login")}
        </Button>
    )
}

export default memo(WechatLoginButton);