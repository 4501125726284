import { useEffect } from "react";
import { isCN } from 'src/tools/utils/appUtil';
import { useDispatch } from 'react-redux';
import { setGoogleLoad, setBaiduLoad } from "src/service/redux/map-load-slice";

const useMapLoader = () => {
  const dispatch = useDispatch();

  // useEffect(() => {
  //   if (isCN) {
  //     const loadBingMap = async () => {
  //       try {
  //         await new Promise<void>((resolve, reject) => {
  //           const script = document.createElement('script');
  //           script.setAttribute("type", "text/javascript");
  //           script.setAttribute(
  //             "src",
  //             `https://www.bing.com/api/maps/mapcontrol?callback=bingMapsCallback&nocahe=${Date.now()}`
  //           );
  //           script.async = true;
  //           script.defer = true;
  //           script.onerror = (error) => reject(error);
  //           (window as any).bingMapsCallback = () => {
  //             resolve();
  //           };
  //           document.head.appendChild(script);
  //         })
  //         dispatch(setBingLoad(true));
  //       } catch (error) {
  //         console.error('Failed to load Bing Maps SDK:', error);
  //       }
  //     }
  //     loadBingMap();
  //   }
  // }, [])

  useEffect(() => {
    if (isCN) {
      const loadGoogleMap = async () => {
        try {
          await new Promise<void>((resolve, reject) => {
            const script = document.createElement('script');
            script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAP_API_KEY}&callback=__googleMapsCallback__&nocahe=${Date.now()}`;
            script.async = true;
            script.defer = true;
            script.onerror = (error) => reject(error);

            (window as any).__googleMapsCallback__ = () => {
              resolve();
            };

            document.head.appendChild(script);
          })
          dispatch(setGoogleLoad(true));
        } catch (error) {
          console.error('Failed to load Google Maps SDK:', error);
        }
      }
      loadGoogleMap();
    }
  }, []);

  useEffect(() => {
    if (isCN) {
      const loadBaiduMap = async () => {
        try {
          await new Promise<void>((resolve, reject) => {
            const script = document.createElement('script');
            script.src = `https://api.map.baidu.com/api?v=1.0&type=webgl&ak=Ysj0aNt1fXZ3ptjAjybfoxxD3DkrRE5a&callback=__baiduMapsCallback__&nocahe=${Date.now()}`;
            script.async = true;
            script.defer = true;
            script.onerror = (error) => reject(error);

            (window as any).__baiduMapsCallback__ = () => {
              resolve();
            };

            document.head.appendChild(script);
          })
          dispatch(setBaiduLoad(true));
        } catch (error) {
          console.error('Failed to load Baidu Maps SDK:', error);
        }
      }
      loadBaiduMap();
    }
  }, []);

}

export default useMapLoader;