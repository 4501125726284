import { Suspense, type ComponentType, lazy, type PropsWithoutRef, type PropsWithRef, ReactNode } from "react";
// import Loader from "./Loader";
import LineLoader from "./LineLoader";

const Loadable = <P extends object>(
  componentPath: () => Promise<{ default: ComponentType<P> }>,
) => {
  return function LoadableInner(props: PropsWithoutRef<P> & PropsWithRef<P>) {
    const LazyComponent = lazy(componentPath);

    return (
      <Suspense fallback={<LineLoader />}>
        <LazyComponent {...props} />
      </Suspense>
    );
  };
};

export default Loadable;
