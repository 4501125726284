// import * as crypto from 'crypto';
import CryptoJS from "crypto-js";
import { TFunction } from "i18next";
import numeral from 'numeral';
import md5 from 'md5';

export const EMAIL_FORMAT =
  /^[a-zA-Z\d]+([-_.][a-zA-Z\d]+)*@[a-zA-Z-\d]+\.[a-zA-Z\d]{2,4}$/;
export const PASSWORD_FORMAT = /(?=.*[A-Z])(?=.{6,50}$)(?=.*[0-9])/;
export const US_PHONE_FORMAT = /^\d{10}$/;
export const CN_PHONE_FORMAT = /^\d{11}$/;
export const USERID_FORMAT = /^(?!.*(_cn|_com)$)[A-Z0-9a-z_-]{1,30}$/;

export const isNumber = (v: any) => typeof v === "number";

export const isNull = (v: any) => v === undefined || v === null;

export const getDisplayUserName = (userName: string, userId: string) => {
  if (!userName) {
    return userId;
  } else if (userName === userId) {
    return userName.trim();
  } else if (userName.includes("##")) {
    return userName.replace("##", " ").trim();
  } else {
    return userName.trim();
  }
};

/**
 * get description about how long ago
 * @param timestamp seconds
 * @returns
 */
export const howLongAgo = (timestamp: number, t: TFunction) => {
  const current = new Date().getTime() / 1000;
  const ago = current - timestamp;
  if (ago < 10) {
    return t("app.time.just-now");
  }
  if (ago < 60) {
    return t("app.time.seconds-ago", { seconds: Math.floor(ago) });
  }
  if (ago < 60 * 60) {
    const num = Math.floor(ago / 60);
    return num === 1
      ? t("app.time.1-minute-ago")
      : t("app.time.minutes-ago", { minutes: num });
  }
  if (ago < 60 * 60 * 24) {
    const num = Math.floor(ago / 60 / 60);
    return num === 1
      ? t("app.time.1-hour-ago")
      : t("app.time.hours-ago", { hours: num });
  }
  if (ago < 60 * 60 * 24 * 7) {
    const num = Math.floor(ago / 60 / 60 / 24);
    return num === 1
      ? t("app.time.1-day-ago")
      : t("app.time.days-ago", { days: num });
  }
  if (ago < 60 * 60 * 24 * 7 * 4) {
    const num = Math.floor(ago / 60 / 60 / 24 / 7);
    return num === 1
      ? t("app.time.1-week-ago")
      : t("app.time.weeks-ago", { weeks: num });
  }
  if (ago < 60 * 60 * 24 * 7 * 4 * 12) {
    const num = Math.floor(ago / 60 / 60 / 24 / 7 / 4);
    return num === 1
      ? t("app.time.1-month-ago")
      : t("app.time.months-ago", { months: num });
  }
  const num = Math.floor(ago / 60 / 60 / 24 / 7 / 4 / 12);
  return num === 1
    ? t("app.time.1-year-ago")
    : t("app.time.years-ago", { years: num });
};

export const getFormatDate = (timeMillionSeconds: number) => {
  var date = new Date(timeMillionSeconds);
  var year = date.getFullYear();
  var mon = date.getMonth() + 1;
  var day = date.getDate();
  return year + "-" + mon + "-" + day;
};

export const isJSON = (str: string): boolean => {
  if (typeof str == "string") {
    try {
      var obj = JSON.parse(str);
      if (typeof obj == "object" && obj) {
        return true;
      } else {
        return false;
      }
    } catch (e) {
      return false;
    }
  }
  return false;
};

export const checkNumber = (str) => {
  var reg = /^[0-9]+.?[0-9]*$/;
  if (reg.test(str)) {
    return true;
  }
  return false;
};

export const getUrlQuery = (url: string): Record<string, string> => {
  const result: Record<string, string> = {};
  if (url.length > 0) {
    if (url[0] === "?") {
      url = url.substring(1);
      const urlList = url.split("&");
      urlList.forEach((item) => {
        const equalIndex = item.indexOf("=");
        const key = item.substring(0, equalIndex);
        const value = item.substring(equalIndex + 1);

        // let list = item.split('=');
        result[key] = value;
      });
    }
  }
  return result;
};

export const getLegalFileName = (fileName: string) => {
  if (!fileName) {
    return "";
  }
  const index = fileName.lastIndexOf(".");
  if (index === -1) {
    return replaceIllegalCharecter(fileName);
  } else {
    const preName = fileName.substring(0, index);
    const ext = fileName.substr(index + 1);
    return replaceIllegalCharecter(preName) + "." + ext;
  }
};

const replaceIllegalCharecter = (str: string) => {
  return str ? str.replace(/[^a-zA-Z\d\u4e00-\u9fa5_-]/g, "_") : "";
};

export const formatFileSize = (bytes: number, decimalPoint = 2) => {
  if (bytes === 0) return "0 Bytes";
  let k = 1000,
    sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
    i = Math.floor(Math.log(bytes) / Math.log(k));
  return (
    parseFloat((bytes / Math.pow(k, i)).toFixed(decimalPoint)) + " " + sizes[i]
  );
};

export const extractPlainTextFromRichText = (
  htmlString: string | undefined | null
) => {
  return htmlString ? htmlString.replace(/<[^>]+>/g, "") : "";
};

export const getNumberDesc = (num: number) => {
  if (num > 10 * 1000) {
    return "10K+";
  } else if (num > 1000) {
    return "1K+";
  } else {
    return num || 0;
  }
};

export const formatNumber = (number) => {
  const formattedNumber = numeral(number).format('0,0.00');
  return formattedNumber;
}

export const emailCheck = (email: string): boolean => {
  return EMAIL_FORMAT.test(email);
};

export const uuid = () => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0;
    const v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

export const MD5 = (): string => {
  return md5(uuid());
}

export const getHash = (file): Promise<string> => {
  return new Promise((resolve) => {
    const reader = new FileReader();

    reader.onload = (e) => {
      const data = e.target?.result;
      // @ts-ignore
      const wordArray = CryptoJS.lib.WordArray.create(data);
      const hash = CryptoJS.SHA256(wordArray);
      resolve(hash.toString(CryptoJS.enc.Hex));
    };

    reader.readAsArrayBuffer(file);
  });
};

export const getDistanceText = (num: number) => {
  if (num < 1000) {
    return `${num.toFixed(0)}m`;
  } else if (num < 1000 * 1000) {
    return `${Math.floor(num / 1000)}km`;
  } else {
    return "1000+ km";
  }
};


export const checkHasMore = (startKey: string): boolean => {
  return typeof startKey === "string" && startKey.length > 0
}