import { createApi } from "@reduxjs/toolkit/query/react";
import { Response, Result, Value } from "../../models/common";
import { baseQueryWithReauth } from "../../tools/toolkit/helper";
import { NotificationTypeEnum } from "src/models/notification";

export const notificationApi = createApi({
  reducerPath: "notification",
  baseQuery: baseQueryWithReauth,
  endpoints(builder) {
    return {
      getNotificationList: builder.mutation<Result<any>, {}>({
        query: () => ({
          url: `getnotificationlist`,
          method: "POST",
        }),
        transformResponse: (res: Response<any>) => new Value(res),
      }),
      getNotificationMessageList: builder.mutation<
        Result<any>,
        { userId: string }
      >({
        query: ({ userId }) => ({
          url: `/dialog/get_user_dialog`,
          method: "POST",
          body: {
            user_id: userId,
          },
        }),
        transformResponse: (res: Response<any>) => new Value(res),
      }),
      getNotificationTypeList: builder.mutation<
        Result<any>,
        { type: Array<string>; pageSize?: number }
      >({
        query: ({ type, pageSize }) => ({
          url: `getnotificationlist`,
          method: "POST",
          body: {
            type,
            pagesize: pageSize || 99,
          },
        }),
        transformResponse: (res: Response<any>) => new Value(res),
      }),
      readNotificationType: builder.mutation<
        Result<any>,
        { notificationType: NotificationTypeEnum }
      >({
        query: ({ notificationType }) => ({
          url: `updatenotificationstatus`,
          method: "POST",
          body: {
            read_number: 0,
            type: notificationType,
          },
        }),
        transformResponse: (res: Response<any>) => new Value(res),
      }),
      readNotification: builder.mutation<
        Result<any>,
        { notificationId: string }
      >({
        query: ({ notificationId }) => ({
          url: `updatenotificationstatus`,
          method: "POST",
          body: {
            is_read: true,
            notification_id: notificationId,
          },
        }),
        transformResponse: (res: Response<any>) => new Value(res),
      }),
      deleteUserDialog: builder.mutation<
        Result<any>,
        { dialogId: string }
      >({
        query: ({ dialogId }) => ({
          url: `/dialog/delete_user_dialog`,
          method: "POST",
          body: {
            dialog_id: dialogId,
          },
        }),
        transformResponse: (res: Response<any>) => new Value(res),
      }),
      deleteNotification: builder.mutation<
        Result<any>,
        { sub_type: string,
          timestamp: number
         }
      >({
        query: ({ sub_type, timestamp }) => ({
          url: `/delete_notification`,
          method: "POST",
          body: {
            sub_type,
            timestamp
          },
        }),
        transformResponse: (res: Response<any>) => new Value(res),
      }),
    };
  },
});

export const {
  useGetNotificationListMutation,
  useGetNotificationMessageListMutation,
  useGetNotificationTypeListMutation,
  useReadNotificationTypeMutation,
  useReadNotificationMutation,
  useDeleteUserDialogMutation,
  useDeleteNotificationMutation,
} = notificationApi;
