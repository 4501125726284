import { createApi } from "@reduxjs/toolkit/query/react";
import { Response, Result, Value } from "../../models/common";
import { baseQueryWithReauth } from "../../tools/toolkit/helper";
import { Collection, CollectionTypeEnum } from "src/models/saveList";
import { PostEntity } from "src/models/post";
import { CommonUser } from "src/models/user";

interface SharedCollectionUserList {
  shared_users: CommonUser[];
  startkey: string;
}

interface GetCollectionList {
  collections: Collection[];
  startkey: string;
}

interface GetPostList {
  messages: PostEntity[];
  users: CommonUser[];
  collections: Collection[];
  startkey: string;
}

interface CreateCollection {
  collection: Collection;
}

export const saveListApi = createApi({
  reducerPath: "saveList",
  baseQuery: baseQueryWithReauth,
  endpoints(builder) {
    return {
      getCollectionInfo: builder.mutation<
        Result<CreateCollection>,
        {
          collection_id: string;
        }
      >({
        query: ({ collection_id }) => ({
          url: "collection",
          method: "POST",
          body: {
            type: 4,
            collection_id,
          },
        }),
        transformResponse: (res: Response<CreateCollection>) =>
          new Value<CreateCollection>(res),
      }),

      getCollectionList: builder.query<
        Result<GetCollectionList>,
        {
          userId: string;
          collectionType?: CollectionTypeEnum;
          startKey?: string;
          pageSize?: number;
        }
      >({
        query: ({ userId, collectionType, startKey = "", pageSize = 10 }) => ({
          url: "collection",
          method: "POST",
          body: {
            type: 5,
            user_id: userId,
            collection_type: collectionType,
            startkey: startKey,
            pagesize: pageSize,
            forwardorder: false,
          },
        }),
        transformResponse: (res: Response<GetCollectionList>) =>
          new Value<GetCollectionList>(res),
      }),

      getUnSelectedCollectionList: builder.query<
        Result<GetCollectionList>,
        {
          userId: string;
          collectionId: string;
          startKey?: string;
          pageSize?: number;
        }
      >({
        query: ({ userId, collectionId, startKey = "", pageSize = 10 }) => ({
          url: "collection",
          method: "POST",
          body: {
            type: 5,
            user_id: userId,
            collection_type: CollectionTypeEnum.Collection,
            collection_id: collectionId,
            startkey: startKey,
            pagesize: pageSize,
            forwardorder: false,
          },
        }),
        transformResponse: (res: Response<GetCollectionList>) =>
          new Value<GetCollectionList>(res),
      }),

      addMessage: builder.mutation<
        Result<undefined>,
        {
          collectionId: string;
          messageIds: string[];
          savingSinglePhoto?: boolean;
        }
      >({
        query: ({ collectionId, savingSinglePhoto, messageIds }) => ({
          url: "collection",
          method: "POST",
          body: {
            type: 6,
            collection_id: collectionId,
            content_type: savingSinglePhoto ? CollectionTypeEnum.Media : "",
            message_ids: messageIds,
          },
        }),
        transformResponse: (res: Response<undefined>) =>
          new Value<undefined>(res),
      }),
      createCollection: builder.mutation<
        Result<CreateCollection>,
        {
          name: string;
          description: string;
          collectionType: CollectionTypeEnum;
          cover?: string;
        }
      >({
        query: ({ name, description, collectionType, cover }) => ({
          url: "collection",
          method: "POST",
          body: {
            type: 1,
            collection_name: name,
            collection_type: collectionType,
            description,
            cover,
          },
        }),
        transformResponse: (res: Response<CreateCollection>) =>
          new Value<CreateCollection>(res),
      }),
      deleteCollection: builder.mutation<
        Result<undefined>,
        {
          collectionId: string;
        }
      >({
        query: ({ collectionId }) => ({
          url: "collection",
          method: "POST",
          body: {
            type: 2,
            collection_id: collectionId,
          },
        }),
        transformResponse: (res: Response<undefined>) =>
          new Value<undefined>(res),
      }),
      editCollection: builder.mutation<
        Result<CreateCollection>,
        {
          collectionId: string;
          name?: string;
          collectionType?: CollectionTypeEnum;
          description?: string;
          cover?: string;
        }
      >({
        query: ({
          collectionId,
          name,
          collectionType,
          description,
          cover,
        }) => ({
          url: "collection",
          method: "POST",
          body: {
            type: 3,
            collection_id: collectionId,
            collection_type: collectionType,
            collection_name: name,
            description,
            cover,
          },
        }),
        transformResponse: (res: Response<CreateCollection>) =>
          new Value<CreateCollection>(res),
      }),
      getMessageList: builder.query<
        Result<GetPostList>,
        {
          collectionId: string;
          startKey?: string;
          pageSize?: number;
          sortByCreateTime?: boolean;
        }
      >({
        query: ({
          collectionId,
          startKey = "",
          pageSize = 10,
          sortByCreateTime = false,
        }) => ({
          url: "collection",
          method: "POST",
          body: {
            type: 8,
            collection_id: collectionId,
            startkey: startKey,
            pagesize: pageSize,
            forwardorder: false,
            time_sort: sortByCreateTime,
          },
        }),
        transformResponse: (res: Response<GetPostList>) =>
          new Value<GetPostList>(res),
      }),
      deletePost: builder.mutation<
        Result<undefined>,
        {
          collectionId: string;
          messageIds: string[];
        }
      >({
        query: ({ collectionId, messageIds }) => ({
          url: "collection",
          method: "POST",
          body: {
            type: 7,
            collection_id: collectionId,
            message_ids: messageIds,
          },
        }),
        transformResponse: (res: Response<undefined>) =>
          new Value<undefined>(res),
      }),

      shareCollection: builder.mutation<
        Result<any>,
        {
          collectionId: string;
          userIDs: string[];
        }
      >({
        query: ({ collectionId, userIDs }) => ({
          url: "collection",
          method: "POST",
          body: {
            type: 9,
            collection_id: collectionId,
            user_ids: userIDs,
          },
        }),
        transformResponse: (res: Response<any>) => new Value<any>(res),
      }),

      unShareCollection: builder.mutation<
        Result<any>,
        {
          collectionId: string;
          userIDs: string[];
        }
      >({
        query: ({ collectionId, userIDs }) => ({
          url: "collection",
          method: "POST",
          body: {
            type: 10,
            collection_id: collectionId,
            user_ids: userIDs,
          },
        }),
        transformResponse: (res: Response<any>) => new Value<any>(res),
      }),

      getSharedCollectionList: builder.query<
        Result<GetCollectionList>,
        {
          userId: string;
          startKey?: string;
          pageSize?: number;
        }
      >({
        query: ({ userId, startKey = "", pageSize = 10 }) => ({
          url: "collection",
          method: "POST",
          body: {
            type: 11,
            user_id: userId,
            startkey: startKey,
            pagesize: pageSize,
            forwardorder: false,
          },
        }),
        transformResponse: (res: Response<GetCollectionList>) =>
          new Value<GetCollectionList>(res),
      }),

      getRecommendedCollectionList: builder.query<
        Result<GetCollectionList>,
        {
          startKey?: string;
          pageSize?: number;
        }
      >({
        query: ({ startKey = "", pageSize = 10 }) => ({
          url: "collection",
          method: "POST",
          body: {
            type: 12,
            startkey: startKey,
            pagesize: pageSize,
            forwardorder: false,
          },
        }),
        transformResponse: (res: Response<GetCollectionList>) =>
          new Value<GetCollectionList>(res),
      }),

      getSharedCollectionUserList: builder.mutation<
        Result<SharedCollectionUserList>,
        {
          collectionId: string;
          startKey?: string;
          pageSize?: number;
        }
      >({
        query: ({ collectionId, startKey = "", pageSize = 10 }) => ({
          url: "collection",
          method: "POST",
          body: {
            type: 13,
            collection_id: collectionId,
            startkey: startKey,
            pagesize: pageSize,
            forwardorder: false,
          },
        }),
        transformResponse: (res: Response<SharedCollectionUserList>) =>
          new Value<SharedCollectionUserList>(res),
      }),
    };
  },
});

export const {
  useGetCollectionInfoMutation,
  useGetCollectionListQuery,
  useLazyGetCollectionListQuery,
  useGetUnSelectedCollectionListQuery,
  useLazyGetUnSelectedCollectionListQuery,
  useAddMessageMutation,
  useCreateCollectionMutation,
  useDeleteCollectionMutation,
  useEditCollectionMutation,
  useLazyGetMessageListQuery,
  useDeletePostMutation,
  useShareCollectionMutation,
  useUnShareCollectionMutation,
  useGetSharedCollectionListQuery,
  useLazyGetSharedCollectionListQuery,
  useGetRecommendedCollectionListQuery,
  useLazyGetRecommendedCollectionListQuery,
  useGetSharedCollectionUserListMutation,
} = saveListApi;
