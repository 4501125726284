import { Button } from '@mui/material'
import React, { useState } from 'react'
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import { useTranslation } from 'react-i18next'
import SvgComponent from 'src/components/svgComponent'

export const FACEBOOK_APP_ID = '167818718443329'

interface PropsForm {
    ResponseFacebook: (response: any) => void,
}

const FacebookLoginButton: React.FC<PropsForm> = (props) => {
    const { t } = useTranslation();
    const { ResponseFacebook } = props;
    const [isDisabled, setIsDisabled] = useState(true)

    return (
        <>
            <FacebookLogin
                appId={FACEBOOK_APP_ID}
                disableMobileRedirect={true}
                autoLoad={false}
                callback={(res) => ResponseFacebook(res)}
                render={renderProps => {
                    // sdk加载完成之前禁用按钮
                    new Promise((resolve, reject) => {
                        if ('FB' in window) {
                            resolve('SDK loaded successfully');
                        }
                    }).then(res => {
                        setIsDisabled(false);
                    })
                    return (
                        <Button size="large" variant="text" onClick={renderProps.onClick} disabled={isDisabled}
                            sx={{
                                textTransform: 'none', color: '#000000', fontFamily: 'Helvetica Neue', fontStyle: 'normal', fontWeight: 400, fontSize: '14px', textAlign: "center",
                                height: '44px', marginBottom: '20px', whiteSpace: 'nowrap', width: '100%',
                                background: '#F9F9F9',
                                border: '1px solid #E6E6E6', borderRadius: '22px', boxShadow: 'none',
                                mixBlendMode: 'normal',
                                position: "relative",
                            }}>
                            <SvgComponent iconName="ico-facebook" style={{ width: '20px', height: '20px', position: "absolute", left: "20px", }} />
                            {t("app.login.facebook-login")}
                        </Button>)
                }
                }>

            </FacebookLogin>
        </>
    )
}

export default FacebookLoginButton;