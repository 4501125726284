import { Button, } from '@mui/material';
import * as React from 'react'
import { GoogleLogin, GoogleLoginResponse, GoogleLoginResponseOffline } from 'react-google-login';
import { useTranslation } from 'react-i18next';
import SvgComponent from 'src/components/svgComponent';

const clientId = '222756514152-g0odmu7215tkck566hv449fq0vuq4iri.apps.googleusercontent.com'

interface PropsForm {
    onSuccess: (response: GoogleLoginResponse | GoogleLoginResponseOffline) => void,
    onFailure?: (error: any) => void,
}

const GoogleLoginButton: React.FC<PropsForm> = (props) => {
    const { t } = useTranslation();
    const { onSuccess, onFailure } = props;

    return (
        <GoogleLogin
            clientId={clientId}
            buttonText='continue with google'
            onSuccess={onSuccess}
            onFailure={onFailure}
            cookiePolicy={'single_host_origin'}
            responseType='code,token'
            render={renderProps => (
                <Button size="large" variant="text" onClick={renderProps.onClick} disabled={renderProps.disabled}
                    sx={{
                        textTransform: 'none', color: '#000000', fontFamily: 'Helvetica Neue', fontStyle: 'normal', fontWeight: 400, fontSize: '14px', textAlign: "center",
                        height: '44px', marginBottom: '20px', whiteSpace: 'nowrap', width: '100%',
                        background: '#F9F9F9',
                        border: '1px solid #E6E6E6', borderRadius: '22px', boxShadow: 'none',
                        position: "relative",
                    }}>
                    <SvgComponent iconName="ico-google" style={{ width: '18px', height: '18px', position: "absolute", left: "20px", }} />
                    {t("app.login.google-login")}
                </Button>
            )}>

        </GoogleLogin>
    )
}

export default GoogleLoginButton;