import React from 'react'
import { Button } from '@mui/material'
import { SnackbarProvider } from 'notistack'
import CloseIcon from '@mui/icons-material/Close';
import { SvgSprite } from './components/svgSprite';
import { SvgSource } from './theme';
import { LoadingBar } from './components/loading';
import { Routers } from './layout/route';
import { useSelector } from "./tools/toolkit/store";
import { useMarkerMutation } from "./service/api/log-api";
import MainMeta from "src/components/meta/MainMeta";
import useLandingBaidu from './hooks/landing/baidu';
import useMapLoader from './hooks/map/useMapLoader';
import useTrackUserActivity from 'src/hooks/user/useTrackUserActivity'



interface AppProps {
  language?: string
}
const App: React.FC<AppProps> = () => {

  const notistackRef = React.createRef<SnackbarProvider>()
  const { credential } = useSelector(state => state.app);

  const [userMarker] = useMarkerMutation();

  React.useEffect(() => {
    userMarker({}).catch((error) => {
      console.error(error);
    })
  }, [credential])

  useLandingBaidu();
  useMapLoader();
  useTrackUserActivity();

  return (
    <SnackbarProvider
      maxSnack={3}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      ref={notistackRef}
      action={key => (
        <Button onClick={() => notistackRef?.current?.closeSnackbar(key)} color="inherit">
          <CloseIcon />
        </Button>
      )}
    >
      <MainMeta />
      <Routers fallback={<LoadingBar />} />
      <SvgSprite symbols={SvgSource} />
    </SnackbarProvider>
  )
}

export default App