import { createApi } from "@reduxjs/toolkit/query/react";
import { Response, Result, Value } from "../../models/common";
import { baseQueryWithReauth } from "../../tools/toolkit/helper";

export const thirdPartyLoginApi = createApi({
  reducerPath: "thirdPartyLogin",
  baseQuery: baseQueryWithReauth,
  endpoints(builder) {
    return {
      thirdPartyLoginStep1: builder.mutation<Result<any>, {
        thirdToken: string;
        loginType: number; //2-Google;3-Facebook;4-Apple
      }>({
        query: ({ thirdToken, loginType }) => ({
          url: `thirdpartysignup`,
          method: "POST",
          body: loginType === 4 ? {
            login_type: loginType,
            token: thirdToken,
            user_name: "Apple User",
            client_id: "com.sktlab.anyLocal.service",
          } : {
            login_type: loginType,
            token: thirdToken,
          },
        }),
        transformResponse: (res: Response<any>) => new Value(res),
      }),

      thirdPartyLoginStep2: builder.mutation<Result<any>, {
        loginType: number;
        userId: string;
        publicKey: string;
        verifyMessage: string;
        signature: string;
      }>({
        query: ({ loginType, userId, publicKey, verifyMessage, signature, }) => ({
          url: `thirdpartysignup`,
          method: "POST",
          body: {
            login_type: loginType,
            user_id: userId,
            public_key: publicKey,
            verify_message: verifyMessage,
            signature: signature,
          },
        }),
        transformResponse: (res: Response<any>) => new Value(res),
      }),
      wechatPartyLogin: builder.mutation<Result<any>, { code: string; }>({
        query: ({ code }) => ({
          url: `thirdpartysignup`,
          method: "POST",
          body: {
            login_type: 5,
            code
          },
        }),
        transformResponse: (res: Response<any>) => new Value(res),
      }),
    };
  },
});

export const { useThirdPartyLoginStep1Mutation, useThirdPartyLoginStep2Mutation, useWechatPartyLoginMutation } = thirdPartyLoginApi;
