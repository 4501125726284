import { Paper, PaperProps } from "@mui/material";

export const PaperComponent = (props: PaperProps) => {
  return (
    // <Draggable
    //   handle="#draggable-dialog-title"
    //   cancel={'[class*="MuiDialogContent-root"]'}
    // >
    <Paper {...props} style={{ borderRadius: "12px" }} />
    // </Draggable>
  );
}
