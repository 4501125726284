import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
// import reportWebVitals from './reportWebVitals';
import { Provider as ReduxProvider } from 'react-redux'
import { toolkitStore } from './tools/toolkit/store';
import App from './App';
import i18n from './app/i18n';
import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles'
import { theme } from './theme/theme'
import { AliveScope, autoFixContext } from 'react-activation';
// import { ErrorBoundary } from "react-error-boundary";
import ErrorPage from './components/error/ErrorPage';
import { windowErrorHandle } from './tools/utils/reportError';

windowErrorHandle();

autoFixContext(
  [require('react/jsx-runtime'), 'jsx', 'jsxs', 'jsxDEV'],
  [require('react/jsx-dev-runtime'), 'jsx', 'jsxs', 'jsxDEV']
)

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <ReduxProvider store={toolkitStore}>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AliveScope>
        <ErrorPage >
          <App language={i18n.language} />
        </ErrorPage>
      </AliveScope>
    </ThemeProvider>
  </ReduxProvider>
);



// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

