import React, { useCallback, useMemo, useState } from 'react'
import { Box, Typography, Avatar, CircularProgress } from '@mui/material';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import axios from 'axios'
import { useSnackbar } from 'notistack';
import PreviewPicture from 'src/components/preview/PreviewPicture';
import PlayCircleTwoToneIcon from '@mui/icons-material/PlayCircleTwoTone';
import PreviewVideo from 'src/components/preview/PreviewVideo';
import { SvgComponent } from '../svgComponent';
import { getDisplayUserName, getNumberDesc } from 'src/tools/utils/stringUtil';
import { getChatMessageTimeDesc } from 'src/tools/utils/timeUtil';
import { FileEntity } from 'src/models/file';
import { ChatMessage, MessageStatusEnum } from 'src/models/chat';
import { useSelector } from 'src/tools/toolkit/store';
import { PostEntity, PostMediaType, getCoverImage } from 'src/models/post';
import IcoLocation from "src/images/post/ico-location-small-blue.svg"
import { appConfig } from 'src/app-config';
import { useTranslation } from 'react-i18next';
import { LocationEntity, getLocationCoverImage, getLocationName } from 'src/models/location';
import { Base64 } from 'js-base64';
import { CommonUser } from 'src/models/user';
import DefaultAvatar from "src/images/defaultIcons/default-avatar.png"
import { LocationAreaEntity } from 'src/pages/location-area/LocationArea';
import icoPlay from "src/images/chatDialog/ico-play.svg"
import { getPostMediaType } from "src/models/post"
import { RequestEntity } from 'src/models/request';
import IcoRequest from "src/images/defaultIcons/ico-request.svg";
import IcoTag from "src/images/defaultIcons/ico-tag.svg";
import { calculatePriceStr } from 'src/tools/utils/postUtil';
import { ShareTopicEntity, ShareTagEntity, ShareTopicTag } from 'src/models/topic';
import { getPostLabel, handleClickLocationArea, getSharedLocationAreaName, getSharedUserName, locationData, getSharedLocationLinkName, getSharedLocationName, getSharePostName, requestData, getSharedRequestName } from 'src/tools/utils/chatUtil';
import AudioPlayer from "src/components/audio/audioPlayer";
import { AudioPlayerProvider } from 'src/providers/AudioPlayerContext';
import IcoLoading from "src/images/chatroom/ico-loading.svg";
import SharePostMessage from 'src/components/chat/SharePostMessage';
import IcoMultiImages from "src/images/chatroom/ico-multi-images.svg";
import { AIAnswerPostStyle } from "src/pages/chatroom/ChatMessageList";
import AIPostBGRound from "src/images/chatroom/ai-post-bg-round.png";
import { WebsocketChatMessage } from "src/models/chat";

interface Props {
  chatMessageList: ChatMessage[];
  enableEdit?: boolean;
  onClickMessageMenu?: (event: React.MouseEvent<HTMLElement>, chatMessage: ChatMessage) => void;
}

export const RichChatMessageList: React.FC<Props> = ({ chatMessageList, enableEdit, onClickMessageMenu }) => {
  const { t, i18n } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { credential } = useSelector(state => state.app);
  const [previewPictureConfig, setPreViewPictureConfig] = useState({ open: false, pictureUrl: "" });
  const [previewVideoConfig, setPreViewVideoConfig] = useState({ open: false, videoUrl: "" });

  const isUnknownType = (row: ChatMessage) => {
    return row.type !== "image"
      && row.type !== "video"
      && row.type !== "post"
      && row.type !== "share_post"
      && row.type !== "share_location"
      && row.type !== "share_user"
      && row.type !== "share_region"
      && row.type !== "share_request"
      // && row.type !== "share_tag" // 没有share_tag这个类型，tag和topic都用的share_topic
      && row.type !== "share_topic"
      && row.type !== "ai_answer"
  }

  const downloadFile = (entity: FileEntity) => {
    const downloadUrl = entity.publicUrl;
    console.log("download: ", downloadUrl)
    axios({
      url: downloadUrl,
      responseType: 'blob',
      method: 'GET',
      onDownloadProgress: (progressEvent) => {
        // let percentComplete = progressEvent.loaded / progressEvent.total;
        // setProgress(percentComplete*100)
      }
    })
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data], { type: entity.fileType }))
        const link = document.createElement("a");
        link.download = entity.fileName;
        link.href = url;
        link.click();
      })
      .catch((err) => {
        console.log(err);
        enqueueSnackbar("Download failed: " + err.message)
      })
  }

  const previewPicture = (url: string) => {
    setPreViewPictureConfig({
      open: true,
      pictureUrl: url,
    })
  }

  const previewVideo = (url: string) => {
    setPreViewVideoConfig({
      open: true,
      videoUrl: url,
    })
  }

  const handleGoToPostList = (row: ChatMessage) => {
    window.open(`${appConfig.paths.aiPostList}?id=${row.id}`, "_blank");
  }

  const isAIAnswerPost = (row: ChatMessage) => {
    return row.type === "ai_answer" && row.post_list && row.post_list.length > 0;
  }

  return (
    <AudioPlayerProvider>
      <Box>
        {chatMessageList?.length > 0 && chatMessageList.map((row, index) => (
          <Box key={index} sx={{
            marginTop: "20px"
          }}>
            {row.sender_id === credential?.user_id ?
              // 自己的消息
              <Box sx={{
                textAlign: "end"
              }}>
                {/* 自己的用户名和头像 */}
                <Box sx={{
                  display: "flex",
                  alignItems: "center",
                  width: "fit-content",
                  marginLeft: "auto",
                }}>
                  <Typography sx={{
                    color: "#AAAAAA",
                    textAlign: "right",
                    padding: "10px 0",
                    fontSize: "14px !important",
                  }} >
                    {getDisplayUserName(row.sender_name, row.sender_id)}
                  </Typography>
                  <Avatar
                    src={row.sender_avatar || DefaultAvatar}
                    style={{ width: '20px', height: '20px', marginLeft: '5px' }}
                  />
                </Box>

                <Box sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: "fit-content",
                  marginLeft: "auto",
                }}>
                  {row.type === "text" ?
                    <Box component="span" sx={{
                      color: "white",
                      background: "#3A92F9",
                      borderRadius: "5px 0 5px 5px",
                      width: "fit-content",
                      maxWidth: "260px",
                      padding: "8px 12px",
                      fontSize: 14,
                      textAlign: "left",
                      wordBreak: "break-word",
                      display: "inline-table",
                    }}>{row.content}</Box>
                    :
                    <Box sx={{
                      marginLeft: "auto",
                      background: "#E9EDF2",
                      borderRadius: "5px 0 5px 5px",
                      width: "260px",
                      padding: "8px 12px",
                    }}>
                      {row.type === "image" &&
                        <Avatar
                          sx={{
                            width: "96px !important",
                            height: "96px !important",
                            cursor: "pointer",
                          }}
                          variant="square"
                          src={row.content}
                          onClick={() => previewPicture(row.content)}
                        />
                      }
                      {row.type === "video" &&
                        <PlayCircleTwoToneIcon sx={{
                          width: "96px !important",
                          height: "96px !important",
                          cursor: "pointer",
                          color: "white",
                          background: "#aaaaaa",
                          borderRadius: "10px",
                        }} onClick={() => previewVideo(row.content)}
                        />
                      }

                      {row.type === "audio" &&
                        <>
                          {
                            row.status === MessageStatusEnum.Sending ?
                              <img src={IcoLoading} />
                              : <AudioPlayer item={row} isOwn={true} />
                          }
                        </>
                      }

                      {row.type === "post" && row.content &&
                        <Box style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
                          onClick={() => window.open(`${window.location.protocol}//${window.location.host}${appConfig.paths.requestPurchasePost}?id=${(JSON.parse(row.content) as PostEntity).messageid}`, "_blank")}>
                          <img alt="post_image" style={{ width: "64px", height: "64px", minWidth: "64px", objectFit: "cover" }} src={getCoverImage(JSON.parse(row.content) as PostEntity)} />
                          <Box sx={{ marginLeft: "10px", textAlign: "left" }}>
                            <Box sx={{ maxWidth: "150px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", fontSize: "14px", fontWeight: 600 }}>{getPostLabel(row, t)}</Box>
                            <Box sx={{ display: "flex", alignItems: "center", marginTop: "10px" }}>
                              <img alt="ico-location" src={IcoLocation} style={{ width: "12px", height: "14px", marginRight: "5px" }} />
                              <Box sx={{ maxWidth: "150px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", fontSize: "12px" }}>{(JSON.parse(row.content) as PostEntity)?.loc_name}</Box>
                            </Box>
                            {JSON.parse(row.content)?.request_amount &&
                              <Typography sx={{
                                fontSize: "14px",
                                fontWeight: 500,
                                color: "#FF0000",
                                marginTop: "6px"
                              }}>
                                {JSON.parse(row.content)?.request_amount} {JSON.parse(row.content)?.request_amount === 1 ? t("app.chat.coins") : t("app.chat.coins")}
                              </Typography>}
                          </Box>
                        </Box>
                      }
                      {row.type === "share_post" && row.content &&
                        <SharePostMessage row={row} isOwn={true} />
                      }

                      {row.type === "share_location" && row.content &&
                        <Box style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
                          onClick={() => window.open(`${window.location.protocol}//${window.location.host}${appConfig.paths.location}?loc_id=${locationData(row).loc_id}&loc_name=${Base64.encodeURI(getSharedLocationLinkName(row))}`, "_blank")}>
                          <img alt="location_image" style={{ width: "64px", height: "64px", minWidth: "64px", objectFit: "cover" }} src={getLocationCoverImage(locationData(row))} />
                          <Box sx={{ marginLeft: "10px", textAlign: "left" }}>
                            <Box sx={{ maxWidth: "150px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", fontSize: "14px", fontWeight: 600 }}>{getSharedLocationName(row)}</Box>
                            <Box sx={{ display: "flex", alignItems: "center", marginTop: "10px" }}>
                              <Box sx={{ fontSize: "12px" }}>
                                {`${getNumberDesc(locationData(row)?.total_visit_count || 0)} ${locationData(row)?.total_visit_count > 1 ? t("app.location.views") : t("app.location.view")}`}
                              </Box>&nbsp;&nbsp;&nbsp;&nbsp;
                              <Box sx={{ fontSize: "12px" }}>
                                {`${getNumberDesc(locationData(row)?.total_message_count || 0)} ${locationData(row)?.total_message_count > 1 ? t("app.location.posts") : t("app.location.post")}`}
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      }

                      {row.type === "share_request" && row.content &&
                        <Box style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
                          onClick={() => window.open(`${window.location.protocol}//${window.location.host}${appConfig.paths.requestDetail}?id=${(JSON.parse(row.content) as RequestEntity).messageid}`, "_blank")}>
                          <img alt="request_image" style={{ width: "64px", height: "64px", minWidth: "64px", objectFit: "cover" }} src={IcoRequest} />
                          <Box sx={{ marginLeft: "10px", textAlign: "left" }}>
                            <Box sx={{ maxWidth: "150px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", fontSize: "14px", fontWeight: 600 }}>{getSharedRequestName(row)}</Box>
                            <Box sx={{ display: "flex", alignItems: "center", marginTop: "10px" }}>
                              <Box sx={{ fontSize: "12px" }}>{calculatePriceStr(Number(requestData(row).cost), requestData(row).message_content.paidInfo.currency, t)}</Box>
                            </Box>
                          </Box>
                        </Box>
                      }

                      {row.type === "share_topic" && row.content && (JSON.parse(row.content) as ShareTopicTag).type === 'topic' &&
                        <Box style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
                          onClick={() => window.open(`${window.location.protocol}//${window.location.host}${appConfig.paths.topic}/${encodeURIComponent((JSON.parse(row.content) as ShareTopicEntity).topic_id)}`, "_blank")}>
                          <img alt="request_image" style={{ width: "64px", height: "64px", minWidth: "64px", objectFit: "cover" }} src={IcoTag} />
                          <Box sx={{ marginLeft: "10px", textAlign: "left" }}>
                            <Box sx={{ maxWidth: "150px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", fontSize: "14px", fontWeight: 600 }}>#{(JSON.parse(row.content) as ShareTopicEntity).topic_id}</Box>
                          </Box>
                        </Box>
                      }

                      {row.type === "share_topic" && row.content && (JSON.parse(row.content) as ShareTopicTag).type === 'tag' &&
                        <Box style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
                          onClick={() => window.open(`${window.location.protocol}//${window.location.host}${appConfig.paths.autoTopic}/${encodeURIComponent((JSON.parse(row.content) as ShareTagEntity).topic_id)}`, "_blank")}>
                          <img alt="request_image" style={{ width: "64px", height: "64px", minWidth: "64px", objectFit: "cover" }} src={IcoTag} />
                          <Box sx={{ marginLeft: "10px", textAlign: "left" }}>
                            <Box sx={{ maxWidth: "150px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", fontSize: "14px", fontWeight: 600 }}>
                              #{i18n.language === 'zh-CN' ? (JSON.parse(row.content) as ShareTagEntity).cn_name : (JSON.parse(row.content) as ShareTagEntity).name}
                            </Box>
                          </Box>
                        </Box>
                      }

                      {row.type === "share_region" && row.content &&
                        <Box style={{ padding: "5px 0", cursor: "pointer", minWidth: "100px" }}
                          onClick={() => handleClickLocationArea(row)}>
                          <Box sx={{ display: "flex", alignItems: "center", }}>
                            <img src={IcoLocation} alt='location' style={{ marginRight: "5px" }} />
                            <Box sx={{ maxWidth: "150px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", fontSize: "14px", fontWeight: 600 }}>{getSharedLocationAreaName(row)}</Box>
                          </Box>
                          <Box sx={{ fontSize: "12px", marginTop: "10px", textAlign: "left" }}>{`${getNumberDesc((JSON.parse(row.content) as LocationAreaEntity)?.post_count || 0)} ${t("app.location.posts")}`}</Box>
                        </Box>
                      }

                      {row.type === "share_user" && row.content &&
                        <Box style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
                          onClick={() => window.open(`${window.location.protocol}//${window.location.host}${appConfig.paths.user}?id=${(JSON.parse(row.content) as CommonUser).user_id}`, "_blank")}>
                          <Avatar alt="user_image" style={{ width: "64px", height: "64px", minWidth: "64px", objectFit: "cover" }} src={(JSON.parse(row.content) as CommonUser).user_avatar || DefaultAvatar} />
                          <Box sx={{ marginLeft: "10px", textAlign: "left" }}>
                            <Box sx={{ width: "150px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", fontSize: "14px", fontWeight: 600 }}>{getSharedUserName(row)}</Box>
                            <Box sx={{ maxWidth: "150px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", fontSize: "12px", marginTop: "10px", }}>{(JSON.parse(row.content) as CommonUser).personalized_signature}</Box>
                          </Box>
                        </Box>
                      }

                      {isUnknownType(row) &&
                        <Box style={{ display: "flex", alignItems: "center" }}>
                          <AttachFileIcon fontSize='small' style={{ color: "blue" }} />
                          <Typography sx={{
                            color: "#666666",
                            fontSize: "14px !important",
                            textAlign: "left",
                            wordBreak: "break-word",
                          }}>{row.content}</Typography>
                        </Box>
                      }

                      {/* <Typography sx={{
                      fontSize: "12px !important",
                      color: "#999999",
                      margin: "5px !important"
                    }}>
                      {`${formatFileSize((JSON.parse(row.content) as FileEntity).fileSize)} ${row.status === 1 ? "sending..." : "send finished"}`}
                    </Typography> */}
                      {/* {row.status === 2 && !(row.type === "image") &&
                      <Button size="small" color="secondary" style={{ textTransform: "none", fontSize: "14px" }} onClick={() => downloadFile(row.content)}>Download</Button>
                    } */}
                    </Box>
                  }
                  {/* {enableEdit &&
                  <SvgComponent 
                    iconName='ico-menu-dots' 
                    style={{paddingLeft: '8px', cursor:'pointer', width:'16px', height: '16px'}} 
                    onClick={(event) => {onClickMessageMenu && onClickMessageMenu(event, row)}}
                  />
                  } */}
                </Box>
                {row.status === MessageStatusEnum.Sending ?
                  <CircularProgress sx={{ width: '10px !important', height: '10px !important', marginTop: '5px !important' }} />
                  :
                  <Typography sx={{
                    fontSize: "12px !important",
                    color: "#999999 !important",
                    fontFamily: "ArialMT !important",
                    marginTop: "5px !important",
                  }}>{row.time && getChatMessageTimeDesc(row.time * 1000)}</Typography>
                }
              </Box>
              :
              // 对方的消息
              <Box sx={{
                textAlign: "start"
              }}>
                {/* 对方的头像和用户名 */}
                <Box sx={{
                  display: "flex",
                  alignItems: "center",
                }}>
                  <Avatar
                    src={row.sender_avatar || DefaultAvatar}
                    style={{ width: '20px', height: '20px', marginRight: '5px' }} />
                  <Typography sx={{
                    color: "#AAAAAA",
                    padding: "10px 0",
                    fontSize: "14px !important",
                  }} >
                    {getDisplayUserName(row.sender_name, row.sender_id)}
                  </Typography>
                </Box>

                {row.type === "text" ?
                  <Box component="span" sx={{
                    color: "black",
                    background: "#E9EDF2",
                    borderRadius: "0 5px 5px 5px",
                    width: "fit-content",
                    maxWidth: "260px",
                    padding: "8px 12px",
                    fontSize: 14,
                    wordBreak: "break-word",
                    display: "inline-table",
                  }}>{row.content}</Box>
                  :
                  <Box sx={{
                    background: "#EEEEEE",
                    borderRadius: "0 5px 5px 5px",
                    width: "260px",
                    padding: "8px 12px",
                    ...(isAIAnswerPost(row) ? AIAnswerPostStyle : {})
                  }}>
                    {row.type === "image" &&
                      <Avatar
                        sx={{
                          width: "96px !important",
                          height: "96px !important",
                          cursor: "pointer",
                        }}
                        variant="square"
                        src={row.content}
                        onClick={() => previewPicture(row.content)} />
                    }

                    {row.type === "video" &&
                      <PlayCircleTwoToneIcon sx={{
                        width: "96px !important",
                        height: "96px !important",
                        cursor: "pointer",
                        color: "white",
                        background: "#aaaaaa",
                        borderRadius: "10px",
                      }} onClick={() => previewVideo(row.content)} />
                    }

                    {row.type === "audio" &&
                      <>
                        {
                          row.status === MessageStatusEnum.Sending ?
                            <img src={IcoLoading} />
                            : <AudioPlayer item={row} isOwn={false} />
                        }
                      </>
                    }

                    {row.type === "post" && row.content &&
                      <Box style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
                        onClick={() => window.open(`${window.location.protocol}//${window.location.host}${appConfig.paths.requestPurchasePost}?id=${(JSON.parse(row.content) as PostEntity).messageid}`, "_blank")}>
                        <img alt="post_image" style={{ width: "64px", height: "64px", minWidth: "64px", objectFit: "cover" }} src={getCoverImage(JSON.parse(row.content) as PostEntity)} />
                        <Box sx={{ marginLeft: "10px", textAlign: "left" }}>
                          <Box sx={{ maxWidth: "150px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", fontSize: "14px", fontWeight: 600 }}>{getPostLabel(row, t)}</Box>
                          <Box sx={{ display: "flex", alignItems: "center", marginTop: "10px" }}>
                            <img alt="ico-location" src={IcoLocation} style={{ width: "12px", height: "14px", marginRight: "5px" }} />
                            <Box sx={{ maxWidth: "150px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", fontSize: "12px" }}>{(JSON.parse(row.content) as PostEntity)?.loc_name}</Box>
                          </Box>
                        </Box>
                      </Box>
                    }

                    {row.type === "share_post" && row.content &&
                      <SharePostMessage row={row} isOwn={false} />
                    }

                    {row.type === "share_location" && row.content &&
                      <Box style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
                        onClick={() => window.open(`${window.location.protocol}//${window.location.host}${appConfig.paths.location}?loc_id=${locationData(row).loc_id}&loc_name=${Base64.encodeURI(getSharedLocationLinkName(row))}`, "_blank")}>
                        <img alt="location_image" style={{ width: "64px", height: "64px", minWidth: "64px", objectFit: "cover" }} src={getLocationCoverImage(locationData(row))} />
                        <Box sx={{ marginLeft: "10px", textAlign: "left" }}>
                          <Box sx={{ maxWidth: "150px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", fontSize: "14px", fontWeight: 600 }}>{getSharedLocationName(row)}</Box>
                          <Box sx={{ display: "flex", alignItems: "center", marginTop: "10px" }}>
                            <Box sx={{ fontSize: "12px" }}>
                              {`${getNumberDesc(locationData(row)?.total_visit_count || 0)} ${locationData(row)?.total_visit_count > 1 ? t("app.location.views") : t("app.location.view")}`}
                            </Box>&nbsp;&nbsp;&nbsp;&nbsp;
                            <Box sx={{ fontSize: "12px" }}>
                              {`${getNumberDesc(locationData(row)?.total_message_count || 0)} ${locationData(row)?.total_message_count > 1 ? t("app.location.posts") : t("app.location.post")}`}
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    }

                    {row.type === "share_request" && row.content &&
                      <Box style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
                        onClick={() => window.open(`${window.location.protocol}//${window.location.host}${appConfig.paths.requestDetail}?id=${requestData(row).messageid}`, "_blank")}>
                        <img alt="request_image" style={{ width: "64px", height: "64px", minWidth: "64px", objectFit: "cover" }} src={IcoRequest} />
                        <Box sx={{ marginLeft: "10px", textAlign: "left" }}>
                          <Box sx={{ maxWidth: "150px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", fontSize: "14px", fontWeight: 600 }}>{getSharedRequestName(row)}</Box>
                          <Box sx={{ display: "flex", alignItems: "center", marginTop: "10px" }}>
                            <Box sx={{ fontSize: "12px" }}>{calculatePriceStr(Number(requestData(row).cost), requestData(row).message_content.paidInfo.currency, t)}</Box>
                          </Box>
                        </Box>
                      </Box>
                    }

                    {row.type === "share_topic" && row.content && (JSON.parse(row.content) as ShareTopicTag).type === 'topic' &&
                      <Box style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
                        onClick={() => window.open(`${window.location.protocol}//${window.location.host}${appConfig.paths.topic}/${encodeURIComponent((JSON.parse(row.content) as ShareTopicEntity).topic_id)}`, "_blank")}>
                        <img alt="request_image" style={{ width: "64px", height: "64px", minWidth: "64px", objectFit: "cover" }} src={IcoTag} />
                        <Box sx={{ marginLeft: "10px", textAlign: "left" }}>
                          <Box sx={{ maxWidth: "150px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", fontSize: "14px", fontWeight: 600 }}>#{(JSON.parse(row.content) as ShareTopicEntity).topic_id}</Box>
                        </Box>
                      </Box>
                    }

                    {row.type === "share_topic" && row.content && (JSON.parse(row.content) as ShareTopicTag).type === 'tag' &&
                      <Box style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
                        onClick={() => window.open(`${window.location.protocol}//${window.location.host}${appConfig.paths.autoTopic}/${encodeURIComponent((JSON.parse(row.content) as ShareTagEntity).topic_id)}`, "_blank")}>
                        <img alt="request_image" style={{ width: "64px", height: "64px", minWidth: "64px", objectFit: "cover" }} src={IcoTag} />
                        <Box sx={{ marginLeft: "10px", textAlign: "left" }}>
                          <Box sx={{ maxWidth: "150px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", fontSize: "14px", fontWeight: 600 }}>
                            #{i18n.language === 'zh-CN' ? (JSON.parse(row.content) as ShareTagEntity).cn_name : (JSON.parse(row.content) as ShareTagEntity).name}
                          </Box>
                        </Box>
                      </Box>
                    }

                    {row.type === "share_region" && row.content &&
                      <Box style={{ padding: "5px 0", cursor: "pointer", minWidth: "100px" }}
                        onClick={() => handleClickLocationArea(row)}>
                        <Box sx={{ display: "flex", alignItems: "center", }}>
                          <img src={IcoLocation} alt='location' style={{ marginRight: "5px" }} />
                          <Box sx={{ maxWidth: "150px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", fontSize: "14px", fontWeight: 600 }}>{getSharedLocationAreaName(row)}</Box>
                        </Box>
                        <Box sx={{ fontSize: "12px", marginTop: "10px", textAlign: "left" }}>{`${getNumberDesc((JSON.parse(row.content) as LocationAreaEntity)?.post_count || 0)} ${t("app.location.posts")}`}</Box>
                      </Box>
                    }

                    {row.type === "share_user" && row.content &&
                      <Box style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
                        onClick={() => window.open(`${window.location.protocol}//${window.location.host}${appConfig.paths.user}?id=${(JSON.parse(row.content) as CommonUser).user_id}`, "_blank")}>
                        <Avatar alt="user_image" style={{ width: "64px", height: "64px", minWidth: "64px", objectFit: "cover" }} src={(JSON.parse(row.content) as CommonUser).user_avatar || DefaultAvatar} />
                        <Box sx={{ marginLeft: "10px", textAlign: "left" }}>
                          <Box sx={{ width: "150px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", fontSize: "14px", fontWeight: 600 }}>{getSharedUserName(row)}</Box>
                          <Box sx={{ maxWidth: "150px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", fontSize: "12px", marginTop: "10px", }}>{(JSON.parse(row.content) as CommonUser).personalized_signature}</Box>
                        </Box>
                      </Box>
                    }

                    {
                      row.type === "ai_answer" && row.content &&
                      <>
                        {isAIAnswerPost(row) && <img src={AIPostBGRound} className='roundImg' />}
                        <Box sx={{
                          lineHeight: '18px', fontFamily: "SF Pro Text",
                          fontSize: '14px', fontWeight: '400',
                          wordBreak: "break-word",
                          whiteSpace: "pre-line",
                          position: 'relative',
                          zIndex: 1
                        }}>
                          <Box
                            sx={{
                              color: "#666666",
                              fontStyle: 'italic',
                              WebkitLineClamp: 5,
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              display: '-webkit-box',
                              '-webkit-box-orient': 'vertical',
                              paddingRight: '3px'
                            }}
                            className="originMessageContainer"
                          >
                            {(row.origin_message as WebsocketChatMessage)?.message.content}
                            {/* {(row?.origin_message as any)?.content} */}
                          </Box>
                          <Box sx={{ width: "100%", borderBottom: "1px solid #D7DCE3", margin: "10px 0" }}></Box>
                          <Box sx={{ color: "#000" }}>
                            {row.content}
                            {isAIAnswerPost(row) &&
                              <Box className="aiPostImage" onClick={() => handleGoToPostList(row)}>
                                <img src={IcoMultiImages} style={{ position: 'absolute', width: "24px", height: '24px', zIndex: 2, top: '10px', left: '3px' }} />
                                <img className="postImage" src={row?.post_list && row?.post_list[0]} />
                              </Box>
                            }
                          </Box>
                        </Box>
                      </>
                    }

                    {isUnknownType(row) &&
                      <Box style={{ display: "flex", alignItems: "center" }}>
                        <AttachFileIcon fontSize='small' style={{ color: "blue" }} />
                        <Typography sx={{
                          color: "#666666",
                          fontSize: "14px !important",
                          wordBreak: "break-word",
                        }}>{row.content}</Typography>
                      </Box>
                    }

                    {/* <Typography sx={{
                      fontSize: "12px !important",
                      color: "#999999",
                      margin: "5px !important"
                    }}>
                      {`${formatFileSize((JSON.parse(row.content) as FileEntity).fileSize)}`}
                    </Typography> */}
                    {/* {!(row.type === "image") &&
                      <Button size="small" color="secondary" style={{ textTransform: "none", fontSize: "14px" }} onClick={() => downloadFile(row.content)}>Download</Button>
                    } */}
                  </Box>

                }
                <Typography sx={{
                  fontSize: "12px !important",
                  color: "#999999 !important",
                  fontFamily: "ArialMT !important",
                  marginTop: "5px !important",
                }}>{row.time && getChatMessageTimeDesc(row.time * 1000)}</Typography>
              </Box>
            }
          </Box>
        ))

          // no message layout
          // <Box style={{ textAlign: 'center', marginTop: "30px" }}>
          //   <SvgComponent iconName='ico-no-chat' style={{ width: '48px', height: '48px' }} />
          //   <Typography style={{ textAlign: "center", color: "#999999", marginTop: "10px" }}>{initing ? t("app.user.loading") : t("app.chat.noMessage")}</Typography>
          // </Box>
        }

        <PreviewPicture
          showDialog={previewPictureConfig.open}
          pictureUrl={previewPictureConfig.pictureUrl}
          onClose={() => setPreViewPictureConfig({ open: false, pictureUrl: "" })} />

        <PreviewVideo
          showDialog={previewVideoConfig.open}
          videoUrl={previewVideoConfig.videoUrl}
          onClose={() => setPreViewVideoConfig({ open: false, videoUrl: "" })} />
      </Box>
    </AudioPlayerProvider>
  )
}
