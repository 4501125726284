import { Box, CircularProgress, ListItem } from "@mui/material";
import { FC, useCallback, useEffect, useState } from "react";
import SvgComponent from "../../svgComponent";
import { useGetNotificationTypeListMutation } from 'src/service/api/notification-api';
import { PostNotification } from "src/models/notification";
import { enqueueSnackbar } from "notistack";
import { appConfig } from "src/app-config";
import { useNavigate } from "react-router-dom";
import { MessageTypeEnum } from "src/models/post";
import { useTranslation } from "react-i18next";
import NotificationListItem from "../NotificationListItem";

interface Props {
  handleReadNotification: (id: string) => void
  isChineseLanguage: boolean,
  handleIsLoading: (isLoading: boolean) => void;
}

const PostsTab = ({ handleReadNotification, isChineseLanguage, handleIsLoading }: Props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [postList, setPostList] = useState<PostNotification[]>([]);
  const [getNotificationTypeList, { isLoading: typeListLoading }] = useGetNotificationTypeListMutation();

  const handleClickPost = (message: PostNotification) => {
    if ((message.post_message && message.post_message.uri && message.post_message.uri.length > 0) // prod 返回的数据结构和dev返回的数据结构不一样，所以做个兼容
      || (message.origin_message?.message_content.uri && message.origin_message?.message_content.uri.length > 0) || message?.message?.messageid) { // 对post的点赞/评论
      const messageType = message.message_type;
      const subType = message.sub_type;
      const messageId = message.associated_id;
      if (messageType === MessageTypeEnum.LiveBroadcast) {
        if (subType === 'broadcast_start') {
          navigate(`${appConfig.paths.webLiveDetail}?id=${messageId}`);
        }
      } else if (messageType === MessageTypeEnum.Message) {
        switch (subType) {
          case 'like':
            navigate(`${appConfig.paths.webPostDetail}?id=${messageId}`);
            break;
          case 'comment':
            navigate(`${appConfig.paths.webPostDetail}?id=${messageId}`);
            break;
          default:
            navigate(`${appConfig.paths.webPostDetail}?id=${messageId}`);
            break;
        }
      } else if (messageType === MessageTypeEnum.Admin_Recommend) {
        navigate(`${appConfig.paths.webPostDetail}?id=${message?.message?.messageid}`);
      }
    } else { // 对评论的点赞/评论
      enqueueSnackbar(t("app.notification.does-not-support-jump"), { variant: "info" })
    }
    handleReadNotification(message.notification_id);
  }


  const handleLoadPostList = useCallback(() => {
    getNotificationTypeList({ type: ['post'] }).unwrap().then(res => {
      if (res && !res.isFailed && res.result) {
        const dataList = res.result.notification_list;
        setPostList(dataList);
      }
      else {
        enqueueSnackbar('Failed to load posts list')
      }
    })
  }, [])

  useEffect(() => {
    handleLoadPostList();
  }, [])

  return (
    <>
      <Box sx={{ bgcolor: 'background.paper', padding: "0px 10px", whiteSpace: 'no-wrap', maxHeight: "525px", overflow: "auto" }}>
        {typeListLoading ?
          <ListItem sx={{ justifyContent: "center" }}>
            <CircularProgress sx={{ marginTop: '20px' }} />
          </ListItem>
          :
          <>
            {postList.length > 0 ?
              <>
                {postList.map((item) => (
                  <NotificationListItem
                    key={item.notification_id}
                    notificationInfo={item}
                    isChineseLanguage={isChineseLanguage}
                    handleClick={() => { handleClickPost(item); }}
                    reloadList={handleLoadPostList}
                    handleIsLoading={isLoading => handleIsLoading(isLoading)}
                  />
                ))}
              </>
              :
              <ListItem sx={{ justifyContent: "center", height: '230px' }}>
                <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                  <SvgComponent iconName={'ico-no-results'} style={{ width: '48px', height: '49px' }} />
                  <Box sx={{ fontFamily: "SF Pro Text", fontSize: "14px", fontWeight: 500, lineHeight: "16.71px", color: "#717171", marginTop: "10px" }}>{t("app.alert.no-data")}</Box>
                </Box>
              </ListItem>
            }
          </>
        }
      </Box>
    </>
  )
}

export default PostsTab;