import {
  PostMediaType,
  UriEntity,
  getPostLocationName,
  getPostLocationSubName,
} from "./post";
import i18n from "src/app/i18n";
export interface LatLng {
  lat: number;
  lng: number;
}
export interface Localtion {
  lat: number;
  lng: number;
  zoom: number
}
export interface LocationResponse {
  geolocation_info: LocationEntity;
  status: number; // 200
}

export interface LocationEntity {
  connect_count: number;
  connect_local_count: number;
  first_message_data: any;
  first_message_id: string;
  first_message_time: number;
  first_user_id: string;
  geo_location: GeoLocation;
  index_list: string[];
  is_hot: boolean;
  latest_message_id: string;
  latest_message_time: number;
  loc_id: string;
  loc_name: string;
  popular_medias: UriEntity[];
  popular_names: PopularName[];
  popular_sub_loc?: string;
  timestamp: number;
  total_followed_count: number;
  total_local_visit_count: number;
  total_message_count: number;
  total_message_like_count: number;
  total_request_count: number;
  total_url_like_count: number;
  total_visit_count: number;
  live_broadcast_count: number;
  time_message_count: number;
  followed_by?: string[];
  primary_key: string;
}

export interface GeoLocation {
  box: {
    max_lat: number;
    min_lat: number;
    max_lon: number;
    min_lon: number;
  };
  geo_hash: string;
  geo_point_info_array: GeoPointInfo[];
  loc_id: string;
  precision: number;
}

export interface GeoPointInfo {
  geo_point_type: number;
  altitude: number;
  latitude: number;
  longitude: number;
  names?: PopularName[];
}

export interface PopularName {
  language: string;
  name: string;
}

export const getLocationFullName = (location: LocationEntity | undefined) => {
  if (!location) {
    return "";
  }
  if (location.loc_name) {
    return location.loc_name;
  }
  if (location.popular_names && location.popular_names.length > 0) {

    const name = location.popular_names.find((item) => item.language === i18n.language);
    if (name) {
      return name.name;
    }
  }
  return "";
};

const filterName = (location: LocationEntity | undefined) => {
  let popular_name = location?.popular_names.filter(
    (item) => item.language === i18n.language
  );
  if (popular_name && popular_name.length === 0) {
    const popular_names =
      location?.geo_location?.geo_point_info_array[0]?.names;
    popular_name =
      popular_names?.filter((item) => item.language === i18n.language) || [];
  }
  const locName = popular_name && popular_name[0]?.name;
  return locName;
};

export const getLocationName = (location: LocationEntity | undefined) => {
  if (!location) {
    return "";
  }
  if (location.loc_name) {
    return getPostLocationName(location.loc_name);
  }
  if (location.popular_names && location.popular_names.length > 0) {
    const locName = filterName(location);
    return getPostLocationName(locName);
  }
  return "";
};

export const getLocationSubName = (location: LocationEntity | undefined) => {
  if (!location) {
    return "";
  }
  if (location.loc_name) {
    return getPostLocationSubName(location.loc_name);
  }
  if (location.popular_names && location.popular_names.length > 0) {
    const locName = filterName(location);
    return getPostLocationSubName(locName);
  }
  return "";
};

export const getSubLocationName1 = (location: LocationEntity | undefined) => {
  if (!location) {
    return "";
  }
  if (location.popular_names?.length > 0) {
    return location.popular_names[0].name;
  } else {
    return location.loc_name || "";
  }
};

export const getLocationCoverImage = (location: LocationEntity | undefined) => {
  if (!location) {
    return "";
  }
  if (location.popular_medias?.length > 0) {
    const findMedia = location.popular_medias.find(
      (item) => item.type === PostMediaType.Photo
    );
    return findMedia?.compressed_url || findMedia?.url || "";
  } else {
    return "";
  }
};
