import { Box, CircularProgress, ListItem } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import SvgComponent from "../../svgComponent";
import { useGetNotificationTypeListMutation } from 'src/service/api/notification-api';
import { RequestNotification } from "src/models/notification";
import { enqueueSnackbar } from "notistack";
import { appConfig } from "src/app-config";
import NotificationListItem from "../NotificationListItem";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface Props {
  handleReadNotification: (id: string) => void
  isChineseLanguage: boolean,
}

const RequestsTab = ({ handleReadNotification, isChineseLanguage }: Props) => {
  const PAGE_SIZE = 20;
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [requestList, setRequestList] = useState<RequestNotification[]>([])
  const [getNotificationTypeList, { isLoading: typeListLoading }] = useGetNotificationTypeListMutation();

  const handleLoadRequestList = useCallback(() => {
    getNotificationTypeList({ type: ['request'], pageSize: PAGE_SIZE }).unwrap().then(res => {
      if (res && !res.isFailed && res.result) {
        const dataList = res.result.notification_list;
        setRequestList(dataList);
      }
      else {
        enqueueSnackbar('Failed to load requests list')
      }
    })
  }, [])

  useEffect(() => {
    handleLoadRequestList();
  }, [])

  const handleClickRequest = (id: string, notificationId: string, userId: string) => {
    navigate(`${appConfig.paths.requestDetail}?id=${id}`);
    handleReadNotification(notificationId);
  }

  return (
    <Box sx={{ bgcolor: 'background.paper', padding: "10px 10px", maxHeight: "525px", overflow: "auto", }}>
      {typeListLoading ?
        <ListItem sx={{ justifyContent: "center" }}>
          <CircularProgress sx={{ marginTop: '20px' }} />
        </ListItem>
        :
        <>
          {requestList.length > 0 ?
            <>
              {requestList.map((item) => (
                <NotificationListItem
                  key={item.notification_id}
                  notificationInfo={item}
                  isChineseLanguage={isChineseLanguage}
                  handleClick={() => { handleClickRequest(item.associated_id, item.notification_id, item.user.user_id); }}
                  reloadList={handleLoadRequestList}
                />
              ))}
            </>
            :
            <ListItem sx={{ justifyContent: "center", height: '230px' }}>
              <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                <SvgComponent iconName={'ico-no-results'} style={{ width: '48px', height: '49px' }} />
                <Box sx={{ fontFamily: "SF Pro Text", fontSize: "14px", fontWeight: 500, lineHeight: "16.71px", color: "#717171", marginTop: "10px" }}>{t("app.alert.no-data")}</Box>
              </Box>
            </ListItem>
          }
        </>
      }
    </Box>
  )
}

export default RequestsTab;