import { FC, useState, useMemo } from "react";
import { Locale, defaultLanguages } from '../../models/locale';
import { useTranslation } from 'react-i18next';
import { Box, IconButton, Menu, Tooltip, useMediaQuery, useTheme } from "@mui/material";
import { useDispatch } from "react-redux";
import { MODE_GRID, MODE_LARGE_PHOTO, setLanguage, setPostPhotoDisplayMode } from "../../service/redux/app-slice";
import IcoModeLarge from "src/images/layout/ico-large-mode.svg"
import IcoModeLargeActive from "src/images/layout/ico-large-mode-active.svg"
import IcoModeGrid from "src/images/layout/ico-gird-mode.svg"
import IcoModeGridActive from "src/images/layout/ico-gird-mode-active.svg"
import { makeStyles } from 'tss-react/mui';
import { useSelector } from "src/tools/toolkit/store";
import IcoSwitch from "src/images/layout/ico-switch.svg";
import IcoSwitchMobile from "src/images/header/ico-switch.svg"
import { onTableOrMobile } from 'src/tools/utils/appUtil';
import PhotoModeList from "./PhotoModeList";

const IsOnTableOrMobile = onTableOrMobile();

const useStyles = makeStyles()((theme) => {
  return {
    root: {
      position: 'relative',
    },
    languageButton: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
    },
    content: {
      position: 'absolute',
      top: '26px',
      right: '10px',
      borderRadius: '8px',
      background: '#fff',
      boxShadow: '0px 4px 20px 0px rgba(0, 0, 0, 0.10)',
      transition: 'all 0.4s',
      zIndex: 100
    },
    item: {
      width: '100%',
      padding: "10px 0",
      display: 'flex',
      flexDirection: "column",
      alignItems: 'center',
      justifyContent: 'center',
    },
    menuIcon: {
      cursor: "pointer"
    },
    menuText: {
      fontFamily: "Helvetica Neue",
      fontSize: "12px",
      fontWeight: 400,
    },
    menuTextSelected: {
      fontFamily: "Helvetica Neue",
      fontSize: "12px",
      fontWeight: 400,
      background: "#3B92F9",
      color: "white",
      borderRadius: "12px",
      width: "128px",
      textAlign: "center",
      padding: "6px 0"
    }

  };
});

const SwitchPostPhotoMode: FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { classes } = useStyles();
  const { postPhotoDisplayMode } = useSelector(state => state.app);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };




  const isMobileOrTable = useMemo(() => {
    return isMobile || IsOnTableOrMobile;
  }, [isMobile])


  const icon = useMemo(() => {
    return isMobileOrTable ? IcoSwitchMobile : IcoSwitch
  }, [isMobileOrTable])
  return (
    <Box className={classes.root}>
      <Tooltip title={t("app.header.switch-post-photo-mode")}>
        <IconButton size="small" onClick={(event) => setAnchorEl(event.currentTarget)}
          style={{
            width: isMobileOrTable ? "24px" : "40px",
            height: isMobileOrTable ? "24px" : "40px",
            marginBottom: isMobileOrTable ? '7px' : 'unset'
          }}
        >
          <img src={icon} alt="switch_mode" style={{ cursor: 'pointer' }} />
        </IconButton>
      </Tooltip>
      {
        anchorEl &&
        <Menu
          anchorEl={anchorEl}
          id="language-menu"
          open={open}
          onClose={handleClose}
          // PaperProps={{
          //   elevation: 0,
          //   sx: {
          //     overflow: 'visible',
          //     filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
          //     mt: '15px',
          //     '&:before': {
          //       content: '""',
          //       display: 'block',
          //       position: 'absolute',
          //       top: 0,
          //       right: 95,
          //       width: 10,
          //       height: 10,
          //       bgcolor: 'background.paper',
          //       transform: 'translateY(-50%) rotate(45deg)',
          //       zIndex: 0,
          //       [theme.breakpoints.down('sm')]: {
          //         right: 10,
          //       }
          //     },
          //     [theme.breakpoints.down('sm')]: {
          //       left: 'unset !important',
          //       right: 5,
          //     }
          //   },
          // }}
          MenuListProps={{
            sx: {
              padding: 0,
              width: '200px',
              [theme.breakpoints.down('sm')]: {
                width: '160px',

              }
            }
          }}
          transformOrigin={{ horizontal: 'center', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
        >
          <PhotoModeList closeDialog={() => setAnchorEl(null)} />
        </Menu>
      }

    </Box>
  )

}

export default SwitchPostPhotoMode;