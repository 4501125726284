import { createApi } from "@reduxjs/toolkit/query/react";
import { Response, Result, Value } from "../../models/common";
import { baseQueryWithReauth } from "../../tools/toolkit/helper";

export const followApi = createApi({
  reducerPath: "follow",
  baseQuery: baseQueryWithReauth,
  endpoints(builder) {
    return {
      getFollowedLocations: builder.mutation<
        Result<any>,
        { pageSize?: number; startKey?: string; forwardOrder?: boolean }
      >({
        query: ({ pageSize, startKey, forwardOrder }) => ({
          url: `getfollowedlocationlist`,
          method: "POST",
          body: {
            pagesize: pageSize || 20,
            startkey: startKey,
            forwardorder: forwardOrder,
          },
        }),
        transformResponse: (res: Response<any>) => new Value(res),
      }),

      getFollowingUsers: builder.mutation<
        Result<any>,
        { pageSize?: number; startKey?: string; forwardOrder?: boolean }
      >({
        query: ({ pageSize, startKey, forwardOrder }) => ({
          url: `getfolloweduserlist`,
          method: "POST",
          body: {
            followed_user: 0,
            pagesize: pageSize || 20,
            start_key: startKey,
            forwardorder: forwardOrder,
          },
        }),
        transformResponse: (res: Response<any>) => new Value(res),
      }),

      getFollowers: builder.mutation<
        Result<any>,
        { pageSize?: number; startKey?: string; forwardOrder?: boolean }
      >({
        query: ({ pageSize, startKey, forwardOrder }) => ({
          url: `getfolloweduserlist`,
          method: "POST",
          body: {
            followed_user: 1,
            pagesize: pageSize || 20,
            start_key: startKey,
            forwardorder: forwardOrder,
          },
        }),
        transformResponse: (res: Response<any>) => new Value(res),
      }),

      getFriends: builder.mutation<
        Result<any>,
        { pageSize?: number; startKey?: string; forwardOrder?: boolean }
      >({
        query: ({ pageSize, startKey, forwardOrder }) => ({
          url: `getfolloweduserlist`,
          method: "POST",
          body: {
            followed_user: 2,
            pagesize: pageSize || 20,
            start_key: startKey,
            forwardorder: forwardOrder,
          },
        }),
        transformResponse: (res: Response<any>) => new Value(res),
      }),

      getFollowedList: builder.mutation<
        Result<any>,
        { pageSize?: number; startKey?: string; forwardOrder?: boolean }
      >({
        query: ({ pageSize, startKey, forwardOrder }) => ({
          url: `getfollowedlist`,
          method: "POST",
          body: {
            pagesize: pageSize || 20,
            startkey: startKey,
            forwardorder: forwardOrder,
          },
        }),
        transformResponse: (res: Response<any>) => new Value(res),
      }),

      addFollowUser: builder.mutation<Result<any>, { userId: string }>({
        query: ({ userId }) => ({
          url: `updatefolloweduser`,
          method: "POST",
          body: {
            isAdd: true,
            user_id: userId,
          },
        }),
        transformResponse: (res: Response<any>) => new Value(res),
      }),

      cancelFollowUser: builder.mutation<Result<any>, { userId: string }>({
        query: ({ userId }) => ({
          url: `updatefolloweduser`,
          method: "POST",
          body: {
            isAdd: false,
            user_id: userId,
          },
        }),
        transformResponse: (res: Response<any>) => new Value(res),
      }),

      addFollowLocation: builder.mutation<Result<any>, { locId: string }>({
        query: ({ locId }) => ({
          url: `updatefollowedlocation`,
          method: "POST",
          body: {
            isAdd: true,
            loc_id: locId,
          },
        }),
        transformResponse: (res: Response<any>) => new Value(res),
      }),

      cancelFollowLocation: builder.mutation<Result<any>, { locId: string }>({
        query: ({ locId }) => ({
          url: `updatefollowedlocation`,
          method: "POST",
          body: {
            isAdd: false,
            loc_id: locId,
          },
        }),
        transformResponse: (res: Response<any>) => new Value(res),
      }),
    };
  },
});

export const {
  useGetFollowedLocationsMutation,
  useGetFollowingUsersMutation,
  useGetFollowersMutation,
  useGetFriendsMutation,
  useGetFollowedListMutation,
  useAddFollowUserMutation,
  useCancelFollowUserMutation,
  useAddFollowLocationMutation,
  useCancelFollowLocationMutation,
} = followApi;
