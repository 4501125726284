import { createApi } from "@reduxjs/toolkit/query/react";
import { Response, Result, Value } from "../../models/common";
import { baseQueryWithReauth } from "../../tools/toolkit/helper";
import {
  TravelListProps,
  TravelListResponse,
  CreateTravelProps,
  CreateTravelResponse,
  GetTravelDetailProps,
  GetTravelDetailResponse,
  GetUserTravelListProps,
  DeleteTravelProps,
  ViewCountProps,
  LikeTravelProps,
  GetTravelPostProps,
  GetLocationPostProps,
  GetLocationPostResponse
} from "src/models/travel";

export const travelApi = createApi({
  reducerPath: "travel",
  baseQuery: baseQueryWithReauth,
  endpoints(builder) {
    return {
      // 获取旅游攻略列表
      getTravelList: builder.mutation<
        Result<TravelListResponse>,
        TravelListProps
      >({
        query: (TravelListProps) => ({
          url: `travel/get_recommend_travel_list`,
          method: "POST",
          body: TravelListProps,
        }),
        transformResponse: (res: Response<TravelListResponse>) =>
          new Value(res),
      }),
      // 创建一个旅游攻略
      createTravel: builder.mutation<
        Result<CreateTravelResponse>,
        CreateTravelProps
      >({
        query: (CreateTravelProps) => ({
          url: `travel/create_travel`,
          method: "POST",
          body: CreateTravelProps,
        }),
        transformResponse: (res: Response<CreateTravelResponse>) =>
          new Value(res),
      }),
      // 获取一个旅游攻略详情
      getTravelDetail: builder.mutation<
        Result<GetTravelDetailResponse>,
        GetTravelDetailProps
      >({
        query: (GetTravelDetailProps) => ({
          url: `travel/get_travel_by_id`,
          method: "POST",
          body: GetTravelDetailProps,
        }),
        transformResponse: (res: Response<GetTravelDetailResponse>) =>
          new Value(res),
      }),
      // 获取一个用户的攻略列表
      getUserTravelList: builder.mutation<
        Result<TravelListResponse>,
        GetUserTravelListProps
      >({
        query: (GetUserTravelListProps) => ({
          url: `travel/get_travel_by_user`,
          method: "POST",
          body: GetUserTravelListProps,
        }),
        transformResponse: (res: Response<TravelListResponse>) =>
          new Value(res),
      }),
      // 删除一个攻略
      deleteTravel: builder.mutation<Result<undefined>, DeleteTravelProps>({
        query: (DeleteTravelProps) => ({
          url: `travel/delete_travel`,
          method: "POST",
          body: DeleteTravelProps,
        }),
        transformResponse: (res: Response<undefined>) => new Value(res),
      }),
      // 攻略浏览量+1
      addViewCount: builder.mutation<Result<undefined>, ViewCountProps>({
        query: (ViewCountProps) => ({
          url: `travel/update_view_count`,
          method: "POST",
          body: ViewCountProps,
        }),
        transformResponse: (res: Response<undefined>) => new Value(res),
      }),
      // 点赞 & 取消点赞
      likeTravel: builder.mutation<Result<undefined>, LikeTravelProps>({
        query: (LikeTravelProps) => ({
          url: `travel/update_like_count`,
          method: "POST",
          body: LikeTravelProps,
        }),
        transformResponse: (res: Response<undefined>) => new Value(res),
      }),
      // 获取一个点附近的post列表
      getNearbyPostList: builder.mutation<any, GetTravelPostProps>({
        query: (GetTravelPostProps) => ({
          url: `gethotmessagelist`,
          method: "POST",
          body: GetTravelPostProps,
        }),
      }),
      // 获取一个location引用的帖子列表
      getLocationPostList: builder.mutation<Result<GetLocationPostResponse>, GetLocationPostProps>({
        query: (GetLocationPostProps) => ({
          url: `travel/get_post_list_by_location_id`,
          method: "POST",
          body: GetLocationPostProps,
        }),
        transformResponse: (res: Response<GetLocationPostResponse>) => new Value(res),
      }),
    };
  },
});

export const {
  useGetTravelListMutation,
  useCreateTravelMutation,
  useGetTravelDetailMutation,
  useGetUserTravelListMutation,
  useDeleteTravelMutation,
  useAddViewCountMutation,
  useLikeTravelMutation,
  useGetNearbyPostListMutation,
  useGetLocationPostListMutation
} = travelApi;
