import { Box, CircularProgress, ListItem } from "@mui/material";
import { FC, useCallback, useEffect, useState } from "react";
import SvgComponent from "../../svgComponent";
import { useGetNotificationTypeListMutation } from 'src/service/api/notification-api';
import { PostNotification } from "src/models/notification";
import { enqueueSnackbar } from "notistack";
import { appConfig } from "src/app-config";
import NotificationListItem from "../NotificationListItem";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface Props {
  handleReadNotification: (id: string) => void
  isChineseLanguage: boolean,
}

const RewardTab = ({ handleReadNotification, isChineseLanguage }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [rewardList, setRewardList] = useState<PostNotification[]>([])
  const [getNotificationTypeList, { isLoading: typeListLoading }] = useGetNotificationTypeListMutation();

  const handleClickReward = (item: PostNotification) => {
    navigate(appConfig.paths.wallet);
  }

  const handleLoadRewardList = useCallback(() => {
    getNotificationTypeList({ type: ['finance'] }).unwrap().then(res => {
      if (res && !res.isFailed && res.result) {
        const dataList = res.result.notification_list;
        setRewardList(dataList);
      }
      else {
        enqueueSnackbar('Failed to load posts list')
      }
    })
  }, [])

  useEffect(() => {
    handleLoadRewardList();
  }, [])

  return (
    <Box sx={{ bgcolor: 'background.paper', padding: "0px 10px", maxHeight: "525px", overflow: "auto", }}>
      {typeListLoading ?
        <ListItem sx={{ justifyContent: "center" }}>
          <CircularProgress sx={{ marginTop: '20px' }} />
        </ListItem>
        :
        <>
          {rewardList.length > 0 ?
            <>
              {rewardList.map((item) => (
                <NotificationListItem
                  key={item.notification_id}
                  notificationInfo={item}
                  isChineseLanguage={isChineseLanguage}
                  handleClick={() => { handleClickReward(item); }}
                  reloadList={handleLoadRewardList}
                />
              ))}
            </>
            :
            <ListItem sx={{ justifyContent: "center", height: '230px' }}>
              <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                <SvgComponent iconName={'ico-no-results'} style={{ width: '48px', height: '49px' }} />
                <Box sx={{ fontFamily: "SF Pro Text", fontSize: "14px", fontWeight: 500, lineHeight: "16.71px", color: "#717171", marginTop: "10px" }}>{t("app.alert.no-data")}</Box>
              </Box>
            </ListItem>
          }
        </>
      }
    </Box>
  )
}

export default RewardTab;