import React, { createContext, useContext, useState, useCallback } from 'react';
import BenzAMRRecorder from 'benz-amr-recorder';

interface AudioPlayerContextProps {
  currentAmr: BenzAMRRecorder | null;
  setCurrentAmr: (amr: BenzAMRRecorder | null) => void;
}

interface Props {
  children: React.ReactNode;
}

const AudioPlayerContext = createContext<AudioPlayerContextProps | undefined>(undefined);

export const AudioPlayerProvider = ({ children }: Props) => {
  const [currentAmr, setCurrentAmr] = useState<BenzAMRRecorder | null>(null);

  return (
    <AudioPlayerContext.Provider value={{ currentAmr, setCurrentAmr }}>
      {children}
    </AudioPlayerContext.Provider>
  );
};

export const useAudioPlayer = () => {
  const context = useContext(AudioPlayerContext);
  if (context === undefined) {
    throw new Error('useAudioPlayer must be used within an AudioPlayerProvider');
  }
  return context;
};
