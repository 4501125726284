import { UserEntity } from "src/models/chatroom";

export interface ChatMessage {
  id: string;
  type: string; //text,file
  content: string; // shown message content
  origin_content?: string;
  origin_message?: WebsocketChatMessage | WebsocketSendMessage; // AI自动回复的消息里的origin_message 是WebsocketChatMessage类型，主动回复发出去的消息里的origin_message是WebsocketSendMessage类型。
  time?: number;
  sender_id: string;
  sender_name: string;
  sender_name_cn?: string;
  sender_avatar: string;
  sender_type?: string; 
  status?: MessageStatusEnum;
  message_read?: boolean;
  thumbnail?: string;
  is_local?: boolean;
  duration?: string;
  mentions?: UserEntity[];
  post_list?: string[]; // post对应的图片的URL
}

export enum MessageStatusEnum {
  Sending = 1,
  Finished = 2,
}

export interface WebSocketMessage {
  action: string;
  messages: WebsocketChatMessage[];
  start_key: string;
}

export interface WebsocketSendMessage {
  content: string;
  id: string;
  type: string;
  user_avatar: string;
  user_id: string;
  user_name: string;
  thumbnail?: string;
  duration?: string;
  mentions?: UserEntity[];
  // 自己回复发出去的origin_message
  origin_message?: WebsocketSendMessage;
  post_list?: string[];
}

export interface WebsocketChatMessage {
  action?: string;
  message_dialog_id?: string;
  message: WebsocketSendMessage;
  // 收到的ai-answer的origin_message
  origin_message?: WebsocketChatMessage;
  time: number;
  sender: {
    user_avatar: string;
    user_id: string;
    user_name: string;
    user_name_cn?: string;
    type?: string;
  };
  message_id?: string;
  is_local?: boolean;
}

export const transferChatMessage = (
  item: WebsocketChatMessage
): ChatMessage => {
  return {
    id: item.message.id,
    type: item.message.type,
    content: item.message.content, // shown message content
    thumbnail: item.message.thumbnail, // video的第一帧图片
    origin_content: item?.origin_message?.message?.content,
    // 后台返回的ai_answer类型的message, origin_message 在最外层，自己回复的消息origin_message就在message里头。
    origin_message: item.message.type === 'ai_answer' ? item.origin_message : item.message.origin_message,
    time: item.time,
    sender_id: item.sender.user_id || item.message.user_id,
    sender_name: item.sender.user_name || item.message.user_name,
    sender_name_cn: item.sender.user_name_cn || item.sender.user_name,
    sender_avatar: item.sender.user_avatar || item.message.user_avatar,
    sender_type: item.sender.type,
    is_local: item?.is_local || undefined,
    duration: item.message.duration,
    mentions: item.message.mentions,
    post_list: item.message.post_list,
  };
};

export const transferChatMessageList = (
  list: WebsocketChatMessage[]
): ChatMessage[] => {
  if (!list || list.length === 0) {
    return [];
  }
  return list.map((item) => transferChatMessage(item));
};
