import * as crypto from "crypto";
import sha256 from "crypto-js/sha256";
import Base64 from "crypto-js/enc-base64";
import * as jwt from "jsonwebtoken";
import { useSelector } from "../toolkit/store";
import { Salt } from "src/models/user";
import { UserProfile } from "src/models/user";
import { appConfig } from "src/app-config"
const genRandom = (length = 32): any => {
  return new Promise((resolve, reject) => {
    crypto.randomBytes(length, (err: any, buf: any) => {
      if (err) {
        reject(err);
      } else {
        resolve(buf.toString("base64"));
      }
    });
  });
};

export const uuid = (): string => {
  return crypto.randomBytes(16).toString("hex");
};

export interface TokenData {
  userid: string;
  exp: number;
  iat: number;
}

export const decodeJWT = function (token: string): TokenData {
  const decoded = jwt.decode(token, { complete: true });
  return decoded ? decoded.payload : null;
};

export default genRandom;

export interface AuthStatus {
  isLogin: boolean;
  // isMember: boolean
}

export interface GetAuthStatus {
  (): AuthStatus;
}

export const getAuthStatus = (): GetAuthStatus => {
  return (): AuthStatus => {
    const result = useSelector((state) => {
      const authStatus: AuthStatus = {
        isLogin: false,
        // isMember: false
      };
      const { credential } = state.app;
      if (credential) {
        authStatus.isLogin = true;
        // if (credential.membership_info?.ending_date && credential.membership_info.ending_date > Date.now()) {
        //   authStatus.isMember = true;
        // }
      }

      return authStatus;
    });
    return result;
  };
};

export interface AuthRole {
  isAdmin: boolean;
  isTeacher: boolean;
  isAgent: boolean;
  isSchoolAdmin: boolean;
}

export interface GetAuthRole {
  (): AuthRole;
}

export function createHash(text: string, secrect: string): string {
  return Base64.stringify(sha256(text + secrect));
}

export function createPasswordHash(pwd: string, salt: Salt): string {
  return createHash(createHash(pwd, salt.client_salt), salt.server_salt);
}

export const SIGN_UP_RESERVED_USER_IDS_KEY = "reserved_user_ids";

export interface ReservedUser {
  userId: string;
  uuid: string;
}

export const ErrorCode = {
  SIGN_UP_USER_ID_EXIST: 201201,
  SIGN_UP_VERIFY_CODE_INCORRECT: 202005,
  SIGN_IN_USER_ID_NOT_EXIST: 201202,
  SIGN_IN_PASSWORD_INCORRECT: 201118,
  SIGN_IN_NEED_VERIFY: 201206,
  SING_IN_TRAFFIC_LIMIT: 201133,
  SING_IN_MULTIPLE_ERRORS: 201134
};

export function isOfficialAccount(credential: UserProfile | undefined) {
  return credential && credential.is_official;
}

export function isAdmin(credential: UserProfile | undefined) {
  return credential && credential.is_admin;
}

export const splitDate = (date: string) => {
  return date.split("-").map((item) => parseInt(item));
}

export const isPromote = (): boolean => {
  return window.location.pathname === appConfig.paths.adminPosts;
}

export const isAdminPost = (): boolean => {
  return window.location.pathname === appConfig.paths.adminPosts;
}

export const isForSale = (): boolean => {
  return window.location.pathname === appConfig.paths.market;
}

export const isShowMoreButton = (): boolean => {
  return window.location.pathname === appConfig.paths.worldview
}

export const isShowRecommendIcon = ():boolean => {
  return window.location.pathname === appConfig.paths.root || window.location.pathname === appConfig.paths.following
}

