import { createApi } from '@reduxjs/toolkit/query/react'
import { Response, Result, Value } from '../../models/common';
import { baseQueryWithReauth } from '../../tools/toolkit/helper';


interface validCustomerExistResponse {
    customer_id: number, 
    register_email: string, 
    is_exist: boolean
}

interface createShopifyCustomerResponse {
    customer_id: number;
    register_email: string;
}

export const customerApi = createApi({
    reducerPath: 'customer',
    baseQuery: baseQueryWithReauth, 
    endpoints(builder) {
        return {
            validCustomerExist: builder.mutation<Result<validCustomerExistResponse>, {}>({
                query: () => ({
                    url: 'customer',
                    method: 'POST',
                    body: {
                        request_type:"valid_customer_exist"
                    }
                }),
                transformResponse: (res: Response<validCustomerExistResponse>) => new Value<validCustomerExistResponse>(res)
            }),
            createShopifyCustomer: builder.mutation<Result<createShopifyCustomerResponse>, {
                email: string, 
                password: string, 
            }>({
                query: ({ email, password }) => ({
                    url: 'customer',
                    method: 'POST',
                    body: {
                        request_type:"create_shopify_customer",
                        request_data: {
                            email,
                            password
                        }
                    }
                }),
                transformResponse: (res: Response<createShopifyCustomerResponse>) => new Value<createShopifyCustomerResponse>(res)
            })
        }
    }
})


export const { useValidCustomerExistMutation, useCreateShopifyCustomerMutation
} = customerApi