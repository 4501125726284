import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { settings } from '../settings'

const keys = settings.keys

export interface LocationState {
  longitude: string | null,
  latitude: string | null,
  geoHash: string | null,
}

function _initialState(): LocationState {
  const locationJson = localStorage.getItem(keys.location);
  if (locationJson) {
    const locationObj:LocationState = JSON.parse(locationJson);
    return locationObj;
  } else {
    return {
      longitude: null,
      latitude: null,
      geoHash: null,
    }
  }
  
}

const locationSlice = createSlice({
  name: keys.location,
  initialState: _initialState(),
  reducers: {
    saveLocation(state, action: PayloadAction<LocationState>) {
      state.longitude = action.payload.longitude;
      state.latitude = action.payload.latitude;
      state.geoHash = action.payload.geoHash;
      localStorage.setItem(keys.location, JSON.stringify(action.payload))
    },
    clearLocation(state) {
      state.longitude = null
      state.latitude = null
      state.geoHash = null
      localStorage.removeItem(keys.location)
    },
  }
})

export const { saveLocation, clearLocation } = locationSlice.actions
export default locationSlice.reducer
