import { createApi } from "@reduxjs/toolkit/query/react";
import { Response, Result, Value } from "../../models/common";
import { baseQueryWithReauth } from "../../tools/toolkit/helper";
import {
  QuestionnaireEntity,
  QuestionnaireResponseData,
  RegionEntity,
  TagsResponseData,
} from "src/models/questionnaire";

export const questionnaireApi = createApi({
  reducerPath: "questionnaire",
  baseQuery: baseQueryWithReauth,
  endpoints(builder) {
    return {
      submitUserInterests: builder.mutation<
        Result<any>,
        { entity: QuestionnaireEntity }
      >({
        query: ({ entity }) => ({
          url: `userinteresting`,
          method: "POST",
          body: {
            type: "write",
            ...entity,
          },
        }),
        transformResponse: (res: Response<any>) => new Value(res),
      }),

      getUserInterests: builder.mutation<Result<QuestionnaireResponseData>, {}>(
        {
          query: () => ({
            url: `userinteresting`,
            method: "POST",
            body: {
              type: "get",
            },
          }),
          transformResponse: (res: Response<QuestionnaireResponseData>) =>
            new Value(res),
        }
      ),

      getInterestTagList: builder.mutation<Result<TagsResponseData>, {}>({
        query: () => ({
          url: `tag`,
          method: "POST",
          body: {
            type: "get_tag_list",
          },
        }),
        transformResponse: (res: Response<TagsResponseData>) => new Value(res),
      }),

      getInterestRegionList: builder.mutation<Result<RegionEntity[]>, {}>({
        query: () => ({
          url: `tag`,
          method: "POST",
          body: {
            type: "get_region_list",
          },
        }),
        transformResponse: (res: Response<RegionEntity[]>) => new Value(res),
      }),
    };
  },
});

export const {
  useSubmitUserInterestsMutation,
  useGetUserInterestsMutation,
  useGetInterestTagListMutation,
  useGetInterestRegionListMutation,
} = questionnaireApi;
