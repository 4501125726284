import { createApi } from '@reduxjs/toolkit/query/react'
import { Response, Result, Value } from '../../models/common';
import { baseQueryWithReauth } from '../../tools/toolkit/helper';


export const logApi = createApi({
  reducerPath: 'log',
  baseQuery: baseQueryWithReauth,
  endpoints(builder) {
    return {
      marker: builder.mutation<Result<any>, {}>({
        query: () => ({
          url: `/activity_recording`,
          method: 'POST',
          body: {
            activity_type: "active_user"
          }
        }),
        transformResponse: (res: Response<any>) => new Value(res)
      }),
      updateCount: builder.mutation < Result<any>, { messageId: string, deviceId:string }>({
        query: ({ messageId, deviceId }) => ({
          url: `/update_count`,
          method: 'POST',
          body: {
            message_id: messageId,
            message_type: 1,
            device_id: deviceId,
            action: "view"
          }
        }),
        transformResponse: (res: Response<any>) => new Value(res)
      }),
      userEnterLeave: builder.mutation<Result<any>, { 
        session_id: string
       }>({
         query: ({ session_id }) => ({
          url: `/activity_recording`,
          method: 'POST',
          body: {
            activity_type:"web_session",
            session_id
          }
        }),
        transformResponse: (res: Response<any>) => new Value(res)
      })
    }
  }
});

export const { 
  useMarkerMutation, 
  useUpdateCountMutation,
  useUserEnterLeaveMutation
 } = logApi;