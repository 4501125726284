import { createApi } from "@reduxjs/toolkit/query/react";
import { Response, Result, Value } from "../../models/common";
import { buildQueryWithReAuth } from "../../tools/toolkit/helper";
import {
  TotalVisitsResponse,
  KeywordListResponse,
  AccessListResponse,
  ActionListResponse,
  IPListResponse
} from "src/models/admin-promotion";

export const adminPromotionApi = createApi({
  reducerPath: "adminPromotion",
  baseQuery: buildQueryWithReAuth(process.env.REACT_APP_ADMIN_API_URL),
  endpoints(builder) {
    return {
      getTotalVisits: builder.mutation<
        Result<TotalVisitsResponse>,
        {
          start_timestamp?: number;
          end_timestamp?: number;
        }
      >({
        query: ({ start_timestamp, end_timestamp }) => ({
          url: `ads_referral_tracking`,
          method: "POST",
          body: {
            operation: "get_visit_count_by_time",
            platform: "baidu",
            start_timestamp,
            end_timestamp,
          },
        }),
        transformResponse: (res: Response<TotalVisitsResponse>) =>
          new Value(res),
      }),
      getKeywordList: builder.mutation<
        Result<KeywordListResponse>,
        {
          start_key: string;
          page_size: number;
          forwardOrder: boolean;
          start_timestamp: number;
          end_timestamp: number;
        }
      >({
        query: ({
          start_key,
          page_size,
          forwardOrder,
          start_timestamp,
          end_timestamp,
        }) => ({
          url: `ads_referral_tracking`,
          method: "POST",
          body: {
            start_key,
            page_size,
            operation: "get_keyword_statistics",
            platform: "baidu",
            forwardorder: forwardOrder,
            start_timestamp,
            end_timestamp,
          },
        }),
        transformResponse: (res: Response<KeywordListResponse>) =>
          new Value(res),
      }),
      getAccessList: builder.mutation<
        Result<AccessListResponse>,
        {
          start_timestamp?: number;
          end_timestamp?: number;
          start_key: string;
          keyword?: string;
          forwardOrder: boolean;
          page_size: number;
          operation: string;
          all?: boolean;
          visit?: boolean;
          download?: boolean;
        }
      >({
        query: ({
          start_timestamp,
          end_timestamp,
          start_key,
          keyword,
          forwardOrder,
          page_size,
          operation,
          all,
          visit,
          download
        }) => ({
          url: `ads_referral_tracking`,
          method: "POST",
          body: {
            start_timestamp,
            end_timestamp,
            start_key,
            operation,
            platform: "baidu",
            keyword,
            page_size,
            forwardorder: forwardOrder,
            all,
            visit,
            download
          },
        }),
        transformResponse: (res: Response<AccessListResponse>) =>
          new Value(res),
      }),
      getActionList: builder.mutation<
        Result<ActionListResponse>,
        {
          start_key: string;
          session_id: string;
          forwardOrder: boolean;
          page_size: number;
        }
      >({
        query: ({ start_key, session_id, forwardOrder, page_size }) => ({
          url: `ads_referral_tracking`,
          method: "POST",
          body: {
            start_key,
            operation: "get_user_action",
            session_id,
            platform: "baidu",
            forwardorder: forwardOrder,
            page_size,
          },
        }),
        transformResponse: (res: Response<ActionListResponse>) =>
          new Value(res),
      }),
      getIPList: builder.mutation<
        Result<IPListResponse>,
        {
          timestamp: number;
          start_key: string;
          forwardOrder: boolean;
          page_size: number;
          period: string;
        }
      >({
        query: ({ start_key, forwardOrder, page_size, timestamp, period }) => ({
          url: `ads_referral_tracking`,
          method: "POST",
          body: {
            operation: "get_ip_list_by_time",
            start_key,
            platform: "baidu",
            forwardorder: forwardOrder,
            page_size,
            timestamp,
            period
          },
        }),
        transformResponse: (res: Response<IPListResponse>) =>
          new Value(res),
      }),
      adjustIpStatus: builder.mutation<
        Result<undefined>,
        {
          ipStatus:boolean
        }
      >({
        query: ({ ipStatus }) => ({
          url: `adminmanamgement`,
          method: "POST",
          body: {
            request_type:"ip_lookup",
            request_data:{
              ip_lookup:ipStatus
            }
          },
        }),
        transformResponse: (res: Response<undefined>) =>
          new Value(res),
      })
    };
  },
});

export const {
  useGetTotalVisitsMutation,
  useGetKeywordListMutation,
  useGetAccessListMutation,
  useGetActionListMutation,
  useGetIPListMutation,
  useAdjustIpStatusMutation
} = adminPromotionApi;
