import { useEffect } from "react";
import { useUserEnterLeaveMutation } from "src/service/api/log-api";
import { isCN } from "src/tools/utils/appUtil";
const useTrackUserActivity = () => {
  const [send] = useUserEnterLeaveMutation();
  useEffect(() => {
    const bd_vid = new URLSearchParams(window.location.search).get("bd_vid");
    if (!isCN || !bd_vid) return;
    const handleEnter = () => {
      send({ session_id: bd_vid });
    };
    handleEnter();
    const handleLeave = () => {
      const data = JSON.stringify({
        session_id: bd_vid,
        activity_type: "web_session",
      });
      const url = `${process.env.REACT_APP_API_URL}/activity_recording`;
      navigator.sendBeacon(url, data);
    };
    window.addEventListener("beforeunload", handleLeave);
  }, [send]);
};

export default useTrackUserActivity;
