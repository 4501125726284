import { createApi } from "@reduxjs/toolkit/query/react";
import { Response, Result, Value } from "../../models/common";
import { ConfigResponse } from "src/models/admin-promotion";
import { baseQueryWithReauth } from "../../tools/toolkit/helper";

export const configApi = createApi({
  reducerPath: "config",
  baseQuery: baseQueryWithReauth,
  endpoints(builder) {
    return {
      getConfig: builder.mutation<Result<ConfigResponse>, {}>({
        query: () => ({
          url: `getconfig`,
          method: "POST",
          body: {},
        }),
        transformResponse: (res: Response<ConfigResponse>) => new Value(res),
      }),
    };
  },
});

export const { useGetConfigMutation } = configApi;
