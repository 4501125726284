import Axios from 'axios';
import { configWx } from "src/tools/utils/wechat";
import { enqueueSnackbar } from "notistack"
export const WX_PUBLIC_APP_ID = "wxe872a1db0f540ca4";

const isDev = process.env.REACT_APP_ENV === "dev";

const showMessageOnDev = (
    message: string,
    variant: "info" | "warning" | "error" | "success"
) => {
    if (isDev) {
        enqueueSnackbar(message, { variant });
    }
};

const getSignature = async (setLoading) => {
    setLoading(true);
    showMessageOnDev("start to request /wechat-signature", "info");
    const configUrl = window.location.href.split("#")[0];
    try {
        const response = await Axios.create({
            baseURL: "https://global-rest.anylocal.cn",
            headers: {
                "Content-Type": "application/json",
            },
        }).post("/wechat/wechat-signature", { url: configUrl });
        setLoading(false);
        if (response && response.data && response.status === 200) {
            const res = response.data.data;
            if (res) {
                configWx(WX_PUBLIC_APP_ID, res.timestamp, res.nonceStr, res.signature);
                showMessageOnDev("Get Wechat signature success:" + JSON.stringify(response), "success");
            } else {
                showMessageOnDev("Get empty Wechat signature:" + JSON.stringify(response), "error");
            }
        } else {
            showMessageOnDev("Get Wechat signature failed:" + JSON.stringify(response), "error");
        }
    } catch (error) {
        setLoading(false);
        showMessageOnDev("Get Wechat signature error:" + JSON.stringify(error), "error");
    }
};

export default getSignature;
