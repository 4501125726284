import { type RouteObject } from "react-router-dom";
import { paths } from 'src/app-config';

import Loadable from "src/components/loadable/Loadable";


const WechatLoginCallbackPage = Loadable(async () => await import("src/pages/auth/WechatLoginCallback"));
const ToolRoutes: RouteObject = {
  path: '/',
  children: [{
    path: paths.wechatLoginCallback,
    element: <WechatLoginCallbackPage />
  }]
};

export default ToolRoutes;