import {
  Box
} from "@mui/material";
import { useRef, useState, useEffect, useCallback } from "react";
import { ChatMessage } from 'src/models/chat';
import BenzAMRRecorder from 'benz-amr-recorder';
import { useAudioPlayer } from 'src/providers/AudioPlayerContext';
import IcoVoiceGrey1 from "src/images/chatroom/ico-voice-grey1.svg";
import IcoVoiceGrey2 from "src/images/chatroom/ico-voice-grey2.svg";
import IcoVoiceGrey3 from "src/images/chatroom/ico-voice-grey3.svg";
import IcoVoiceGrayRight1 from "src/images/chatroom/ico-voice-gray-right1.svg";
import IcoVoiceGrayRight2 from "src/images/chatroom/ico-voice-gray-right2.svg";
import IcoVoiceGrayRight3 from "src/images/chatroom/ico-voice-gray-right3.svg";

interface Props {
  item: ChatMessage,
  isOwn: boolean,
}

const greyImages = [IcoVoiceGrey1, IcoVoiceGrey2, IcoVoiceGrey3];
const greyRightImages = [IcoVoiceGrayRight1, IcoVoiceGrayRight2, IcoVoiceGrayRight3];

const AudioPlayer = ({ item, isOwn }: Props) => {
  const { currentAmr, setCurrentAmr } = useAudioPlayer(); // 使用上下文
  const [amr, setAmr] = useState<BenzAMRRecorder | null>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const amrFile = item.content;

  const images = isOwn ? greyRightImages : greyImages;
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const intervalIdRef = useRef<NodeJS.Timeout | null>(null);

  const onAmr = useCallback((a: BenzAMRRecorder) => {
    if (amr) {
      amr.stop();
    }
    setAmr(a);
    setIsPlaying(false);
    setIsLoaded(true);
  }, [amr, setAmr, setIsPlaying]);

  const initAmrWithUrl = useCallback(async (url: string) => {
    try {
      const a = new BenzAMRRecorder();
      await a.initWithUrl(url);
      a.onAutoEnded(function () {
        setIsPlaying(false);
        setCurrentImageIndex(0);
        setCurrentAmr(null);
        if (intervalIdRef.current) {
          clearInterval(intervalIdRef.current);
          intervalIdRef.current = null;
        }
      })
      onAmr(a);
    } catch (error) {
      console.error('init error:', error);
    }
  }, [onAmr]);

  const load = useCallback(async () => {
    await initAmrWithUrl(amrFile);
  }, [initAmrWithUrl]);

  const togglePlay = useCallback(async () => {
    if (!isLoaded) {
      await load();
    }

    if (isPlaying) {
      amr?.stop();
      setIsPlaying(false);
      setCurrentAmr(null);
      if (intervalIdRef.current) {
        clearInterval(intervalIdRef.current);
        intervalIdRef.current = null;
      }
    } else {
      amr?.play();
      setIsPlaying(true);
      if (currentAmr && currentAmr !== amr) {
        currentAmr.stop();
      }
      setCurrentAmr(amr);
      if (intervalIdRef.current) {
        clearInterval(intervalIdRef.current);
      }
      intervalIdRef.current = setInterval(() => {
        setCurrentImageIndex(prevIndex => (prevIndex + 1) % images.length);
      }, 300);
    }
  }, [isPlaying, isLoaded, amr, currentAmr])

  useEffect(() => {
    load();
    return () => {
      if (intervalIdRef.current) {
        clearInterval(intervalIdRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (currentAmr && currentAmr !== amr) {
      amr?.stop();
      setIsPlaying(false);
      if (intervalIdRef.current) {
        clearInterval(intervalIdRef.current);
      }
    }
  }, [currentAmr, amr]);

  const calculateWidth = (duration: number) => {
    const minWidth = 50;
    const maxWidth = 336;
    const minDuration = 1;
    const maxDuration = 120;

    // 计算映射的宽度
    const width = ((duration - minDuration) / (maxDuration - minDuration)) * (maxWidth - minWidth) + minWidth;
    // 限制宽度在最小和最大值之间
    return Math.min(Math.max(width, minWidth), maxWidth);
  };

  const boxWidth = calculateWidth(Number(item.duration));

  return (
    <Box
      sx={{
        width: `${boxWidth}px`,
        height: '30px',
        cursor: "pointer",
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexDirection: isOwn ? "row-reverse" : "row",
        color: isOwn ? "rgba(0, 0, 0, 1)" : "#858B93",
        fontSize: '14px',
        fontFamily: "SF Pro Text",
        padding: '0 3px'
      }} onClick={togglePlay}>
      <img src={!isPlaying ? images[2] : images[currentImageIndex]} width={14} height={18} style={{ marginLeft: '5px' }} />
      {/* <span>{isPlaying ? "true" : "false"}</span> */}
      {item.duration}''
    </Box>
  )
}

export default AudioPlayer;