import { useEffect, useMemo, useRef } from 'react';
import { Box } from '@mui/material';
import { CollectDownloadAction } from 'src/hooks/landing/baidu';
import { getAppDownloadUrl, getFormatSearchParam } from './openApp';
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

const WX_APP_OPEN_ID = "wxe65ec5ced00a0650"; // 微信开放平台的app id

const WechatOpenAppBtn = ({ openBtnStyles,text }) => {
    const { t } = useTranslation();
    const wxRef = useRef(null)
    const location = useLocation()
    useEffect(() => {
        // @ts-ignore
        wxRef.current?.addEventListener('launch', function (e: any) {
            console.log('launch success');
        });
        // @ts-ignore
        wxRef.current?.addEventListener('error', function (e) {
            console.error(JSON.stringify(e));
            // enqueueSnackbar('Please check if Anylocal app is installed', { variant: 'warning' });
            CollectDownloadAction(() => {
                window.location.href = getAppDownloadUrl();
            });
        });
        // @ts-ignore
        // wxRef.current?.addEventListener('ready', function (e) {
        //     console.log('ready', e.detail);
        // });
    }, [])

    const getFormatHref = useMemo(() => {
        // 和非微信浏览器打开app时携带的参数格式保持一致
        const formatSearchParams = getFormatSearchParam();
        return `${window.location.protocol}//${window.location.host}${window.location.pathname}${formatSearchParams}`;
    }, [location])

    return (
        <Box sx={{ position: 'relative', width: openBtnStyles.width, height: openBtnStyles.height, }}>
            <Box
                sx={{
                    width: "100%",
                    height: "100%",
                    background: "#EAF4FF",
                }}
            />
            {/*  @ts-ignore */}
            <wx-open-launch-app
                style={{ width: '100%', height: '100%', display: 'block', position: 'absolute', top: 0 }}
                ref={wxRef}
                appid={WX_APP_OPEN_ID}
                // 支持打开app后跳转到指定的页面
                // https://developers.weixin.qq.com/doc/offiaccount/OA_Web_Apps/Wechat_Open_Tag.html#%E8%B7%B3%E8%BD%ACAPP%EF%BC%9Awx-open-launch-app
                extinfo={getFormatHref}
            >
                <script type="text/wxtag-template">
                    <button style={openBtnStyles}>{text}</button>
                </script>
                {/*  @ts-ignore */}
            </wx-open-launch-app>
        </Box>
    )
}
export default WechatOpenAppBtn