import { FC, useState, useMemo } from "react";
import { Locale, defaultLanguages } from '../../models/locale';
import { useTranslation } from 'react-i18next';
import { Box, IconButton, Menu, Tooltip, useMediaQuery, useTheme } from "@mui/material";
import { SvgComponent } from "src/components/svgComponent";
import { useDispatch } from "react-redux";
import { setLanguage } from "../../service/redux/app-slice";

import IcoMobileLanguage from "src/images/header/ico-language.svg"

import { makeStyles } from 'tss-react/mui';
import { onTableOrMobile } from 'src/tools/utils/appUtil';

const IsOnTableOrMobile = onTableOrMobile();

const useStyles = makeStyles()((theme) => {
    return {
        root: {
            position: 'relative',
        },
        languageButton: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer',
        },
        content: {
            position: 'absolute',
            top: '26px',
            right: '10px',
            borderRadius: '8px',
            background: '#fff',
            boxShadow: '0px 4px 20px 0px rgba(0, 0, 0, 0.10)',
            transition: 'all 0.4s',
            zIndex: 100
        },
        item: {
            width: '100%',
            height: '35px',
            margin: '5px 0',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer',
            "&:hover": {
                color: '#3A92F9',
            }
        }

    };
});

const SwitchLanguage: FC = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const { classes } = useStyles();
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);


    const handleClose = () => {
        setAnchorEl(null);
    };

    const isMobileOrTable = useMemo(() => {
        return isMobile || IsOnTableOrMobile;
    }, [isMobile])

    return (
        <Box className={classes.root}>
            <Tooltip title={t("app.header.language")}>
                <IconButton size="small" onClick={(event) => setAnchorEl(event.currentTarget)} sx={{ width: isMobileOrTable ? "24px" : "40px", height: isMobileOrTable ? "24px" : "40px", marginBottom: isMobileOrTable ? '7px' : 'unset' }}>
                    {
                        isMobileOrTable ?
                            <Box component={'img'} src={IcoMobileLanguage} /> :
                            <SvgComponent iconName='ico-language' style={{ width: '18px', height: '18px', cursor: 'pointer' }} />
                    }

                </IconButton>
            </Tooltip>
            {
                anchorEl &&
                <Menu
                    anchorEl={anchorEl}
                    id="language-menu"
                    open={open}
                    onClose={handleClose}
                    PaperProps={{
                        elevation: 0,
                        sx: {
                            minWidth: 148,
                            overflow: 'visible',
                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                            // mt: 2,
                            // ml: 15,
                            // '&:before': {
                            //     content: '""',
                            //     display: 'block',
                            //     position: 'absolute',
                            //     top: 0,
                            //     right: 100,
                            //     width: 10,
                            //     height: 10,
                            //     bgcolor: 'background.paper',
                            //     transform: 'translateY(-50%) rotate(45deg)',
                            //     zIndex: 0,
                            // },
                        },
                    }}
                    MenuListProps={{
                        sx: {
                            padding: 0,
                        }
                    }}
                    transformOrigin={{ horizontal: 'center', vertical: 'top' }}
                    anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
                >

                    {defaultLanguages.map((language: Locale) => (
                        <Box
                            key={language.lang}
                            className={classes.item}
                            onClick={() => {
                                i18n.changeLanguage(language.lang);
                                dispatch(setLanguage(language.lang));
                                localStorage.setItem('language', language.lang);
                                handleClose();
                            }}
                            style={{
                                background: i18n.language === language.lang ? '#faf6f6' : '#fff',
                            }}
                        >
                            {open && language.label} {language.icon}
                        </Box>
                    ))}
                </Menu>
            }

        </Box>
    )

}

export default SwitchLanguage;