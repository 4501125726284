export const SearchHistoryKey = "app-search-history";

export interface SearchHistoryObjectProps {
  searchKey: string,
  searchTime: number,
}

export const saveSearchHistory = (searchValue) => {
  const existingHistory = getSearchHistory();
  const searchItemIndex = existingHistory.findIndex(item => item.searchKey === searchValue);

  if (searchItemIndex > -1) {
    // 更新已有的搜索项的时间
    existingHistory[searchItemIndex].searchTime = Date.now();
  } else {
    // 添加新的搜索项
    const searchItem: SearchHistoryObjectProps = {
      searchKey: searchValue,
      searchTime: Date.now()
    };
    existingHistory.unshift(searchItem);
  }

  localStorage.setItem(SearchHistoryKey, JSON.stringify(existingHistory))
} 

export const getSearchHistory = () => {
  const history = localStorage.getItem(SearchHistoryKey);
  return history ? JSON.parse(history) : [];
}

export const clearHistory = () => {
  localStorage.removeItem(SearchHistoryKey);
}