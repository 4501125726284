import { createApi } from "@reduxjs/toolkit/query/react";
import { Response, Result, Value } from "../../models/common";
import { baseQueryWithReauth } from "../../tools/toolkit/helper";
import { _loadState } from "../redux/app-slice";
import { PictureEntity, MessageEntity, RelevantMessageEntity } from "src/models/chatroom";
import { MessageListProps } from "src/models/admin-chatroom";

export const chatroomApi = createApi({
  reducerPath: "chatroom",
  baseQuery: baseQueryWithReauth,
  endpoints(builder) {
    return {
      getRoomByNation: builder.mutation<Result<any>, {nation?: string, startKey: string, pageSize: number, gps: string, type: string }>({
        query: ({
          nation,
          startKey,
          pageSize,
          gps,
          type
        }) => ({
          url: `loc_chat/get_room_by_nation`,
          method: "POST",
          body: {
            nation,
            page_size: pageSize || 20,
            start_key: startKey,
            gps,
            type
          },
        }),
        transformResponse: (res: Response<any>) => new Value(res),
      }),
      getHistoryMessageByRoomId: builder.mutation<Result<MessageListProps>, { room_id: string, startKey: string, pageSize: number, forward:boolean }>({
        query: ({
          room_id,
          startKey,
          pageSize,
          forward
        }) => ({
          url: `loc_chat/room_message`,
          method: "POST",
          body: {
            room_id,
            page_size: pageSize || 20,
            start_key: startKey,
            forward
          },
        }),
        transformResponse: (res: Response<MessageListProps>) => new Value(res),
      }),
      getUsersByRoomId: builder.mutation<Result<any>, { room_id: string, startKey: string, pageSize: number }>({
        query: ({
          room_id,
          startKey,
          pageSize
        }) => ({
          url: `loc_chat/room_user`,
          method: "POST",
          body: {
            room_id,
            page_size: pageSize || 20,
            start_key: startKey,
          },
        }),
        transformResponse: (res: Response<any>) => new Value(res),
      }),
      searchRoomByText: builder.mutation<Result<any>, { search_text: string, startKey?: string, pageSize?: number }>({
        query: ({
          search_text,
          startKey,
          pageSize
        }) => ({
          url: `loc_chat/search_room`,
          method: "POST",
          body: {
            search_text,
            page_size: pageSize || 20,
            start_key: startKey,
          },
        }),
        transformResponse: (res: Response<any>) => new Value(res),
      }),
      deleteMessage: builder.mutation<Result<any>, { id: string }>({
        query: ({
          id,
        }) => ({
          url: `loc_chat/delete_message`,
          method: "POST",
          body: {
            message_id: id,
          },
        }),
        transformResponse: (res: Response<any>) => new Value(res),
      }),
      getRoomByLocId: builder.mutation<Result<any>, { gps: string }>({
        query: ({
          gps,
        }) => ({
          url: `loc_chat/get_room_by_loc`,
          method: "POST",
          body: {
            gps
          },
        }),
        transformResponse: (res: Response<any>) => new Value(res),
      }),
      followRoom: builder.mutation<Result<any>, { id: string, follow: boolean }>({
        query: ({
          id,
          follow
        }) => ({
          url: `loc_chat/followed_room`,
          method: "POST",
          body: {
            room_id: id,
            follow
          },
        }),
        transformResponse: (res: Response<any>) => new Value(res),
      }),
      setMessageRead: builder.mutation<Result<any>, { id: string }>({
        query: ({
          id
        }) => ({
          url: `loc_chat/read_message`,
          method: "POST",
          body: {
            message_id: id,
          },
        }),
        transformResponse: (res: Response<any>) => new Value(res),
      }),
      setDialogMessageRead: builder.mutation<Result<any>, { id: string }>({
        query: ({
          id
        }) => ({
          url: `loc_chat/read_message`,
          method: "POST",
          body: {
            dialog_id: id
          },
        }),
        transformResponse: (res: Response<any>) => new Value(res),
      }),
      getFollowedRoom: builder.mutation<Result<any>, { gps: string }>({
        query: ({
          gps,
        }) => ({
          url: `loc_chat/get_followed_room`,
          method: "POST",
          body: {
            gps
          },
        }),
        transformResponse: (res: Response<any>) => new Value(res),
      }),
      updateRoomCover: builder.mutation<
        Result<undefined>,
        {
          room_id: string;
          cover: string;
        }
      >({
        query: ({ room_id, cover }) => ({
          url: `loc_chat/update_room_cover`,
          method: "POST",
          body: {
            room_id,
            cover,
          },
        }),
        transformResponse: (res: Response<undefined>) => new Value(res),
      }),
      getPostPicture: builder.mutation<
        Result<PictureEntity>,
        {
          room_id: string
        }
      >({
        query: ({ room_id }) => ({
          url: `loc_chat/list_room_cover`,
          method: "POST",
          body: {
            room_id
          },
        }),
        transformResponse: (res: Response<PictureEntity>) => new Value(res),
      }),
      getPostListByRoomMessageId: builder.mutation<Result<any>, { id: string }>({
        query: ({
          id
        }) => ({
          url: `loc_chat/list_post_by_room_message`,
          method: "POST",
          body: {
            room_message_id: id,
          },
        }),
        transformResponse: (res: Response<any>) => new Value(res),
      }),
      getContributorsByRoomId: builder.mutation<Result<any>, { id: string, startKey?: string, pageSize?: number }>({
        query: ({
          id,
          startKey,
          pageSize
        }) => ({
          url: `loc_chat/room_submitter`,
          method: "POST",
          body: {
            room_id: id,
            page_size: pageSize || 20,
            start_key: startKey,
          },
        }),
        transformResponse: (res: Response<any>) => new Value(res),
      }),
      searchMessage: builder.mutation<
        Result<MessageEntity>,
        {
          search_text: string, 
          startKey?: string, 
          pageSize?: number,
          room_id: string,
          type: string
        }
      >({
        query: ({
          room_id,
          search_text,
          startKey,
          pageSize,
          type
         }) => ({
          url: `loc_chat/room_message`,
          method: "POST",
          body: {
            room_id,
            search_text,
            page_size: pageSize || 20,
            start_key: startKey,
            type
          },
        }),
        transformResponse: (res: Response<MessageEntity>) => new Value(res),
      }),
      getRelevantMessage: builder.mutation<
        Result<RelevantMessageEntity>,
        {
          room_id: string,
          message_id: string,
          page_size: number,
          type: string,
        }
      >({
        query: ({
          room_id,
          message_id,
          page_size,
          type,
        }) => ({
          url: `loc_chat/room_message`,
          method: "POST",
          body: {
            room_id,
            message_id,
            page_size,
            type
          },
        }),
        transformResponse: (res: Response<RelevantMessageEntity>) => new Value(res),
      }),
      uploadVisitorInfo: builder.mutation<
        Result<any>,
        {
          user_id: string,
          user_name: string,
          clientDeviceId: string
        }
      >({
        query: ({
          user_id,
          user_name,
          clientDeviceId
        }) => ({
          url: `loc_chat/update_visitor_info`,
          method: "POST",
          headers:{
            "Client-Device-Id": clientDeviceId
          },
          body: {
            user_id,
            user_name
          },
        }),
        transformResponse: (res: Response<any>) => new Value(res),
      }),
    };
  },
});

export const {
  useGetRoomByNationMutation,
  useGetHistoryMessageByRoomIdMutation,
  useGetUsersByRoomIdMutation,
  useSearchRoomByTextMutation,
  useDeleteMessageMutation,
  useGetRoomByLocIdMutation,
  useFollowRoomMutation,
  useSetMessageReadMutation,
  useSetDialogMessageReadMutation,
  useGetFollowedRoomMutation,
  useUpdateRoomCoverMutation,
  useGetPostPictureMutation,
  useGetPostListByRoomMessageIdMutation,
  useGetContributorsByRoomIdMutation,
  useSearchMessageMutation,
  useGetRelevantMessageMutation,
  useUploadVisitorInfoMutation,
} = chatroomApi;
