import { Button } from '@mui/material';
import * as React from 'react'
import AppleSignin from 'react-apple-signin-auth'
import { useTranslation } from 'react-i18next';
import SvgComponent from 'src/components/svgComponent';

interface PropsForm {
    SuccessResponseApple: (response: any) => void,
    ErrorResponseApple: (response: any) => void,
}

const AppleLoginButton: React.FC<PropsForm> = (props) => {
    const { t } = useTranslation();
    const { SuccessResponseApple, ErrorResponseApple } = props;
    const [base_url, setBaseUrl] = React.useState("");

    React.useEffect(() => {
        setBaseUrl((window.location.host.indexOf("localhost") >= 0 || window.location.host.indexOf("127.0.0") >= 0) ? "www-test.dev.anylocal.com" : window.location.host);
    }, []);

    return (
        <AppleSignin
            authOptions={{
                clientId: "com.sktlab.anyLocal.service",
                redirectURI: `https://${base_url}/home`,
                state: 'state',
                //nonce: 'nonce',
                usePopup: true,
                scope: 'email name',
            }}
            uiType="dark"
            className="apple-auth-btn"
            noDefaultStyle={false}
            buttonExtraChildren="Continue with Apple"
            onSuccess={(response) => SuccessResponseApple(response)}
            onError={(error) => ErrorResponseApple(error)}
            skipScript={false}
            render={(props) =>
                <Button size="large" variant="text" {...props}
                    sx={{
                        textTransform: 'none', color: '#000000', fontFamily: 'Helvetica Neue', fontStyle: 'normal', fontWeight: 400, fontSize: '14px', textAlign: "center",
                        height: '44px', marginBottom: '20px', whiteSpace: 'nowrap', width: '100%',
                        background: '#F9F9F9',
                        border: '1px solid #E6E6E6', borderRadius: '22px', boxShadow: 'none',
                        mixBlendMode: 'normal',
                        position: "relative",
                    }}>
                    <SvgComponent iconName="ico-apple" style={{ width: '17px', height: '20px', position: "absolute", left: "20px", }} />
                    {t("app.login.apple-login")}
                </Button>
            }
        />
    )
}
export default AppleLoginButton;