import { useMemo } from 'react';
import { Box } from '@mui/material';
import { getFormatSearchParam } from './openApp';
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import OpenAppBtn from './OpenAppBtn';
import { WXOpenLaunchAppCover } from "src/components/wxOpenLaunchAppCover";

const WechatOpenAppBtnNew = () => {
    const { t } = useTranslation();
    const location = useLocation()

    const getFormatHref = useMemo(() => {
        // 和非微信浏览器打开app时携带的参数格式保持一致
        const formatSearchParams = getFormatSearchParam();
        return `${window.location.protocol}//${window.location.host}${window.location.pathname}${formatSearchParams}`;
    }, [location])

    return (
        <Box sx={{ position: 'relative' }}>
            <OpenAppBtn />
            <WXOpenLaunchAppCover redirectURL={getFormatHref} />
        </Box >
    )
}
export default WechatOpenAppBtnNew;