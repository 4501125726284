import { createApi } from "@reduxjs/toolkit/query/react";
import { Response, Result, Value } from "../../models/common";
import { baseQueryWithReauth } from "../../tools/toolkit/helper";
import { Transform, reportResponse,reportListResponse} from 'src/models/pingxx';

interface TransformResponse {
  itemList: Transform[];
  startKey: string;
}

export const pingxxApi = createApi({
  reducerPath: "pingxx",
  baseQuery: baseQueryWithReauth,
  endpoints(builder) {
    return {
      createUser: builder.mutation<
        Result<any>,
        {
          id: string
        }
      >({
        query: ({
          id
        }) => ({
          url: `pingxx`,
          method: "POST",
          body: {
            type: "request",
            input: {
              requestTime: Date.now(),
              function: "create_user",
              body: {
                id
              }
            }
          }
        }),
        transformResponse: (res: Response<any>) => new Value(res),
      }),
      queryUser: builder.mutation<
        Result<any>,
        {
          id: string
        }
      >({
        query: ({
          id
        }) => ({
          url: `pingxx`,
          method: "POST",
          body: {
            type: "request",
            input: {
              requestTime: Date.now(),
              function: "user_check",
              url_id: id
            }
          }
        }),
        transformResponse: (res: Response<any>) => new Value(res),
      }),
      createAccount: builder.mutation<
        Result<any>,
        {
          certName: string,
          certNumber: string,
          mobile: string
        }
      >({
        query: ({
          certName,
          certNumber,
          mobile
        }) => ({
          url: `pingxx`,
          method: "POST",
          body: {
            type: "open_account",
            input: {
              cert_name: certName,
              cert_number: certNumber,
              mobile
            }
          }
        }),
        transformResponse: (res: Response<any>) => new Value(res),
      }),
      queryAccount: builder.mutation<
        Result<any>, {}>({
          query: () => ({
            url: `pingxx`,
            method: "POST",
            body: {
              type: "open_account_check"
            }
          }),
          transformResponse: (res: Response<any>) => new Value(res),
        }),
      requestWithdraw: builder.mutation<
        Result<any>,
        {
          amount: number,
          channel?: string,
        }
      >({
        query: ({
          amount,
          channel
        }) => ({
          url: `pingxx`,
          method: "POST",
          body: {
            type: "withdraw_request",
            input: {
              amount,
              channel
            }
          }
        }),
        transformResponse: (res: Response<any>) => new Value(res),
      }),
      queryWithdraw: builder.mutation<
        Result<TransformResponse>, {
          status: string,
          queryType: string,
          forward: boolean,
          pageSize: number,
          startKey: string,
        }>({
          query: ({ status, queryType, forward, pageSize, startKey }) => ({
            url: `pingxx`,
            method: "POST",
            body: {
              type: "withdraw_list",
              input: {
                status,
                query_type: queryType,
                forward,
                page_size: pageSize,
                start_key: startKey
              }
            }
          }),
          transformResponse: (res: Response<TransformResponse>) => new Value(res),
        }),
      approveWithdraw: builder.mutation<
        Result<any>,
        {
          id: string,
        }
      >({
        query: ({
          id
        }) => ({
          url: `pingxx`,
          method: "POST",
          body: {
            type: "withdraw_approve",
            input: {
              apply_id: id
            }
          }
        }),
        transformResponse: (res: Response<any>) => new Value(res),
      }),
      rejectWithdraw: builder.mutation<
        Result<any>,
        {
          id: string,
          reason: string,
        }
      >({
        query: ({
          id,
          reason
        }) => ({
          url: `pingxx`,
          method: "POST",
          body: {
            type: "withdraw_approve",
            input: {
              apply_id: id,
              reject: true,
              reason
            }
          }
        }),
        transformResponse: (res: Response<any>) => new Value(res),
      }),
      withdraw: builder.mutation<
        Result<any>,
        {
          amount: number,
          returnUrl: string,
        }
      >({
        query: ({ amount, returnUrl }) => ({
          url: `pingxx`,
          method: "POST",
          body: {
            type: "pingxx_withdraw",
            input: {
              amount,
              return_url: returnUrl
            },
          }
        }),
        transformResponse: (res: Response<any>) => new Value(res),
      }),
      confirmWithdraw: builder.mutation<
        Result<any>,
        { id: string }
      >({
        query: ({ id }) => ({
          url: `pingxx`,
          method: "POST",
          body: {
            type: "request",
            input: {
              requestTime: Date.now(),
              function: "withdrawals_put",
              url_id: id,
              body: {
                status: "pending"
              }
            },
          }
        }),
        transformResponse: (res: Response<any>) => new Value(res),
      }),
      getWithdraw: builder.mutation<
        Result<any>,
        { id: string }
      >({
        query: ({ id }) => ({
          url: `pingxx`,
          method: "POST",
          body: {
            type: "request",
            input: {
              requestTime: Date.now(),
              function: "balance_transfers_check",
              url_id: id,
            },
          }
        }),
        transformResponse: (res: Response<any>) => new Value(res),
      }),
      settleAccounts: builder.mutation<
        Result<any>,
        { id: string }
      >({
        query: ({ id }) => ({
          url: `pingxx`,
          method: "POST",
          body: {
            type: "request",
            input: {
              requestTime: Date.now(),
              function: "settle_accounts",
              url_id: id,
              body: {
                return_url: "http://localhost:4000/wallet"
              }
            },
          }
        }),
        transformResponse: (res: Response<any>) => new Value(res),
      }),
      exchange: builder.query<
        Result<any>,
        { coin: number }
      >({
        query: ({ coin }) => ({
          url: `pingxx`,
          method: "POST",
          body: {
            type: "exchange",
            input: {
              coin
            },
          }
        }),
        transformResponse: (res: Response<any>) => new Value(res),
      }),
      getReport: builder.mutation<
        Result<reportResponse>,
        {
          time: string;
        }
      >({
        query: ({ time }) => ({
          url: "pingxx",
          method: "POST",
          body: {
            type: "total",
            input: {
              time,
            },
          },
        }),
        transformResponse: (res: Response<reportResponse>) => new Value(res),
      }),
      getReportApply: builder.mutation<
        Result<reportResponse>,
        {
          apply: string;
        }
      >({
        query: ({ apply }) => ({
          url: "pingxx",
          method: "POST",
          body: {
            type: "total",
            input: {
              apply,
            },
          },
        }),
        transformResponse: (res: Response<reportResponse>) => new Value(res),
      }),
      getReportList: builder.mutation<
        Result<reportListResponse>,
        {
          date: string;
          forward: boolean;
          pageSize: number,
          startKey: string,
        }
      >({
        query: ({ date, forward, pageSize, startKey }) => ({
          url: "pingxx",
          method: "POST",
          body: {
            type: "apply_report",
            input: {
              forward,
              date,
              page_size: pageSize,
              start_key: startKey
            },
          },
        }),
        transformResponse: (res: Response<reportListResponse>) => new Value(res),
      }),
    }
  }
});

export const {
  useCreateAccountMutation,
  useQueryAccountMutation,
  useCreateUserMutation,
  useQueryUserMutation,
  useRequestWithdrawMutation,
  useQueryWithdrawMutation,
  useApproveWithdrawMutation,
  useWithdrawMutation,
  useGetWithdrawMutation,
  useConfirmWithdrawMutation,
  useSettleAccountsMutation,
  useLazyExchangeQuery,
  useRejectWithdrawMutation,
  useGetReportMutation,
  useGetReportApplyMutation,
  useGetReportListMutation,
} = pingxxApi;