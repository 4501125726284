import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { DateTime } from "luxon";
import i18n from 'src/app/i18n';

// const isEN = !!localStorage.getItem("language")


dayjs.extend(utc);
dayjs.extend(timezone);
// 将秒数格式化为时:分:秒
export const formatTime = (seconds) => {
  const format = (val) => `0${Math.floor(val)}`.slice(-2);
  const minutes = (seconds / 60) % 60;
  const hours = seconds / 3600;
  if (hours >= 1) {
    return `${format(hours)}:${format(minutes)}:${format(seconds % 60)}`;
  } else {
    return `${format(minutes)}:${format(seconds % 60)}`;
  }
};

/**
 * 获取当前时区
 * @returns
 */
export const getTimeZone = () => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
};

/**
 * 获取当前月份
 */
export const getCurrentMonth = () => {
  const date = new Date();
  return date.getMonth() + 1;
};
/**
 * 获取当前年份,美式
 */
export const getCurrentYear = () => {
  const date = new Date();
  return date.getFullYear();
};

/**
 * get format time string of yesterday, used for Material UI TimePicker value
 * @param timeStamp
 * @returns format: 2021-07-05T00:00
 */
export const getYesterdayFormatTime = () => {
  const date = new Date();
  date.setDate(date.getDate() - 1);
  date.setHours(0);
  date.setMinutes(0);
  date.setSeconds(0);
  return getFormatPickerTime(date.getTime());
};

/**
 * get format time string of tomorrow, used for Material UI TimePicker value
 * @param timeStamp
 * @returns format: 2021-07-05T00:00
 */
export const getTomorrowFormatTime = () => {
  const date = new Date();
  date.setDate(date.getDate() + 1);
  date.setHours(0);
  date.setMinutes(0);
  date.setSeconds(0);
  return getFormatPickerTime(date.getTime());
};

/**
 * get format time string of 7 days later, used for Material UI TimePicker value
 * @param timeStamp
 * @returns format: 2021-07-05T00:00
 */
export const getFormatTime7DaysLater = () => {
  const date = new Date();
  date.setDate(date.getDate() + 7);
  date.setHours(0);
  date.setMinutes(0);
  date.setSeconds(0);
  return getFormatPickerTime(date.getTime());
};

/**
 * get format time string of the first day of this month, used for Material UI TimePicker value
 * @param timeStamp
 * @returns format: 2021-07-05T00:00
 */
export const getMonthFirstDayFormatTime = () => {
  const date = new Date();
  date.setDate(1);
  date.setHours(0);
  date.setMinutes(0);
  date.setSeconds(0);
  return getFormatPickerTime(date.getTime());
};

export const getDayZeroClockFormatTime = (
  targetDate: Date,
  needMillisecond: boolean = false
) => {
  const date = new Date();
  date.setFullYear(targetDate.getFullYear());
  date.setDate(targetDate.getDate());
  date.setMonth(targetDate.getMonth());
  date.setHours(0);
  date.setMinutes(0);
  date.setSeconds(0);
  date.setMilliseconds(0);
  if (needMillisecond) {
    return date.getTime();
  } else {
    return date.getTime() / 1000;
  }
};

export const getMonthFirstDayFormatTimestamp = (
  targetDate: Date,
  needMillisecond: boolean = false
): number => {
  const date = new Date();
  date.setDate(1);
  date.setHours(0);
  date.setMinutes(0);
  date.setSeconds(0);
  date.setMilliseconds(0);
  date.setMonth(targetDate.getMonth());
  date.setFullYear(targetDate.getFullYear());
  if (needMillisecond) {
    return date.getTime();
  } else {
    return date.getTime() / 1000;
  }
};

export const getNextMonthFirstDayFormatTimestamp = (
  targetDate: Date,
  needMillisecond: boolean = false
): number => {
  const lastDate = getLastDayOfMonth(
    targetDate.getFullYear(),
    targetDate.getMonth() + 1
  );
  const timeStamp = lastDate.getTime() + 86400000;
  if (needMillisecond) {
    return timeStamp;
  } else {
    return timeStamp / 1000;
  }
};

/**
 * get current format time string used for Material UI TimePicker value
 * @param timeStamp
 * @returns format: 2021-07-05T09:00
 */
export const getCurrentFormatPickerTime = () => {
  return getFormatPickerTime(new Date().getTime());
};

/**
 * get format time string used for Material UI TimePicker value
 * @param timeStamp
 * @returns format: 2021-07-05T09:00
 */
export const getFormatPickerTime = (timeStamp) => {
  const time = new Date(timeStamp);
  const y = time.getFullYear();
  const m = time.getMonth() + 1;
  const d = time.getDate();
  const h = time.getHours();
  const mm = time.getMinutes();
  //const s = time.getSeconds();
  return y + "-" + add0(m) + "-" + add0(d) + "T" + add0(h) + ":" + add0(mm);
};

export const getFormatDisplayTime = (timeStamp) => {
  const time = new Date(timeStamp);
  const y = time.getFullYear();
  const m = time.getMonth() + 1;
  const d = time.getDate();
  const h = time.getHours();
  const mm = time.getMinutes();
  const s = time.getSeconds();
  return (
    y +
    "-" +
    add0(m) +
    "-" +
    add0(d) +
    " " +
    add0(h) +
    ":" +
    add0(mm) +
    ":" +
    add0(s)
  );
};

export const getFormatDisplayTimeTillMinute = (timeStamp) => {
  const time = new Date(timeStamp);
  const y = time.getFullYear();
  const m = time.getMonth() + 1;
  const d = time.getDate();
  const h = time.getHours();
  const mm = time.getMinutes();
  return y + "-" + add0(m) + "-" + add0(d) + " " + add0(h) + ":" + add0(mm);
};

/**
 * get timeStamp in million seconds
 * @param timeStr format: 2021-07-08T15:54
 * @returns
 */
export const getTimeStampMillionSeconds = (timeStr: string) => {
  let dateTime = timeStr.replace(/-/g, "/").replace("T", " ");
  let timestamp = new Date(dateTime).getTime();
  return timestamp;
};

/**
 * get timeStamp string in seconds
 * @param timeStr format: 2021-07-08T15:54
 * @returns string
 */
export const getTimeStampSeconds = (timeStr: string) => {
  let dateTime = timeStr.substring(0, 19);
  dateTime = dateTime.replace(/-/g, "/"); //必须把日期'-'转为'/'
  dateTime = dateTime.replace("T", " "); //把'T'转为' '
  let timestamp = new Date(dateTime).getTime();
  return (timestamp / 1000).toString();
};

export const getCurrentFormatDate = () => {
  return getFormatDate(new Date().getTime());
};

export const getCurrentFormatMonth = () => {
  return getFormatMonth(new Date().getTime());
};

export const getCurrentFormatWeek = () => {
  return getFormatWeek(new Date().getTime());
};

const add0 = (m) => {
  return m < 10 ? "0" + m : m;
};

/**
 * get format date string
 * @param dateStr 2021-7-5
 * @returns  2021-07-05
 */
export const formatDateStr = (dateStr: string) => {
  if (!dateStr) {
    return "";
  }
  const nums = dateStr.split("-");
  const year = parseInt(nums[0]);
  const month = parseInt(nums[1]);
  const day = parseInt(nums[2]);
  return year + "-" + add0(month) + "-" + add0(day);
};

export const getFormatDateHour = (timeStamp: number) => {
  const time = new Date(timeStamp);
  const y = time.getFullYear();
  const m = time.getMonth() + 1;
  const d = time.getDate();
  const h = time.getHours();
  return y + "-" + add0(m) + "-" + add0(d) + "-" + add0(h);
};

export const getFormatDate = (timeStamp: number) => {
  const time = new Date(timeStamp);
  const y = time.getFullYear();
  const m = time.getMonth() + 1;
  const d = time.getDate();
  return y + "-" + add0(m) + "-" + add0(d);
};

export const getFormatMonth = (timeStamp: number) => {
  const time = new Date(timeStamp);
  const y = time.getFullYear();
  const m = time.getMonth() + 1;
  return y + "-" + add0(m);
};

export const getFormatWeek = (timeStamp: number) => {
  const time = new Date(timeStamp);
  const y = time.getFullYear();
  const week = getWeekOfYear(timeStamp);
  return y + "-W" + add0(week);
};

export const getWeekOfYear = (timeStamp: number) => {
  const date = new Date();
  const y = new Date(timeStamp).getFullYear();
  date.setFullYear(y);
  date.setMonth(0);
  date.setDate(1);
  const dateGap =
    timeStamp - date.getTime() > 0 ? timeStamp - date.getTime() : 0;
  const week = Math.ceil(dateGap / (7 * 24 * 60 * 60 * 1000));
  return week;
};

export const getMonday = (date: Date) => {
  const week = date.getDay(); //获取时间的星期数
  const minus = week ? week - 1 : 6;
  date.setDate(date.getDate() - minus); //获取minus天前的日期
  const y = date.getFullYear();
  const m = date.getMonth() + 1; //获取月份
  const d = date.getDate();
  return y + "-" + m + "-" + d;
};

/**
 * get Date
 * @param dateStr 2021-07-05
 * @returns
 */
export const getDateObj = (dateStr: string) => {
  let dateTime = dateStr.replace(/-/g, "/");
  return new Date(dateTime);
};

export const getLastDayOfMonth = (year, month) => {
  let date = new Date(year, month - 1, 1);
  date.setDate(1);
  date.setMonth(date.getMonth() + 1);
  const cdate = new Date(date.getTime() - 1000 * 60 * 60 * 24);
  return cdate;
};

export const getLastDayOfMonthTimestamp = (
  needMillisecond: boolean
): number => {
  const date = new Date();
  const lastDate = getLastDayOfMonth(date.getFullYear(), date.getMonth());
  lastDate.setHours(0);
  lastDate.setMinutes(0);
  lastDate.setSeconds(0);
  lastDate.setMilliseconds(0);
  if (needMillisecond) {
    return lastDate.getTime();
  } else {
    return lastDate.getTime() / 1000;
  }
};

/**
 * get timeStamp in million seconds
 * @param timeStr format: 2021-07-08
 * @returns
 */
export const getTimeStampByDate = (dateStr: string) => {
  const date = getDateObj(dateStr);
  return date.getTime();
};

/**
 * get format time description
 * @param timestamp
 * @returns time description like "8:18 AM, January 20, 2022"
 */
export const getDateTimeInWordDescription = (timestamp: number) => {
  const date = new Date(timestamp);
  return `${date.getHours() > 12 ? date.getHours() - 12 : date.getHours()}:${date.getMinutes() >= 10 ? date.getMinutes() : `0${date.getMinutes()}`
    } ${date.getHours() >= 12 ? "PM" : "AM"}, ${getMonthInWord(
      date.getMonth()
    )} ${date.getDate()}, ${date.getFullYear()}`;
};

/**
 * get format time description
 * @param timestamp
 * @returns time description like "Aug 4, 2023 12:28:32 PM"
 */
export const getShortDateTimeInWordDescription = (timestamp: number) => {
  const date = new Date(timestamp);
  return `${getMonthInWord(
    date.getMonth(),
    true
  )} ${date.getDate()}, ${date.getFullYear()} ${date.getHours() > 12 ? date.getHours() - 12 : date.getHours()
    }:${date.getMinutes() >= 10 ? date.getMinutes() : `0${date.getMinutes()}`}:${date.getSeconds() >= 10 ? date.getSeconds() : `0${date.getSeconds()}`
    } ${date.getHours() >= 12 ? "PM" : "AM"}`;
};

/**
 * get format time description
 * @param timestamp
 * @returns time description like "Aug 4, 2023 12:28 PM"
 */
export const getShortDateTimeInWordDescription2 = (timestamp: number) => {
  const date = new Date(timestamp);
  return `${getMonthInWord(
    date.getMonth(),
    true
  )} ${date.getDate()}, ${date.getFullYear()} ${date.getHours() > 12 ? date.getHours() - 12 : date.getHours()
    }:${date.getMinutes() >= 10 ? date.getMinutes() : `0${date.getMinutes()}`} ${date.getHours() >= 12 ? "PM" : "AM"
    }`;
};

/**
 * get format time description
 * @param timestamp
 * @returns time description like "January 20, 8:18 AM"
 */
export const getTimeInWordDescription = (timestamp: number) => {
  const date = new Date(timestamp);
  return `${getMonthInWord(date.getMonth())} ${date.getDate()}, ${date.getHours() > 12 ? date.getHours() - 12 : date.getHours()
    }:${date.getMinutes() >= 10 ? date.getMinutes() : `0${date.getMinutes()}`} ${date.getHours() >= 12 ? "PM" : "AM"
    }`;
};

/**
 * get format time description
 * @param timestamp
 * @returns time object like [day: '10', month: 'Oct', time: '12:00am']
 */
export const getTimeArr = (timestamp: number) => {
  const date = new Date(timestamp);
  return {
    day: date.getDate(),
    month: getMonthInWord(date.getMonth(), true),
    time: `${date.getHours() > 12 ? date.getHours() - 12 : date.getHours()}:${date.getMinutes() >= 10 ? date.getMinutes() : `0${date.getMinutes()}`
      } ${date.getHours() >= 12 ? "pm" : "am"}`,
  };
};

/**
 * get format time description
 * @param timestamp
 * @returns time description like "8:00pm, Feb 27, 2021"
 */
export const getTimeInWordDescription2 = (timestamp: number) => {
  // const date = new Date(timestamp);
  // return `${date.getHours() > 12 ? date.getHours() - 12 : date.getHours()}:${
  //   date.getMinutes() >= 10 ? date.getMinutes() : `0${date.getMinutes()}`
  // } ${date.getHours() >= 12 ? "PM" : "AM"}, ${getMonthInWord(
  //   date.getMonth()
  // )} ${date.getDate()}, ${date.getFullYear()}`;
  const language = localStorage.getItem("language") ?? "en";
  const date = dayjs(timestamp);
  if (language === "zh-CN") {
    // 格式化成中式时间
    return date.format("YYYY年MM月DD日 HH:mm:ss");
  } else if (language === "en") {
    // 格式化成美式时间
    return date.format("h:mm A, MMMM D, YYYY");
  } else {
    // 自动localized格式
    // return date.format();
    return date.format("MM-DD-YYYY");
  }
};

export const getTimezoneInWordDescription = (
  timestamp: number,
  timezone: string
) => {
  const language = localStorage.getItem("language") ?? "en";
  const date = dayjs.tz(timestamp, timezone);
  if (language === "zh-CN") {
    // 格式化成中式时间
    return date.format("YYYY年MM月DD日 HH:mm:ss");
  } else if (language === "en") {
    // 格式化成美式时间
    return date.format("h:mm A, MMMM D, YYYY");
  } else {
    // 自动localized格式
    // return date.format();
    return date.format("MM-DD-YYYY");
  }
};

export const getTimezoneOffset = (timezone) => {
  const now = DateTime.now().setZone(timezone);
  const offset = now.offset;
  return offset;
};

/**
 * get format time description
 * @param timestamp
 * @returns time description like "2022/10/22 12:08"
 */
export const getTimeInWordDescription3 = (timestamp: number | string) => {
  const time = new Date(timestamp);
  const year = time.getFullYear();
  const month = time.getMonth() + 1;
  const day = time.getDate();
  const hour = time.getHours();
  const minute = time.getMinutes();
  return (
    year +
    "/" +
    add0(month) +
    "/" +
    add0(day) +
    " " +
    add0(hour) +
    ":" +
    add0(minute)
  );
};

/**
 * get format date description
 * @param timestamp
 * @returns time description like "10/22/2022 "
 */
export const getDateFormat1 = (timestamp: number) => {
  const time = new Date(timestamp);
  const year = time.getFullYear();
  const month = time.getMonth() + 1;
  const day = time.getDate();
  return add0(month) + "/" + add0(day) + "/" + year;
};

/**
 * get format date description
 * @param timestamp
 * @returns time description like "Feb 27, 2021"
 */
export const getDateInWordDescription = (timestamp: number) => {
  const language = localStorage.getItem("language") ?? "en";
  const date = dayjs(timestamp);
  if (language === "zh-CN") {
    return date.format("YYYY年MM月DD日");
  } else if (language === "en") {
    return `${getMonthInWord(date.month())} ${date.date()}, ${date.year()}`;
  } else {
    return date.format("MM-DD-YYYY");
  }
};

export const getMonthInWord = (month: number, short?: boolean) => {
  const allMonths = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const shortMonths = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ];
  return short ? shortMonths[month] : allMonths[month];
};

export const getStrTime = (date: Date) => {
  let hours = date.getHours();
  let minutes = date.getMinutes();
  const ampm = hours >= 12 ? "pm" : "am";
  hours = hours % 12;
  hours = hours === 0 ? 12 : hours; // the hour '0' should be '12'
  const strMinutes = minutes < 10 ? "0" + minutes : minutes;
  var strTime = hours + ":" + strMinutes + " " + ampm;
  return strTime;
};

/**
 * Get time area description
 * @param startDate
 * @param endDate
 * @returns time area description like "2022/07/27 08:00 ~ 09:00"
 */
export const getTimeAreaDesc = (startDate: Date, endDate: Date) => {
  const startYear = startDate.getFullYear();
  const startMonth = startDate.getMonth() + 1;
  const startDay = startDate.getDate();
  let startHours = startDate.getHours();
  const startHoursStr = startHours < 10 ? "0" + startHours : startHours;
  let startMinutes = startDate.getMinutes();
  const strMinutes = startMinutes < 10 ? "0" + startMinutes : startMinutes;
  const startTime = startHoursStr + ":" + strMinutes;
  const startTimeDesc = `${startYear}/${startMonth}/${startDay} ${startTime}`;

  const endYear = endDate.getFullYear();
  const endMonth = endDate.getMonth() + 1;
  const endDay = endDate.getDate();
  let endHours = endDate.getHours();
  const endHoursStr = endHours < 10 ? "0" + endHours : endHours;
  let endMinutes = endDate.getMinutes();
  const strEndMinutes = endMinutes < 10 ? "0" + endMinutes : endMinutes;
  const endTime = endHoursStr + ":" + strEndMinutes;

  let endTimeDesc = "";
  if (startYear === endYear && startMonth === endMonth && startDay === endDay) {
    endTimeDesc = endTime;
  } else {
    endTimeDesc = `${endYear}/${endMonth}/${endDay} ${endTime}`;
  }
  return startTimeDesc + " ~ " + endTimeDesc;
};

/**
 * get date and what day of the week
 * @param date
 * @returns sample: 'Sunday, August 28'
 */
export const getDateAndWeekDayStr = (date: Date, short?: boolean) => {
  const monthStr = getMonthInWord(date.getMonth(), short);
  const dayOfMonth = date.getDate();
  const weekDay = getWeekDayStr(date, short);
  return `${weekDay}, ${monthStr} ${dayOfMonth}`;
};

export const getWeekDayStr = (date: Date, short?: boolean) => {
  const WEEKDAYS = short
    ? ["Sun", "Mon", "Tues", "Wed", "Thur", "Fri", "Sat"]
    : [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
  const day = date.getDay();
  return WEEKDAYS[day];
};

export const getChatMessageTimeDesc = (timestamp: number) => {
  const nowDate = new Date();
  const current = nowDate.getTime() / 1000;
  const ago = current - timestamp / 1000;
  if (ago < 10) {
    return i18n.t("app.time.just-now");
  }
  // < 60s
  if (ago < 60) {
    return i18n.t("app.time.seconds-ago", { seconds: Math.floor(ago) })
  }

  const time = new Date(timestamp);
  const y = time.getFullYear();
  const m = time.getMonth() + 1;
  const d = time.getDate();
  const h = time.getHours();
  const mm = time.getMinutes();
  // the same date
  if (
    y === nowDate.getFullYear() &&
    time.getMonth() === nowDate.getMonth() &&
    d === nowDate.getDate()
  ) {
    return add0(h) + ":" + add0(mm);
  }
  // < 7 days
  if (ago < 60 * 60 * 24 * 7) {
    return add0(m) + "-" + add0(d) + " " + add0(h) + ":" + add0(mm);
  }
  return getFormatDisplayTimeTillMinute(timestamp);
};

// export const getCommentTimeDesc = (timestamp: number) => {
//   const nowDate = new Date();
//   const current = nowDate.getTime() / 1000;
//   const ago = current - timestamp / 1000;
//   if (ago < 10) {
//     return isEN ? "just now" : "现在";
//   }
//   // < 60s
//   if (ago < 60) {
//     return Math.floor(ago) + (isEN ? " seconds ago" : " 秒之前");
//   }

//   const time = new Date(timestamp);
//   const y = time.getFullYear();
//   // const m = time.getMonth()+1;
//   const d = time.getDate();
//   const h = time.getHours();
//   const mm = time.getMinutes();
//   // the same date
//   if (
//     y === nowDate.getFullYear() &&
//     time.getMonth() === nowDate.getMonth() &&
//     d === nowDate.getDate()
//   ) {
//     return (isEN ? "today " : "今天 ") + add0(h) + ":" + add0(mm);
//   }
//   return getFormatDisplayTimeTillMinute(timestamp);
// };

export const getTimeCountText = (timeSeconds: number) => {
  const hours = Math.floor(timeSeconds / (60 * 60));
  const minutes = Math.floor((timeSeconds - hours * 60 * 60) / 60);
  const seconds = timeSeconds - hours * 60 * 60 - minutes * 60;
  return add0(hours) + " : " + add0(minutes) + " : " + add0(seconds);
};

export const getLeftDays = (timestamp: number) => {
  const current = new Date().getTime();
  if (timestamp <= current) {
    return 0;
  } else {
    const left = timestamp - current;
    return Math.floor(left / (24 * 60 * 60 * 1000));
  }
};


export const getAverage = (elements: number[], num: number): number => {
  let sum = 0;

  const lastElements = elements.slice(Math.max(elements.length - num, 1));
  for (let i = 0; i < lastElements.length; i++) {
    sum += lastElements[i];
  }

  return Math.ceil(sum / num);
}

export const convertToTimestamp = (
  date: dayjs.Dayjs | null,
  toSeconds?: boolean
): number => {
  if (!date) {
    return 0;
  }
  const milliseconds = date.valueOf(); // 获取毫秒级时间戳
  return toSeconds ?? false ? Math.floor(milliseconds / 1000) : milliseconds;
};

/**
 * 格式化秒为时分秒
 */
export const formatSecondsTime = (seconds: number)=> {
  if (typeof seconds !== 'number' || seconds < 0) {
    return 0;
  }
  const formatSeconds = Math.round(seconds);
  const hours = Math.floor(formatSeconds / 3600);
  const minutes = Math.floor((formatSeconds % 3600) / 60);
  const secs = formatSeconds % 60;
  const language = localStorage.getItem("language") ?? "en";
  if (language === "zh-CN") {
    if (hours > 0) {
      return `${hours}小时${minutes}分${secs}秒`;
    } else if (minutes > 0) {
      return `${minutes}分${secs}秒`;
    } else {
      return `${secs}秒`;
    }
  } else if (language === "en") {
    if (hours > 0) {
      return `${hours}h ${minutes}m ${secs}s`;
    } else if (minutes > 0) {
      return `${minutes}m ${secs}s`;
    } else {
      return `${secs}s`;
    }
  } else {
    return 0;
  }
}

/**
 * 毫秒时间戳转化为dayjs.Dayjs类型
 */
export const convertToDayjs = (timestamp: number|string|null): dayjs.Dayjs => {
  if(!timestamp) {
    return dayjs();
  }
  const time = Number(timestamp)
  return dayjs(time);
};