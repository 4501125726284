import { Box, useTheme, Paper, Typography, Skeleton } from "@mui/material";
import { useState, FC, useCallback, useEffect } from "react";
import { makeStyles } from "tss-react/esm/mui";
import { useGetHistoryMutation } from "src/service/api/search-api";
import { useTranslation } from "react-i18next";
import { useSelector } from "src/tools/toolkit/store";
import icoDelete from "src/images/search/ico-delete.svg";
import CloseIcon from "@mui/icons-material/Close";
import {
  clearSearchHistory,
  removeSearchHistoryItem,
} from "src/service/redux/search-slice";
import { useDispatch } from "react-redux";
import { useAdminGetCategoryListMutation } from "src/service/api/post-api";
import { Link } from "react-router-dom";
import { appConfig } from "src/app-config";

const useStyles = makeStyles()(() => {
  return {
    title: {
      fontSize: "16px",
      fontWeight: 500,
      color: "#000000",
      marginBottom: "10px",
    },
    content: {
      display: "flex",
      flexWrap: "wrap",
      gap: "8px",
      marginBottom: "10px",
    },
    smallCard: {
      width: "48%",
      height: "26px",
      padding: "6px 10px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      borderRadius: "4px",
      background: "#f5f5f5",
      color: "#000000",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      fontSize: "14px",
      cursor: "pointer",
    },
    deleteButton: {
      color: "#858B93",
      cursor: "pointer",
      "&:hover": {
        color: "#000",
      },
    },
    textBox: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      fontSize: "14px",
    },
  };
});

interface Props {
  handelSelectedText: (text: string) => void;
}

interface Category {
  tag_id: string;
  cn_name: string;
  name: string;
}

const SearchHistoryBox: FC<Props> = ({ handelSelectedText }) => {
  const theme = useTheme();
  const { t, i18n } = useTranslation();
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const { searchHistory } = useSelector((state) => state.search);
  const [getHotList, { isLoading: hotLoading }] = useGetHistoryMutation();
  const [isDelete, setIsDelete] = useState<boolean>(false);
  const [getCategoryList, { isLoading: listLoading }] =
    useAdminGetCategoryListMutation();
  const [categoryList, setCategoryList] = useState<Category[]>([]);
  const [hotList, setHotList] = useState<string[]>([]);

  const handleGetHotList = useCallback(() => {
    getHotList({})
      .unwrap()
      .then((res) => {
        if (!res?.isFailed && res?.result?.length > 0) {
          setHotList(res?.result?.slice(0, 8));
        } else {
          setHotList([]);
        }
      });
  }, [getHotList]);

  useEffect(() => {
    handleGetHotList();
  }, [handleGetHotList]);

  const handleDeleteItem = (e, item: string) => {
    e.stopPropagation();
    dispatch(removeSearchHistoryItem(item));
  };
  const handleDeleteAll = (e) => {
    e.stopPropagation();
    dispatch(clearSearchHistory());
  };
  const handleGetCategoryList = useCallback(() => {
    getCategoryList({ start_key: "", page_size: 6 })
      .unwrap()
      .then((res) => {
        if (res && !res.isFailed) {
          const list = res?.result?.itemList?.slice(0, 8);
          setCategoryList(list || []);
        }
      });
  }, [getCategoryList]);
  useEffect(() => {
    handleGetCategoryList();
  }, [handleGetCategoryList]);

  const list = [1, 2, 3, 4, 5, 6, 7, 8];

  return (
    <Paper
      sx={{
        width: "400px",
        position: "absolute",
        top: "45px",
        zIndex: theme.zIndex.tooltip,
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        padding: "20px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      onClick={(e) => e.stopPropagation()}
    >
      <Box
        sx={{
          width: "100%",
          height: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography className={classes.title}>
            {t("app.search.search-history")}
          </Typography>
          <Box>
            {isDelete ? (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography
                  className={classes.deleteButton}
                  sx={{
                    marginRight: "10px",
                  }}
                  onClick={handleDeleteAll}
                >
                  {t("app.search.delete-all")}
                </Typography>
                <Typography
                  className={classes.deleteButton}
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsDelete(false);
                  }}
                >
                  {t("app.search.cancel")}
                </Typography>
              </Box>
            ) : (
              <Box
                component="img"
                src={icoDelete}
                onClick={(e) => {
                  e.stopPropagation();
                  setIsDelete(true);
                }}
                sx={{
                  color: "#858B93",
                  cursor: "pointer",
                  "&:hover": {
                    color: "#000",
                  },
                }}
              />
            )}
          </Box>
        </Box>
        <Box className={classes.content}>
          {searchHistory?.map((item, index) => {
            return (
              <Box
                key={item}
                className={classes.smallCard}
                onClick={() => handelSelectedText(item)}
              >
                <Typography className={classes.textBox}>{item}</Typography>
                {isDelete && (
                  <CloseIcon
                    sx={{
                      width: "12px",
                      height: "12px",
                      color: "#858B93",
                      cursor: "pointer",
                      "&:hover": {
                        color: "#000",
                      },
                    }}
                    onClick={(e) => handleDeleteItem(e, item)}
                  />
                )}
              </Box>
            );
          })}
        </Box>
        <Typography className={classes.title} sx={{ marginTop: "20px" }}>
          {t("app.search.recent-hot-searches")}
        </Typography>
        {hotLoading ? (
          <Box className={classes.content}>
            {list.map((item, index) => (
              <Skeleton
                className={classes.smallCard}
                key={index}
                variant="rectangular"
              />
            ))}
          </Box>
        ) : (
          <Box className={classes.content}>
            {hotList?.map((item) => {
              return (
                <Box
                  className={classes.smallCard}
                  onClick={() => handelSelectedText(item)}
                >
                  <Typography className={classes.textBox}>{item}</Typography>
                </Box>
              );
            })}
          </Box>
        )}
        <Typography className={classes.title} sx={{ marginTop: "20px" }}>
          {t("app.search.search-category")}
        </Typography>
        {listLoading ? (
          <Box className={classes.content}>
            {list.map((item, index) => (
              <Skeleton
                className={classes.smallCard}
                key={index}
                variant="rectangular"
              />
            ))}
          </Box>
        ) : (
          <Box className={classes.content}>
            {categoryList?.map((item) => {
              return (
                <Box
                  key={item?.tag_id}
                  className={classes.smallCard}
                  component={Link}
                  to={`${appConfig.paths.autoTopic}/${encodeURIComponent(
                    item.tag_id
                  )}`}
                  target="_blank"
                >
                  <Typography className={classes.textBox}>
                    {i18n.language === "zh-CN" ? item.cn_name : item.name}
                  </Typography>
                </Box>
              );
            })}
          </Box>
        )}
      </Box>
    </Paper>
  );
};
export default SearchHistoryBox;
