import { Response, Result, Value } from "../../models/common";
import { createApi } from "@reduxjs/toolkit/query/react";
import { buildQueryWithReAuth } from "../../tools/toolkit/helper";
import { AdminListResponse, RoleListResponse, Role, PermissionResponse } from "src/models/admin";
import { BlockRequest, CancelBlockRequest } from "src/models/user"

export const adminRoleApi = createApi({
  reducerPath: "adminRole",
  baseQuery: buildQueryWithReAuth(process.env.REACT_APP_ADMIN_API_URL),
  endpoints(builder) {
    return {
      adminSetRole: builder.mutation<
        Result<undefined>,
        {
          startKey: string;
          userId: string;
          roleId: string;
        }
      >({
        query: ({ startKey, userId, roleId }) => ({
          url: "adminmanamgement",
          method: "POST",
          body: {
            request_type: "admin_role",
            request_data: {
              type: 7,
              user_id: userId,
              role_id: roleId,
            },
          },
        }),
        transformResponse: (res: Response<undefined>) => new Value(res),
      }),
      getAdminAccountList: builder.mutation<
        Result<AdminListResponse>,
        {
          role_name?:string;
          startKey: string;
        }
      >({
        query: ({ startKey, role_name }) => ({
          url: "adminmanamgement",
          method: "POST",
          body: {
            request_type: "admin_role",
            request_data: {
              type: 6,
              role_name,
              startkey: startKey,
              pagesize: 8,
            },
          },
        }),
        transformResponse: (res: Response<AdminListResponse>) => new Value(res),
      }),
      getRoleList: builder.mutation<Result<RoleListResponse>, {}>({
        query: () => ({
          url: "adminmanamgement",
          method: "POST",
          body: {
            request_type: "admin_role",
            request_data: {
              type: 5,
            },
          },
        }),
        transformResponse: (res: Response<RoleListResponse>) => new Value(res),
      }),
      createNewRole: builder.mutation<Result<undefined>, {
        role_name: string;
        description: string;
        permissions: string[];
      }>({
        query: ({
          role_name,
          description,
          permissions,
        }) => ({
          url: "adminmanamgement",
          method: "POST",
          body: {
            request_type: "admin_role",
            request_data: {
              type: 1,
              role_name,
              description,
              permissions,
            },
          },
        }),
        transformResponse: (res: Response<undefined>) => new Value(res),
      }),
      getRole: builder.mutation<Result<Role>, {
        role_id: string
      }>({
        query: ({
          role_id
        }) => ({
          url: "",
          method: "POST",
          body: {
            request_type: "admin_role",
            request_data: {
              type: 2,
              role_id
            },
          },
        }),
        transformResponse: (res: Response<Role>) => new Value(res),
      }),
      upDateRole: builder.mutation<Result<undefined>, {
        role_id: string;
        role_name: string;
        description: string;
        permissions: string[];
      }>({
        query: ({
          role_id,
          role_name,
          description,
          permissions,
        }) => ({
          url: "adminmanamgement",
          method: "POST",
          body: {
            request_type: "admin_role",
            request_data: {
              role_id,
              type: 4,
              role_name,
              description,
              permissions,
            },
          },
        }),
        transformResponse: (res: Response<undefined>) => new Value(res),
      }),
      deleteRole: builder.mutation<Result<undefined>, {
        role_id:string
      }>({
        query: ({
          role_id
        }) => ({
          url: "adminmanamgement",
          method: "POST",
          body: {
            request_type: "admin_role",
            request_data: {
              type: 3,
              role_id
            },
          },
        }),
        transformResponse: (res: Response<undefined>) => new Value(res),
      }),
      getPermissionList: builder.mutation<Result<PermissionResponse>, {
      }>({
        query: () => ({
          url: "adminmanamgement",
          method: "POST",
          body: {
            request_type: "admin_role",
            request_data: {
              type: 8
            },
          },
        }),
        transformResponse: (res: Response<PermissionResponse>) => new Value(res),
      }),
      blockUser: builder.mutation<Result<undefined>, BlockRequest>({
        query: (BlockRequest) => ({
          url: `blockuser`,
          method: "POST",
          body: BlockRequest
        }),
        transformResponse: (res: Response<undefined>) => new Value(res),
      }),
      cancelBlockUser: builder.mutation<Result<undefined>, CancelBlockRequest>({
        query: (CancelBlockRequest) => ({
          url: `blockuser`,
          method: "POST",
          body: CancelBlockRequest,
        }),
        transformResponse: (res: Response<undefined>) => new Value(res),
      }),
    };
  },
});

export const {
  useAdminSetRoleMutation,
  useGetAdminAccountListMutation,
  useGetRoleMutation,
  useGetRoleListMutation,
  useCreateNewRoleMutation,
  useUpDateRoleMutation,
  useDeleteRoleMutation,
  useGetPermissionListMutation,
  useBlockUserMutation,
  useCancelBlockUserMutation,
} = adminRoleApi;
