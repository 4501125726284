import { useEffect } from "react";
import { useNavigationType, NavigationType, useLocation } from "react-router-dom";
import { useSelector } from 'src/tools/toolkit/store';

const useRefreshFunction = () => {
  const navigationType = useNavigationType();
  const location = useLocation();
  const { refreshFunctionRecord } = useSelector(state => state.refreshList);

  useEffect(() => {
    if (navigationType === NavigationType.Replace) {
      const refreshFunction = refreshFunctionRecord[location.pathname]
      if (refreshFunction) {
        refreshFunction()
      }
    }
  }, [navigationType, location, refreshFunctionRecord])
}


export default useRefreshFunction;