import * as React from "react";
import { Button, CircularProgress, Divider, TextField, Typography, useMediaQuery, useTheme } from "@mui/material";
import { Box, IconButton } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import CloseIcon from '@mui/icons-material/Close';
import { useSnackbar } from 'notistack'
import { Loader } from '../loader'
import { useDispatch } from 'react-redux'
import { useSelector } from "src/tools/toolkit/store";
import { ChatMessage, MessageStatusEnum, WebSocketMessage, WebsocketChatMessage, transferChatMessage, transferChatMessageList } from "src/models/chat";
import useWebSocket from 'react-use-websocket'
import { showSignInUpDialog } from "src/service/redux/signinup-slice";
import { decodeJWT } from "src/tools/utils/auth";
import { getFormatDisplayTime } from "src/tools/utils/timeUtil";
import { uuid } from "src/tools/utils/stringUtil";
// import { SvgComponent } from "../svgComponent";
import { RichChatMessageList } from "./RichChatMessageList";
// import { Picker } from 'emoji-mart'
// import 'emoji-mart/css/emoji-mart.css'
// import copy from 'copy-to-clipboard';
import { DraggablePaper } from "../draggable";
// import DefaultAvatar from "src/images/defaultIcons/default-avatar.png"
import { signInRefreshToken } from "src/tools/utils/refreshToken";
import { checkResponse } from "src/tools/utils/api";
import { setRefreshToken, setToken } from "src/service/redux/access-slice";
import { PostEntity } from "src/models/post";
import { useTranslation } from 'react-i18next';
import icoDisconnect from "src/images/chatDialog/ico-disconnect.svg"
import { getToken, getRefreshToken } from "src/tools/utils/storage";

const Dot = "•";

export interface AutoSendMessage {
  send: boolean,
  sendType: "post" | "response",
  post: PostEntity | undefined
}

interface PropsForm {
  sendMessageOnOpen?: AutoSendMessage;
  open: boolean;
  chatUserId: string;
  chatUserName: string;
  onClickClose?: () => void;
  chatType: "user" | "ai"
}

const ChatDialog: React.FC<PropsForm> = ({ sendMessageOnOpen, open, chatUserId, chatUserName, onClickClose, chatType }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  // const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch()
  const { credential } = useSelector(state => state.app);
  // const { token, refreshToken } = useSelector(state => state.tokenRes);
  const token = getToken() ?? '';
  const refreshToken = getRefreshToken() ?? '';
  // const { showChatDialog, chatTeacherId, showCount } = useSelector(state => state.chat);
  const messagesEndRef = React.useRef<any>(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [emoji, setEmoji] = React.useState();
  const [isEmojiPickerDisplay, setIsEmojiPickerDisplay] = React.useState(false)
  const [newChatMessage, setNewChatMessage] = React.useState('');
  const [chatMessageList, setChatMessageList] = React.useState<ChatMessage[]>([]);
  const [chatMessageListStartkey, setchatMessageListStartkey] = React.useState<string>('')//chatMessageList start_key
  const [isShowLoading, setisShowLoading] = React.useState<boolean>(false)//是否显示loading
  const [connectRetryCount, setConnectRetryCount] = React.useState(0);
  const [startHeartBeat, setStartHeartBeat] = React.useState(false);
  const [heartbeatCount, setHeartbeatCount] = React.useState(0);
  const [isShowError, setIsShowError] = React.useState<boolean>(false);
  const [dots, setDots] = React.useState(Dot);
  const [isAnswering, setIsAnswering] = React.useState(false);

  const INPUT_MAX_COUNT = 1000;
  const CONNECT_WEBSOCKET_RETRY_LIMIT = 20;
  const CHAT_MESSAGE_PAGE_SIZE = 20;

  React.useEffect(() => {
    if (startHeartBeat) {
      setTimeout(() => {
        sendHeartBeat();
        // console.log('set heart beat count '+ (heartbeatCount+1))
        setHeartbeatCount(count => count + 1);
      }, 30 * 1000);
    }
  }, [startHeartBeat, heartbeatCount])

  // React.useEffect(() => {
  //   if (chatUserId) {
  //     startChat(chatUserId);
  //   }
  // }, [chatUserId])

  const startChat = (userId: string) => {
    const params = {
      action: "switchdialog",
      talk_with_id: userId,
    };
    // console.log("send chat message: ", JSON.stringify(params))
    sendJsonMessage(params);
  }

  /**
   * 点击右上角关闭
   */
  const handleClickCloseButton = () => {
    setStartHeartBeat(false);
    onClickClose && onClickClose();
  }

  /**
   * 滚动到聊天消息底部（TODO）
   */
  const scrollToBottom = () => {
    // TODO 
    messagesEndRef?.current?.scrollIntoView({ behavior: "smooth" });
  };

  // 监听聊天内容区的滚动
  const handleScroll = (e: any) => {
    if (e.target.scrollTop === 0) {
      if (chatMessageListStartkey) {
        setisShowLoading(true)
        setTimeout(() => {
          setisShowLoading(false)
        }, 800);
      }
      if (chatMessageListStartkey) {
        // startkey存在则继续加载
        getChatMessageList(chatMessageListStartkey);
      }
    }
  }

  /**
   * 请求购买post后打开聊天时，自动发送post链接
   * @returns 
   */
  const handleAutoSend = () => {
    if (!sendMessageOnOpen || !sendMessageOnOpen?.send) {
      return;
    }
    const params = {
      action: "sendmessage",
      message: {
        content: JSON.stringify(sendMessageOnOpen?.post),
        id: uuid(),
        type: "post",
        user_avatar: credential?.user_avatar,
        user_id: credential?.user_id,
        user_name: credential?.user_name,
      }
    };
    sendJsonMessage(params);

    // 先把消息显示在聊天列表里
    setNewChatMessage("");
    const newMessage: ChatMessage = {
      id: params.message.id,
      type: params.message.type,
      content: params.message.content,
      sender_id: credential?.user_id || "",
      sender_name: credential?.user_name || "",
      sender_avatar: credential?.user_avatar || "",
      status: MessageStatusEnum.Sending,
      message_read: false,
    }
    setChatMessageList(messages => [...messages, newMessage]);
    handleClickSendChatMessage(t("app.chat.want-purchase"));
    scrollToBottom();
  }

  const getWebsocketUrl = (token: string | null | undefined) => {
    return `${process.env.REACT_APP_WEBSOCKET_URL}/dialog?&token=${token}`;
  }

  const [websocketUrl, setWebsocketUrl] = React.useState(getWebsocketUrl(token));
  /* websocket */
  const { sendJsonMessage, getWebSocket } =
    useWebSocket(websocketUrl, {
      // websocket连接成功的处理
      onOpen: () => {
        console.log("chat websocket is connected: " + websocketUrl);
        if (chatUserId) {
          startChat(chatUserId);
        }
        setStartHeartBeat(true);
      },
      // 收到消息
      onMessage: (e) => {
        if (e.data) {
          const msg: any = JSON.parse(e.data);
          if (msg?.code === 201221) {
            enqueueSnackbar(t("app.chatroom.block_Send_error_tip"), { variant: "error" });
            return;
          }
          if (msg.action === "dialogmessage") {
            const getMsg: WebSocketMessage = JSON.parse(e.data);
            const socketMessages: WebsocketChatMessage[] = getMsg.messages;
            if (socketMessages && socketMessages.length > 0) {
              const list = transferChatMessageList(socketMessages);
              if (chatMessageListStartkey) {
                setChatMessageList(messages => [...list, ...messages]);
              } else {
                setChatMessageList(list);
                handleAutoSend();
              }

              // 消息标记为已读
              const lastMessage = socketMessages[socketMessages.length - 1];
              setMessageRead(lastMessage.message_id!);
            } else {
              handleAutoSend();
            }

            if (!chatMessageListStartkey) {
              setTimeout(() => {
                scrollToBottom();
              }, 300);
            }
            setchatMessageListStartkey(msg.start_key);
          } else if (msg.action === "sendmessage") {
            // console.log("send message callback:" + JSON.stringify(msg));
            // 即时发送和收到的消息，包括自己发的消息
            const callbackMessage: WebsocketChatMessage = msg;
            handleReceiveChatMessage(callbackMessage);
            setNewChatMessage("");
            if (msg?.message?.user_type === "AI"){
              setIsAnswering(false);
            }
          } else if (msg.action === "deletemessage") {
            // console.log("delete message:" + JSON.stringify(msg));
            // const deleteMessageId = msg.messageid;
            // // 移除被删的聊天消息
            // const filterChatMessageList = chatMessageList.filter(item => item.id !== deleteMessageId);
            // setChatMessageList(filterChatMessageList);
          } else if (msg.action === "disconnect") {
            console.log(msg.user.name + " disconnected!")
          } else if (msg.action === "connect") {
            console.log(msg.user.name + " join in!")
          } else if (msg.action === "switchdialog") {
            getChatMessageList("");
          }
        }
      },
      onError: () => {
        setIsShowError(true);
        console.log("Connect to chat websocket failed: " + websocketUrl + ", retry count:" + connectRetryCount)
        setStartHeartBeat(false);
      },
      //Will attempt to reconnect on all close events, such as server shutting down
      shouldReconnect: closeEvent => {
        console.log("chat websocket is closed, code: " + closeEvent.code + ", token: " + token);
        if (token) {
          const tokenData = decodeJWT(token);
          const currentTimeSeconds = new Date().getTime() / 1000;
          if (tokenData && tokenData.exp < currentTimeSeconds) {
            // if (tokenData.exp - currentTimeSeconds < 59*60) {
            console.log("Websocket token is expired, will refresh...old token expire time: ", getFormatDisplayTime(tokenData?.exp * 1000))
            tryToRefreshToken(tokenData.userid);
          } else {
            const newUrl = getWebsocketUrl(token);
            setWebsocketUrl(newUrl);
          }
          setConnectRetryCount(count => count + 1);
          return true;
        } else {
          return false
        }
      },
      retryOnError: !!token,
      // // 系统默认值，重试20次，每次间隔5秒
      reconnectAttempts: CONNECT_WEBSOCKET_RETRY_LIMIT,
      reconnectInterval: 5000,
    })

  // 刷新聊天消息
  const getChatMessageList = (startKey: string) => {
    // if (!currentChat || !currentChat.chat_id) {
    //   return;
    // }
    const params = {
      action: "dialogmessage",
      talk_with_id: chatUserId,
      page_size: CHAT_MESSAGE_PAGE_SIZE,
      start_key: startKey,
      reverse: false,
    }
    // console.log("get comment list params: ", JSON.stringify(params))
    sendJsonMessage(params);
  }

  const tryToRefreshToken = async (userId: string) => {
    const response = await signInRefreshToken(userId, refreshToken || "");
    if (response && checkResponse(response)) {
      const { token, refresh_token } = response.data?.data;
      // storeAccessToken(token);
      dispatch(setToken(token));
      dispatch(setRefreshToken(refresh_token));
      // update websocket url
      const newUrl = getWebsocketUrl(token);
      console.log("chat websocket refresh token successfully and set new url: " + newUrl);
      setWebsocketUrl(newUrl);
    }
  }

  /**
   * 接收消息并处理
   * @param msg 
   */
  const handleReceiveChatMessage = (msg: WebsocketChatMessage) => {
    if (!msg || !msg.message) {
      return;
    }
    // 消息标记为已读
    setMessageRead(msg.message_id!);
    // 刷新聊天消息列表
    const findMessage = chatMessageList.find(item => item.id === msg.message.id);
    if (!findMessage) {// 不是重复消息
      const transferMessage = transferChatMessage(msg);
      setChatMessageList(messages => [...messages, transferMessage]);
    } else {
      if (findMessage.status === MessageStatusEnum.Sending) {
        findMessage.status = MessageStatusEnum.Finished;
        findMessage.time = msg.time;
        setChatMessageList([...chatMessageList]);
      }
    }
    scrollToBottom();
  }

  /**
   * 输入框的监听
   * @param event 
   */
  const handleInputChatMessageChange = (event) => {
    const content = event.target.value;
    if (content?.length > INPUT_MAX_COUNT) {
      enqueueSnackbar("exceed max length")
    } else {
      setNewChatMessage(content)
    }

  }

  /**
   * 按下回车键
   * @param event 
   */
  const onChatKeyDown = (event) => {
    if (event.keyCode === 13) {
      handleClickSendChatMessage(newChatMessage)
    }
  }

  /**
   * 点击发送按钮
   * @returns 
   */
  const handleClickSendChatMessage = (newChatMessage: string) => {
    if (!(newChatMessage.trim())) {
      return;
    }
    if (!credential) {
      dispatch(showSignInUpDialog())
      return;
    }
    setIsAnswering(true);
    const params = {
      action: "sendmessage",
      message: {
        content: newChatMessage,
        id: uuid(),
        type: 'text',
        user_avatar: credential?.user_avatar,
        user_id: credential?.user_id,
        user_name: credential?.user_name,
      }
    };
    // console.log("send chat message: ", JSON.stringify(params))
    sendJsonMessage(params);

    setNewChatMessage("");
    const newMessage: ChatMessage = {
      id: params.message.id,
      type: params.message.type,
      content: params.message.content,
      sender_id: credential?.user_id,
      sender_name: credential?.user_name,
      sender_avatar: credential?.user_avatar,
      status: MessageStatusEnum.Sending,
      message_read: false,
    }
    setChatMessageList(messages => [...messages, newMessage]);
    scrollToBottom();
  }

  const setMessageRead = (messageId: string) => {
    sendJsonMessage({ action: "readmessage", message_id: messageId });
  }

  const sendHeartBeat = () => {
    // console.log('send heart beat')
    sendJsonMessage({ action: "heartbeat" });
  }

  /**
   * 打开emoji表情框
   * @returns 
   */
  const handleOpenEmojiPicker = () => {
    if (!credential) {
      dispatch(showSignInUpDialog())
      return
    }
    setIsEmojiPickerDisplay(value => !value)
  }

  /**
   * 选择表情
   * @param emoji 
   */
  const handlePickEmoji = (emoji) => {
    setEmoji(emoji)
    setIsEmojiPickerDisplay(false)
    const newContent = newChatMessage + emoji.native;
    if (newContent.length <= INPUT_MAX_COUNT) {
      setNewChatMessage(newContent)
    } else {
      enqueueSnackbar("exceed max length")
    }
  }

  React.useEffect(() => {
    if (isAnswering && chatType === "ai") {
      const interval = setInterval(() => {
        setDots(prevDots => {
          if (prevDots.length < 3) {
            return prevDots + Dot;
          } else {
            return Dot;
          }
        });
      }, 300);
      return () => clearInterval(interval);
    } else {
      setDots(Dot); // Reset when not answering
    }
  }, [isAnswering, chatType]);

  return (
    <>
      <Dialog
        open={open}
        maxWidth="lg"
        PaperComponent={DraggablePaper}
        aria-labelledby="draggable-dialog-title"
      >
        <Box style={{ cursor: 'move', height: '5px' }} id="draggable-dialog-title" />
        <DialogContent sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          padding: "0px !important",
          justifyContent: "center",
          height: "620px",
          [theme.breakpoints.down("sm")]: {
            width: "80vw",
            height: "520px",
          }
        }} >

          {/* right close button */}
          <IconButton onClick={() => handleClickCloseButton()} style={{ position: "absolute", right: 10, top: 10, zIndex: 10 }}>
            <CloseIcon />
          </IconButton>

          {/* 聊天窗口 */}
          <Box sx={{
            position: "relative",
            background: 'white',
            flexGrow: 1,
            minWidth: "760px",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            [theme.breakpoints.down("sm")]: {
              minWidth: "160px",
            }
          }}>
            <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', height: '70px' }}>

              <Typography sx={{
                fontSize: "16px !important",
                fontFamily: "Arial-BoldMT, Arial !important",
                lineClamp: 1,
                WebkitLineClamp: 1,
                whiteSpace: 'nowrap',
                overflow: "hidden",
                textOverflow: "ellipsis",
                maxWidth: "200px",
                [theme.breakpoints.down("sm")]: {
                  maxWidth: "90px",
                }
              }}>
                {/* {getDisplayUserName(currentChat?.chat_with.name || "", currentChat?.chat_with.id || "")} */}
                {chatUserName}
              </Typography>
            </Box>
            <Divider />

            {/* 聊天消息内容区 */}
            {isShowError ? <Box sx={{
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              paddingBottom: "350px"
            }}>
              <Box
                component="img"
                src={icoDisconnect}
              />
              <Typography sx={{
                marginTop: "16px",
                fontSize: "14px",
                fontWeight: "400"
              }}>
                {t("app.chat.connection-failed")}
              </Typography>
            </Box> : <Box sx={{
              padding: "20px",
              height: "100%",
              overflow: "auto",
            }} onScroll={handleScroll}>
              <RichChatMessageList
                chatMessageList={chatMessageList}
                enableEdit={false}
                onClickMessageMenu={() => {/* */ }}
              />

              <div style={{ marginTop: "100px" }}
                ref={messagesEndRef}>
              </div>
            </Box>}

            {/* loading... */}
            <Box sx={{
              display: 'flex',
              position: "absolute",
              left: "47.5%",
              top: "18%",
            }} style={{ display: isShowLoading ? "" : "none" }}>
              <CircularProgress />
            </Box>

            <Divider />
            {/* 底部输入框 */}
            {!isShowError && <Box sx={{
              display: "flex",
              alignItems: "center",
              padding: "10px",
              position: 'relative',
              [theme.breakpoints.down("sm")]: {
                height: "30px",
              }
            }}>

              {isAnswering && chatType === "ai" && <Box
                sx={{
                  position: 'absolute',
                  top: '-23px',
                  fontFamily: 'Helvetica Neue',
                  fontSize: '13px',
                  fontWeight: '400',
                  lineHeight: "22px",
                  backgroundColor: '#fff',
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  gap: '3px',
                  zIndex: 10
                }}
              >
                {t("app.chatroom.ai-answering", { username: chatUserName })}
                <Box>{dots}</Box>
              </Box>}
              
              <Box sx={{
                flexGrow: 1,
                marginRight: "20px",
                display: 'flex',
                alignItems: "center",
                padding: "11px",
                [theme.breakpoints.down("sm")]: {
                  borderRadius: "20px",
                  marginRight: "20px",
                  padding: "5px",
                }
              }}>
                {/* emoji表情 */}
                {/* <SvgComponent iconName="ico-emoji" className={classes.emoji} onClick={handleOpenEmojiPicker} />
                {isEmojiPickerDisplay &&
                  <Box className={classes.emojiBox}>
                    <Picker
                      set={'apple'}
                      onClick={(emoji) => { handlePickEmoji(emoji) }}
                      id='emoji_picker'
                    />
                  </Box>
                } */}
                {/* 文字输入框 */}
                <TextField
                  autoFocus
                  placeholder={t("app.chat.typeMessage")}
                  variant="standard"
                  InputProps={{
                    disableUnderline: true,
                  }}
                  value={newChatMessage}
                  onChange={handleInputChatMessageChange}
                  onKeyDown={onChatKeyDown}
                  sx={{
                    flexGrow: 1,
                    fontSize: "14px",
                    border: "none"
                  }}
                />
                {/* 添加文件 */}
                {/* <FilePickerButton
                  usage={UsageType.CHAT}
                  onSendFailed={(message, isError) => { enqueueSnackbar(message, { variant: isError ? "error" : "info" }) }}
                  closeChat={false}
                  onFileSelected={refreshSendFileStatus}
                  onFileUploaded={refreshSendFileStatus}
                /> */}
              </Box>
              {/* 消息发送按钮 */}
              <Button size="large" variant="contained" sx={{
                textTransform: 'none', color: 'white', fontFamily: 'Helvetica Neue', fontStyle: 'normal', fontWeight: 400, fontSize: '14px',
                padding: '10px 17px', height: '40px', whiteSpace: 'nowrap', minWidth: '88px',
                background: 'linear-gradient(90deg, #60D6FF 0%, #3A92F9 100%)',
                mixBlendMode: 'normal',
                boxShadow: '0px 4px 6px rgba(96, 214, 255, 0.3)',
                borderRadius: '22px'
              }}
                onClick={() => handleClickSendChatMessage(newChatMessage)}
              >{t("app.button.send")}
              </Button>
            </Box>}

          </Box>

        </DialogContent>

      </Dialog>

      <Loader isLoading={isLoading} />
    </>
  )
}
export default ChatDialog;