import wx from "weixin-js-sdk";
import i18n from 'src/app/i18n';
import { getWeChatValidUrl } from "src/tools/utils/appUtil";


export function configWx(appId, timestamp, nonceStr, signature) {
  wx.config({
    debug: false,
    appId,
    timestamp,
    nonceStr,
    signature,
    jsApiList: [
      'updateAppMessageShareData',
      'updateTimelineShareData'
    ],
    openTagList: ["wx-open-launch-app"],
  });
  wx.ready(function () {
    console.log("wechat readied");
  });
  wx.error(function (res) {
    // console.log("wechat error:", res);
    console.error('wechat config error: ' + JSON.stringify(res))
  });
}


interface initWeChatShareOptions {
  title?: string,
  desc?: string,
  link?: string,
  imgUrl?: string,
}

export const initWeChatShare = (options?: initWeChatShareOptions) => {

  wx.ready(function () {
    const shareTitle = options?.title ? options.title : i18n.t('app.title');
    const shareDesc = options?.desc ? options.desc : i18n.t('app.desc');
    const shareLink = options?.link ? options.link : getWeChatValidUrl(window.location.href);
    const shareImgUrl = options?.imgUrl ? options.imgUrl : 'https://www.anylocal.cn/logo512.png';
    console.log('shareImgUrl:', shareImgUrl);
    // 自定义“分享给朋友”及“分享到QQ”按钮的分享内容
    wx.updateAppMessageShareData({
      title: shareTitle, // 分享标题
      desc: shareDesc, // 分享描述
      link: shareLink, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
      imgUrl: shareImgUrl, // 分享图标
      success: function () {
        // 设置成功
        console.log("wechat set success");
      },
    });
    // 自定义“分享到朋友圈”及“分享到QQ空间”按钮的分享内容
    wx.updateTimelineShareData({
      title: `${shareTitle}  ${options?.desc ? options.desc : ''}`, // 分享标题
      link: shareLink, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
      imgUrl: shareImgUrl, // 分享图标
      success: function () {
        // 设置成功
        console.log("wechat set success");
      },
    });
  });
};