import { createApi } from '@reduxjs/toolkit/query/react'
import { Response, Result, Value } from '../../models/common';
import { baseQueryWithReauth } from '../../tools/toolkit/helper';
import { OrderEntity } from "src/models/order";


interface getOrderResult {
    order_info: OrderEntity[];
    start_key: string;
}

export const orderApi = createApi({
    reducerPath: 'order',
    baseQuery: baseQueryWithReauth, 
    endpoints(builder) {
        return {
            getSellingOrder: builder.mutation<Result<getOrderResult>, {
                orderIds?: number[], 
                userId?: string, 
                pageSize?: number, 
                startKey?: string, 
                forwardOrder?: boolean
            }>({
                query: ({ orderIds, userId, pageSize, startKey, forwardOrder }) => ({
                    url: 'order',
                    method: 'POST',
                    body: {
                        request_type:"list_order",
                        request_data: {
                            user_id: userId,
                            order_ids: orderIds, 
                            pagesize: pageSize,
                            start_key: startKey,
                            forward_order: forwardOrder,
                        }
                    }
                }),
                transformResponse: (res: Response<getOrderResult>) => new Value<getOrderResult>(res)
            }),
            orderTracking: builder.mutation<Result<{}>, {
                orderId: number, 
                company: string, 
                trackingId: string, 
            }>({
                query: ({ orderId, company, trackingId}) => ({
                    url: 'order',
                    method: 'POST',
                    body: {
                        request_type:"order_tracking",
                        request_data: {
                            order_id: orderId,
                            tracking_id: trackingId, 
                            company: company,
                            tracking_url: ""
                        }
                    }
                }),
                transformResponse: (res: Response<{}>) => new Value<{}>(res)
            }),
        }
    }
})


export const { useGetSellingOrderMutation, useOrderTrackingMutation
} = orderApi