import i18n from 'src/app/i18n';
export interface GetCheckoutSessionResponse {
  session_id: string;
  client_id: string;
  product_info: PaymentProductInfo;
  // existed in app's response
  // payment_intent: {
  //   ephemeralKey: string,
  //   id: string,
  //   secret: string,
  // }
}

export interface PaidInfo {
  paid_id?: string;
  payment_type: number;
  membership_certificate?: MembershipCertificate;
  customer_id: string;
  cources?: string; //A list of cource ids separated by ;
  coupon?: string;
  reference_id?: string;
  amounts?: number[];
}

export interface MembershipCertificate {
  id: string;
  ending_date?: number;
  period_length?: number;
}

export enum PaymentMode {
  Payment = 1,
  Setup = 2,
  Subscription = 3,
}
export enum PaymentType {
  Stripe = 1,
  Stripe_Intent = 2,
  Stripe_Portal = 3,
  Apple_Receipt = 4,
  Certificate_Code = 5,
  Coin = 6,
}
export interface RequestCheckoutSession {
  paymenttype: PaymentType;
  price_id?: string;
  quantity?: number;
  mode?: PaymentMode;
  coupon?: string;
  allow_input_code?: boolean;
  success_url: string;
  cancel_url?: string;
  product_info?: PaymentProductInfo;
  description?: string;
}

export interface PaymentProductInfo {
  product_type?: string;
  product_id?: string;
  quantity?: number;
  paid_info?: PaidInfo;
  customer?: string;
  amount?: number;
}

export interface UserPaymentInfo {
  balances: string;
  rate_usd2_rmb: number;
  withdraw_enable: boolean;
  coin_balance: number;
  cash_balance: number;
  usd_balance: number;
  payout_account: boolean;
  payout_account_available: boolean;
}

export interface CheckoutOneTimeItem {
  product_name: string;
  description?: string;
  images?: string[];
  amount: number;
  quantity: number;
  currency: PaymentCurrency;
}

export enum Product_Type {
  Request = "request",
  Reward = "reward",
  Recharge = "recharge",
}

export enum PaymentCurrency {
  USD = 'USD',
  CNY = 'CNY',
  COIN = 'COIN'
}
export interface TransferInfo {
  account: string;
  amount: number;
  currency: PaymentCurrency;
  group_info: string;
  paymenttype: PaymentType;
  notes?: string;
}
export interface PaymentInfo {
  token: string;
  amount: number; //cent
  description: string;
  currency: PaymentCurrency;
  paymenttype: PaymentType;
  transferinfo?: TransferInfo;
}
export interface SessionInfo {
  paymenttype: PaymentType;
  session_id: string;
}

export enum PaymentStatus {
  Succeeded = 1,
  Pending = 2,
  failed = 3,
}
export interface MakePayment {
  payment_request_id: string;
  payment_info?: PaymentInfo; //Payment by Token
  session_info?: SessionInfo; //Payment by CheckoutSesssion
  price_id?: string;
  payment_paid_id?: string;
  payment_status?: PaymentStatus;
  product_info: PaymentProductInfo;
}

export enum PaymentOperationEnum {
  ResponseIsAdopted = 1,
  PayForRequest = 2,
  RewardOtherPost = 3,
  MyPostIsReward = 4,
  BuySomePost = 5,
  SellYourPost = 6,
  RechargeAnylocalCoins = 7,
  Withdraw = 8,
  Refund = 9,
  SystemReward = 10,
  SystemDeduction = 11,
  RewardToPerson = 12,
  ReceivePersonReward = 13,
  ConvertCashCoinToCoin = 14,
  OfficialApproveWithdrawal = 15,
  OfficialRejectWithdrawal = 16,
  WithdrawalReject = 17,
}

export enum PaymentStatusEnum {
  Pending = 0,
  Success = 1,
  ServerError = 2,
  Failed = 4,
}
export interface PaymentHistory {
  amount: number;
  balance: number;
  currency: string;
  operation: PaymentOperationEnum;
  payment_channel: string; //"Stripe"
  payment_id: string;
  request_id: string;
  request_user_id: string;
  response_user_id: string;
  service_fee: number;
  timestamp: number;
  transfer_id: string;
  transfer_status: PaymentStatusEnum;
  message_type: number;
  message_id: string;
  payer: {
    user_avatar: string;
    user_id: string;
    user_name: string;
  },
  receiver: {
    user_avatar: string;
    user_id: string;
    user_name: string;
  },
  is_plus: boolean;
  primary_key: string;
  sub_operation?: string;
}


const operationTextList = {
  [PaymentOperationEnum.ResponseIsAdopted]: "Response is accepted",
  [PaymentOperationEnum.PayForRequest]: "Pay for request",
  [PaymentOperationEnum.RewardOtherPost]: "Reward other post",
  [PaymentOperationEnum.MyPostIsReward]: "My post is rewarded",
  [PaymentOperationEnum.BuySomePost]: "Buy some post",
  [PaymentOperationEnum.SellYourPost]: "Sell your post",
  [PaymentOperationEnum.RechargeAnylocalCoins]: "Recharge Anylocal coins",
  [PaymentOperationEnum.Withdraw]: "Withdraw",
  [PaymentOperationEnum.Refund]: "Refund",
  [PaymentOperationEnum.SystemReward]: "System reward",
  [PaymentOperationEnum.SystemDeduction]: "System deduction",
  [PaymentOperationEnum.RewardToPerson]: "Reward to person",
  [PaymentOperationEnum.ReceivePersonReward]: "Receive person reward",
}

export const getOperationText = (payment: PaymentHistory): string => {
  return operationTextList[payment.operation] ?? "";
};


const paymentStatusTextList = {
  [PaymentStatusEnum.Pending]: "pending",
  [PaymentStatusEnum.Success]: "success",
  [PaymentStatusEnum.ServerError]: "error",
  [PaymentStatusEnum.Failed]: "failed",
};


export const getPaymentStatusText = (payment: PaymentHistory): string => {
  return paymentStatusTextList[payment.transfer_status] ?? "unknown";
};

// 
//     "1": "响应已被采纳", + 现金
//       "2": "支付需求", -随
//       "3": "奖励其它帖子”,-随
//       "4": "我的帖子已被奖励", +现金
//       "5": "购买别人的帖子”,-随
//       "6": "售卖我的帖子", +现金
//       "7": "充值随迹币”,+随
//       "8": "提现”,-随
//       "9": "退款”,+随
//       "10": "系统奖励”,+现金
//       "11": "系统扣款”,-现金
//       "12": "打赏了别人”,-随
//       "13": "被人打赏了”+现金
//    

export const coinType = (row: PaymentHistory): string => {
  const coinsList = [2, 3, 5, 7, 9, 12]
  if (coinsList.includes(row?.operation)) {
    return ` ${i18n.t('app.wallet.coins')}`;
  } else {
    return ` ${i18n.t('app.wallet.cashCoins')}`;
  }
}