import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { Response, Result, Value } from '../../models/common';
import { queryBuilder } from "src/tools/toolkit/helper";

export const uploadApi = createApi({
  reducerPath: 'upload-file',
  baseQuery: fetchBaseQuery(queryBuilder(process.env.REACT_APP_UPLOAD_FILE_API_URL)), 
  endpoints(builder) {
    return {
      uploadFile: builder.mutation<Result<any>, {
        user_id: string,
        file_name: string
        file_type: string,
        hash: any,
      }>({
        query: ({file_name, file_type, user_id, hash }) => ({
          url: `uploadfile`,
          method: 'POST',
          body: {
            file_name,
            file_type,
            user_id,
            hash,
            }
        }),
        transformResponse: (res: Response<any>) => new Value(res)
      }),
    }
  }
})

export const { useUploadFileMutation } = uploadApi