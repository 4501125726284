import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";

const ConfirmButton = styled(Button)({
    borderRadius: "20px",
    height: "40px",
    minWidth: "88px",
    padding: "10px 19px",
    color: "#fff",
    background: "linear-gradient(90deg, #60D6FF 0%, #3A92F9 100%)",
    textAlign: "center",
    fontSize: "14px",
    textTransform: "none",
    "&:hover": {
        background: "linear-gradient(90deg, #60D6FF 0%, #3A92F9 80%)",
    }
})

export default ConfirmButton;