import { useEffect, useRef } from 'react';
import { WX_APP_OPEN_ID } from "src/tools/utils/appUtil";
import { getAppDownloadUrl } from 'src/layout/openApp/openApp';
import { CollectDownloadAction } from 'src/hooks/landing/baidu';

interface Props {
  redirectURL: string
}

export const WXOpenLaunchAppCover = ({ redirectURL }: Props) => {
  let extinfo = redirectURL;

  const wxRef = useRef(null);
  useEffect(() => {
    // @ts-ignore
    wxRef.current?.addEventListener('launch', function (e: any) {
      console.log('launch success');
    });
    // @ts-ignore
    wxRef.current?.addEventListener('error', function (e) {
      console.error('error', JSON.stringify(e));
      CollectDownloadAction(() => {
        window.location.href = getAppDownloadUrl();
      });
      // enqueueSnackbar('Please check if Anylocal app is installed', { variant: 'warning' });
    });
    // @ts-ignore
    // wxRef.current?.addEventListener('ready', function (e) {
    //   console.log('ready', e.detail);
    // });
  }, [])

  return (
    <>
      {/*  @ts-ignore */}
      <wx-open-launch-app
        style={{ width: '100%', height: '100%', display: 'block', position: 'absolute', top: 0, left: 0, zIndex: 99 }}
        ref={wxRef}
        appid={WX_APP_OPEN_ID}

        // 支持打开app后跳转到指定的页面
        // https://developers.weixin.qq.com/doc/offiaccount/OA_Web_Apps/Wechat_Open_Tag.html#%E8%B7%B3%E8%BD%ACAPP%EF%BC%9Awx-open-launch-app
        extinfo={extinfo}
      >
        <script type="text/wxtag-template">
          <div style={{ position: 'absolute', width: '100%', height: '100%', opacity: 0, zIndex: 1 }}></div>
        </script>
        {/*  @ts-ignore */}
      </wx-open-launch-app>
    </>
  )
}