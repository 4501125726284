import { createApi } from "@reduxjs/toolkit/query/react";
import { Response, Result, Value } from "../../models/common";
import { MessageTypeEnum, ReportContentTypeEnum } from "../../models/post";
import { FootprintsRequestData } from "../../models/user";
import { baseQueryWithReauth } from "../../tools/toolkit/helper";
import { ReportCategoryItem } from "src/tools/utils/appUtil";
import { RequestEntity, ResponseStatusEnum, RequestStatusEnum } from "src/models/request";

export interface ResponseListRes {
  requests: RequestEntity[];
  startkey: string;
}

export const userApi = createApi({
  reducerPath: "user",
  baseQuery: baseQueryWithReauth,
  endpoints(builder) {
    return {
      getMessageFromUser: builder.mutation<
        Result<any>,
        {
          messageType: MessageTypeEnum;
          userId: string;
          pageSize?: number;
          startKey?: string;
          forwardOrder?: boolean;
          type?: number;
          startTime?: number;
          endTime?: number;
          locId?: string;
          status?: RequestStatusEnum
        }
      >({
        query: ({
          messageType,
          userId,
          pageSize,
          startKey,
          forwardOrder,
          type,
          startTime,
          endTime,
          locId,
          status
        }) => ({
          url: `getmessagefromuser`,
          method: "POST",
          body: {
            message_type: messageType,
            user_id: userId,
            pagesize: pageSize || 20,
            startkey: startKey,
            forwardorder: forwardOrder,
            type: type,
            followeduser: false,
            start_time: startTime,
            end_time: endTime,
            loc_id: locId,
            status
          },
        }),
        transformResponse: (res: Response<any>) => new Value(res),
      }),

      getUserResponses: builder.mutation<
        Result<ResponseListRes>,
        {
          pageSize?: number;
          startKey?: string;
          forwardOrder?: boolean;
          status?: ResponseStatusEnum
        }
      >({
        query: ({ pageSize, startKey, forwardOrder, status }) => ({
          url: `getrespondedrequest`,
          method: "POST",
          body: {
            type: "response",
            pagesize: pageSize || 20,
            startkey: startKey,
            forwardorder: forwardOrder,
            status
          },
        }),
        transformResponse: (res: Response<ResponseListRes>) => new Value(res),
      }),

      getFootprints: builder.mutation<
        Result<any>,
        { requestType: string; requestData: FootprintsRequestData }
      >({
        query: ({ requestType, requestData }) => ({
          url: `footprints`,
          method: "POST",
          body: {
            request_type: requestType,
            request_data: requestData,
          },
        }),
        transformResponse: (res: Response<any>) => new Value(res),
      }),

      getTimePostList: builder.mutation<
        Result<any>,
        {
          type: number;
          timezone: string;
          user_id: string;
          pagesize: number;
          forwardorder?: false;
          year?: number;
          month?: number;
          day?: number;
          startkey?: string;
        }
      >({
        query: ({
          type,
          timezone,
          user_id,
          pagesize,
          forwardorder,
          year,
          month,
          startkey
        }) => ({
          url: `timeline`,
          method: "POST",
          body: {
            type,
            timezone,
            user_id,
            pagesize,
            forwardorder,
            year,
            month,
            startkey
          },
        }),
        transformResponse: (res: Response<any>) => new Value(res),
      }),

      updateUserProfile: builder.mutation<
        Result<any>,
        {
          user_name?: string;
          personalized_signature?: string;
          user_avatar?: string;
          gender?: string;
          birthday?: string;
          user_cover?: string;
        }
      >({
        query: ({
          user_name,
          personalized_signature,
          user_avatar,
          gender,
          birthday,
          user_cover,
        }) => ({
          url: `uploaduserprofile`,
          method: "POST",
          body: {
            user_name,
            personalized_signature,
            user_avatar,
            gender,
            birthday,
            user_cover,
          },
        }),
        transformResponse: (res: Response<any>) => new Value(res),
      }),

      SendVerifyCode: builder.mutation<
        Result<any>,
        {
          auth_address: string;
          // verify_code: string
        }
      >({
        query: ({ auth_address }) => ({
          url: `deleteuser`,
          method: "POST",
          body: {
            auth_address,
          },
        }),
        transformResponse: (res: Response<any>) => new Value(res),
      }),

      DeleteUser: builder.mutation<
        Result<any>,
        {
          verify_code: string;
          auth_address: string;
        }
      >({
        query: ({ verify_code, auth_address }) => ({
          url: `deleteuser`,
          method: "POST",
          body: {
            verify_code,
            auth_address,
          },
        }),
        transformResponse: (res: Response<any>) => new Value(res),
      }),

      reportContent: builder.mutation<
        Result<any>,
        {
          content_id: string;
          content_type: ReportContentTypeEnum;
          category: ReportCategoryItem;
          reason: string;
        }
      >({
        query: ({ content_id, content_type, category, reason }) => ({
          url: `report`,
          method: "POST",
          body: {
            content_id,
            content_type,
            category,
            reason,
          },
        }),
        transformResponse: (res: Response<any>) => new Value(res),
      }),
      getUserPurchased: builder.mutation<
        Result<any>,
        {
          pageSize?: number;
          startKey?: string;
        }
      >({
        query: ({ pageSize, startKey }) => ({
          url: `user_purchased`,
          method: "POST",
          body: {
            pagesize: pageSize,
            startkey: startKey
          },
        }),
        transformResponse: (res: Response<any>) => new Value(res),
      }),
      getUserResponse: builder.mutation<
        Result<any>,
        {
          pageSize?: number;
          startKey?: string;
        }
      >({
        query: ({ pageSize, startKey }) => ({
          url: `getrespondedrequest`,
          method: "POST",
          body: {
            pagesize: pageSize,
            startkey: startKey,
            type:"request"
          },
        }),
        transformResponse: (res: Response<any>) => new Value(res),
      }),
    };
  },
});

export const {
  useGetMessageFromUserMutation,
  useGetUserResponsesMutation,
  useGetFootprintsMutation,
  useGetTimePostListMutation,
  useUpdateUserProfileMutation,
  useSendVerifyCodeMutation,
  useDeleteUserMutation,
  useReportContentMutation,
  useGetUserPurchasedMutation,
  useGetUserResponseMutation
} = userApi;
