import * as cryptoJS from 'crypto-js'

export class settings {
  private static get __secrect_key() {
    return 'nimiah@k3y'
  }

  // gets all keys
  static get keys() {
    return {
      app: 'app-state',
      accessToken: 'app-at',
      refreshToken: 'app-refresh-at',
      location: 'app-location',
      video: 'video-state',
      refreshList: 'app-refresh-list',
      mapLoad: 'map-load',
      userPageInfo: 'user-page-info',
      search: "app-search",
      promotion: "promotion",
      publicKey:"app-public_key"
    }
  }

  static loadState(key: string) {
    const s = sessionStorage.getItem(key)
    if (s) {
      const bytes = cryptoJS.AES.decrypt(s, settings.__secrect_key)
      return JSON.parse(bytes.toString(cryptoJS.enc.Utf8))
    }
    return undefined
  }

  static saveState(key: string, state?: any) {
    if (Boolean(state)) {
      const s = JSON.stringify(state)
      sessionStorage.setItem(key, cryptoJS.AES.encrypt(s, settings.__secrect_key).toString())
    } else {
      sessionStorage.removeItem(key)
    }
  }
}
