
export const userPagePath = {
  post: "",
  travel: 'travel',
  request: 'request',
  saveList: 'save-list',
  purchased: 'purchased',
  followers: 'followers',
  followingPeople: 'following-people',
  friends: 'friends',
  followingLocations: 'following-locations'
}