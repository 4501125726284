import { Box, CircularProgress, ListItem } from "@mui/material";
import { FC, useCallback, useEffect, useState } from "react";
import SvgComponent from "../../svgComponent";
import { useGetNotificationTypeListMutation } from "src/service/api/notification-api";
import { TravelNotification } from "src/models/notification";
import { enqueueSnackbar } from "notistack";
import { appConfig } from "src/app-config";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import NotificationListItem from "../NotificationListItem";

interface Props {
  handleReadNotification: (id: string) => void;
  isChineseLanguage: boolean;
  handleIsLoading: (isLoading: boolean) => void;
}

const TravelsTab: FC<Props> = ({
  handleReadNotification,
  isChineseLanguage,
  handleIsLoading,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [travelList, setTravelList] = useState<TravelNotification[]>([]);
  const [getNotificationTypeList, { isLoading: typeListLoading }] =
    useGetNotificationTypeListMutation();
  const handleLoadTravelList = useCallback(() => {
    getNotificationTypeList({ type: ["travel"] })
      .unwrap()
      .then((res) => {
        if (res && !res.isFailed && res.result) {
          const dataList = res.result.notification_list;
          setTravelList(dataList);
        } else {
          enqueueSnackbar("Failed to load travels list");
        }
      });
  }, []);
  useEffect(() => {
    handleLoadTravelList();
  }, []);

  const handleClickTravel = (item: TravelNotification) => {
    navigate(`${appConfig.paths.travel}/detail?id=${item?.message?.travel_id}`);
    handleReadNotification(item?.notification_id);
  };
  return (
    <>
      <Box
        sx={{
          bgcolor: "background.paper",
          padding: "0 10px",
          whiteSpace: "no-wrap",
          maxHeight: "525px",
          overflow: "auto",
        }}
      >
        {typeListLoading ? (
          <ListItem sx={{ justifyContent: "center" }}>
            <CircularProgress sx={{ marginTop: "20px" }} />
          </ListItem>
        ) : (
          <>
            {travelList.length > 0 ? (
              <>
                {travelList.map((item) => (
                  <NotificationListItem
                    key={item.notification_id}
                    notificationInfo={item}
                    isChineseLanguage={isChineseLanguage}
                    handleClick={() => {
                      handleClickTravel(item);
                    }}
                    reloadList={handleLoadTravelList}
                  />
                ))}
              </>
            ) : (
              <ListItem sx={{ justifyContent: "center", height: "230px" }}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <SvgComponent
                    iconName={"ico-no-results"}
                    style={{ width: "48px", height: "49px" }}
                  />
                  <Box
                    sx={{
                      fontFamily: "SF Pro Text",
                      fontSize: "14px",
                      fontWeight: 500,
                      lineHeight: "16.71px",
                      color: "#717171",
                      marginTop: "10px",
                    }}
                  >
                    {t("app.alert.no-data")}
                  </Box>
                </Box>
              </ListItem>
            )}
          </>
        )}
      </Box>
    </>
  );
};

export default TravelsTab;
