import { fetchBaseQuery, BaseQueryFn, FetchArgs, FetchBaseQueryError, BaseQueryApi } from '@reduxjs/toolkit/query/react'
// import { getStoredAccessToken, storeAccessToken } from 'src/utils/localStorage'
// import { showLogoutDialog } from '../service/signinup-slice'
// import { getFormatDisplayTime } from 'src/utils/timeUtil'
// import  Axios, { AxiosError, AxiosResponse } from 'axios';
import { ThunkDispatch } from '@reduxjs/toolkit'
// import { getFormatDisplayTime } from '../utils/timeUtil'
import { decodeJWT } from '../utils/auth'
import { RootState } from './store';
import { signInRefreshToken } from '../utils/refreshToken';
import { checkResponse } from '../utils/api';
import { defaultLanguage } from '../../models/locale';
import { setRefreshToken, setToken } from '../../service/redux/access-slice';
import { logout } from '../utils/store';
import { getToken, getRefreshToken } from "src/tools/utils/storage"
import { fetchWithErrorHandling, ErrorType, ErrorSubType } from "src/tools/utils/reportError";

const TOKEN_REFRESH_WHITE_LIST_APIS = [
  'signinpw',
  'thirdpartylogin',
  'signupcheckname',
  'signup',
  'signupverifycode',
  'getuserprofile',
  'signin',
  'forgetpassword',
];

export const queryBuilder = (path?: string) => ({
  baseUrl: path ?? process.env.REACT_APP_API_URL,
  prepareHeaders(headers: any, { getState }: any) {
    const { app } = getState() as RootState
    const token = getToken() ?? '';
    if (Boolean(token)) {
      // const localToken = localStorage.getItem(settings.keys.accessToken);
      // let token=tokenRes.token;
      // if (localToken && localToken !== tokenRes.token) {
      //   // setToken(localToken);
      //   token=localToken;
      // }
      headers.set('Authorization', `${token}`)
      // headers.set('Authorization', `${token}`)
    }
    // headers.set('Authorization', `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyaWQiOiJ3dWNoZW4iLCJ1c2VybmFtZSI6IiIsInVzZXJhdmF0YXIiOiIiLCJncHMiOiIzNC4zMjA2NTMsMTA4Ljc3ODI5MyIsImFjY3VyYWN5IjoxLCJpc19hbm9ueW1vdXMiOmZhbHNlLCJ1c2VyX3ByaXZpbGVnZSI6MiwiaWF0IjoxNjg0NDg0MTI3LCJleHAiOjE2ODQ0ODc3Mjd9.PIC7pBcSfWDMokyfWf3-TT_gNtuZSQtLN_OtzFuy4ww`)
    headers.set('Content-Type', 'application/json')
    headers.set('Accept', 'application/json')
    headers.set('Accept-Language', app.language ?? defaultLanguage)
    headers.set('Client-Type', 'web')
    headers.set('Client-Version', '0.1.0')
    return headers
  }
})

const baseQuery = fetchBaseQuery(queryBuilder());
let requestRefreshTokenPromise: null | Promise<void> = null;
export const baseQueryWithReauth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions
) => {
  // const { tokenRes } = api.getState() as RootState
  const token = getToken() ?? '';
  const refreshToken = getRefreshToken() ?? '';
  if (!token) {
    return queryWithReportError(args, api, extraOptions);
  }
  const tokenData = decodeJWT(token);
  const currentTimeSeconds = new Date().getTime() / 1000;
  // console.log("old token expire time: ", getFormatDisplayTime(tokenData?.exp * 1000))
  if (tokenData && tokenData.exp > currentTimeSeconds) {
    // if (tokenData.exp - currentTimeSeconds > 59*60) {
    return queryWithReportError(args, api, extraOptions);
  }
  if (TOKEN_REFRESH_WHITE_LIST_APIS.includes((args as any).url)) {
    // console.log("This api doesn't need token refresh: ", (args as any).url)
    return queryWithReportError(args, api, extraOptions);
  }

  if (tokenData && tokenData.userid && refreshToken) {
    if (!requestRefreshTokenPromise) {
      requestRefreshTokenPromise = requestRefreshToken(api.dispatch, tokenData.userid, refreshToken);
    }
    await requestRefreshTokenPromise;
    requestRefreshTokenPromise = null;
  }
  return queryWithReportError(args, api, extraOptions);
};

function isValidToken(token) {
  return typeof token === 'string' && token.length > 0;
}

const requestRefreshToken = async (dispatch: ThunkDispatch<any, any, any>, userId: string, refreshToken: string | null | undefined) => {
  if (refreshToken) {
    const refreshTokenData = decodeJWT(refreshToken);
    const currentTimeSeconds = new Date().getTime() / 1000;
    if (refreshTokenData.exp < currentTimeSeconds) {
      logout();
      return;
    }
  } else {
    logout();
    return;
  }
  const response = await signInRefreshToken(userId, refreshToken);
  if (response && checkResponse(response)) {
    const { token, refresh_token } = response.data?.data;
    if (!response.data?.data || !isValidToken(token) || !isValidToken(refresh_token)) {
      logout();
      return;
    }
    dispatch(setToken(token));
    dispatch(setRefreshToken(refresh_token));
  } else {
    logout();
  }
}
const queryWithReportError = async (args: string | FetchArgs, api: BaseQueryApi, extraOptions: {}, baseUrl?: string) => {

  let query = baseQuery;
  if (baseUrl) {
    query = fetchBaseQuery(queryBuilder(baseUrl))
  }
  const startTime = performance.now();
  const res = await query(args, api, extraOptions);

  const endTime = performance.now();


  const duration = endTime - startTime;

  if (res.error || !res.data || res.meta?.response?.status !== 200 || ((res.data as any).result && (res.data as any).result.code !== 201000)) {
    try {
      let url = '';
      const request = res.meta?.request;
      const response = res.meta?.response;
      const description = {
        request: {},
        response: {},
        userAgent: navigator.userAgent,
        duration
      }
      if (request) {
        let bodyText = '';
        if (typeof args === 'object') {
          bodyText = args.body
        }

        if (bodyText === '' && !request.bodyUsed) {
          bodyText = await request.json();
        }

        const headers = {}
        request.headers.forEach((value, name) => {
          headers[name] = value;
        });

        if (request.url) {
          url = request.url;
        }

        const requestInfo = {
          method: request.method,
          url: request.url,
          headers: headers,
          body: bodyText || null, // 如果 body 为空则返回 null
          credentials: request.credentials,
          mode: request.mode,
          cache: request.cache,
          redirect: request.redirect,
          referrer: request.referrer,
          referrerPolicy: request.referrerPolicy,
          integrity: request.integrity,
        };

        description.request = requestInfo;

      }

      if (response) {
        const headers = {};
        response.headers.forEach((value, name) => {
          headers[name] = value;
        });

        if (response.url && url === '') {
          url = response.url;
        }

        const responseInfo = {
          status: response.status,
          statusText: response.statusText,
          url: response.url,
          headers: headers,
          body: res.data || null, // 如果 body 为空则返回 null
          ok: response.ok,
          redirected: response.redirected,
          type: response.type,
          bodyUsed: response.bodyUsed,
        };
        description.response = responseInfo;
      }


      const content = res.error || (res.data as any).result;
      fetchWithErrorHandling({
        url,
        type: ErrorType.ERROR,
        sub_type: ErrorSubType.SERVER_ERROR,
        content: JSON.stringify(content),
        description: JSON.stringify(description),
      })
    } catch (e) {
      console.error('fetchWithErrorHandling:', e);
    }
  }

  if ((res.meta?.response?.status === 200 && res.data && (res.data as any).result?.result_code === 401) || res.meta?.response?.status === 401) {
    // console.log("api return 401 token expired, begin to refresh token...")
    // const { tokenRes } = api.getState() as RootState;
    const token = getToken() ?? '';
    const refreshToken = getRefreshToken() ?? '';
    const tokenData = decodeJWT(token);
    if (tokenData && tokenData.userid && refreshToken) {
      await requestRefreshToken(api.dispatch, tokenData.userid, refreshToken);
      // console.log("api 401 token refresh finished, request again...")
      return await query(args, api, extraOptions);
    } else {
      logout();
    }
  }
  return res;
}
export const buildQueryWithReAuth = (baseUrl?: string) => {
  let adminRequestRefreshTokenPromise: null | Promise<void> = null;
  const baseQueryWithReAuth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
    args,
    api,
    extraOptions
  ) => {
    // const { tokenRes } = api.getState() as RootState
    const token = getToken() ?? '';
    const refreshToken = getRefreshToken() ?? '';
    if (!token) {
      return queryWithReportError(args, api, extraOptions, baseUrl);
    }
    const tokenData = decodeJWT(token);
    const currentTimeSeconds = new Date().getTime() / 1000;
    // console.log("old token expire time: ", getFormatDisplayTime(tokenData?.exp * 1000))
    if (tokenData && tokenData.exp > currentTimeSeconds) {
      // if (tokenData.exp - currentTimeSeconds > 59*60) {
      return queryWithReportError(args, api, extraOptions, baseUrl);
    }
    if (TOKEN_REFRESH_WHITE_LIST_APIS.includes((args as any).url)) {
      // console.log("This api doesn't need token refresh: ", (args as any).url)
      return queryWithReportError(args, api, extraOptions, baseUrl);
    }
    if (!adminRequestRefreshTokenPromise && tokenData?.userid && refreshToken) {
      adminRequestRefreshTokenPromise = requestRefreshToken(api.dispatch, tokenData.userid, refreshToken);
    }
    await adminRequestRefreshTokenPromise;
    adminRequestRefreshTokenPromise = null;
    return queryWithReportError(args, api, extraOptions, baseUrl);
  };
  return baseQueryWithReAuth;
}

/*
export const reportError = async (entity: ErrorReportEntity): Promise<AxiosResponse<LoginUserResponse> | void> => {
  const success = async (): Promise<AxiosResponse<LoginUserResponse>> => {
      const response = await Axios.create({
          baseURL: process.env.REACT_APP_API_URL,
          headers: {
              'Content-Type': 'application/json',
              'authorization': getStoredAccessToken(),
          },
        }).post<LoginUserResponse>('/tracking', {
          request_type: "create_tracking",
          request_data: {
            error_code: entity.error_code,
            error_message: entity.error_message,
            browser_type: getExploreName(),
            modules: entity.modules,
            request_url: entity.request_url,
            request_action: entity.request_action,
            request_params: entity.request_params,
          }
      });
      return response
  };

  const error = (e: AxiosError): AxiosResponse | void => {
      return e.response;
  };

  return apiWrapper<LoginUserResponse>({
      success,
      error,
  });
};
*/