import * as React from 'react';
import { ReactElement, ReactNode } from 'react';
import { Backdrop, Box, CircularProgress, Typography } from "@mui/material";


export interface LoaderProps {
  children?: ReactElement | ReactNode;
  isLoading?: boolean;
  zIndex?: number;
  showLoadingText?: boolean;
}

export default function Loader(LoaderProps): ReactElement {
  const { isLoading, zIndex, showLoadingText } = LoaderProps;

  return (
    <>
      <Backdrop
        sx={{
          zIndex: zIndex ?? 1000,
          color: "blue",
          backgroundColor: "rgba(255,255,255,0.2)",
        }}
        open={!!(isLoading)}
      >
        <Box justifyContent="center" flexWrap="nowrap">
          <Box
            display="flex"
            justifyContent="center"
          >
            <CircularProgress />
          </Box>
          {showLoadingText &&
            <Typography>Loading</Typography>
          }
        </Box>
      </Backdrop>
    </>
  );
}
