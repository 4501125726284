import { Avatar, Badge, Box, CircularProgress, Divider, ListItem, ListItemAvatar, Tab, Tabs, useTheme } from "@mui/material";
import { FC, useCallback, useEffect, useState } from "react";
import SvgComponent from "../../svgComponent";
import { styled } from '@mui/system'
import DefaultPicture from '../../images/defaultIcons/default-pic-post.png'
import { useGetNotificationTypeListMutation, useReadNotificationMutation, useReadNotificationTypeMutation, useGetNotificationMessageListMutation, useGetNotificationListMutation } from 'src/service/api/notification-api';
import { FollowerNotification, LastNotificationTime, MessageNotification, NotificationTypeEnum, PostNotification, RequestNotification } from "src/models/notification";
import { useSelector } from "src/tools/toolkit/store";
import { enqueueSnackbar } from "notistack";
import { appConfig } from "src/app-config";
import { howLongAgo, isJSON } from "src/tools/utils/stringUtil";
import { showSignInUpDialog } from "src/service/redux/signinup-slice";
import { useDispatch } from "react-redux";
import { TabPanel } from "src/components/tab/tab";
import { useGetUserNewsListMutation } from "src/service/api/news-api";
import { NewsEntity } from "src/models/news";
import { useNavigate } from "react-router-dom";
import NewsNotificationListItem from "../NewsNotificationListItem";
import ArticleIcon from '@mui/icons-material/Article';
import WalletIcon from '@mui/icons-material/Wallet';
import { Base64 } from "js-base64";
import { MessageTypeEnum } from "src/models/post";
import { useTranslation } from "react-i18next";
import IcoDelete from "src/images/header/ico-delete.svg";

const PAGE_SIZE = 20;

const NewsTab = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [newsList, setNewsList] = useState<NewsEntity[]>([]);
  const [getUserNewsList, { isLoading: loadingNews }] = useGetUserNewsListMutation();

  const handleClickNews = (item: NewsEntity) => {
    navigate(`${appConfig.paths.newsDetail}?id=${Base64.encode(item.announcement_id)}`);
  }

  const handleLoadNewsList = useCallback(async () => {
    getUserNewsList({ pageSize: PAGE_SIZE, startKey: "" })
      .unwrap()
      .then(res => {
        if (res && !res.isFailed && res.result) {
          const announcements = res.result.announcements;
          if (announcements) {
            setNewsList(announcements);
          } else {
            setNewsList([]);
          }
        } else {
          setNewsList([]);

          enqueueSnackbar(res?.message || "Search failed", { variant: "error" });
        }
      })
  }, [])

  useEffect(() => {
    handleLoadNewsList();
  }, [])

  return (
    <Box sx={{ bgcolor: 'background.paper', padding: "10px 10px", maxHeight: "525px", overflow: "auto", }}>
      {loadingNews ?
        <ListItem sx={{ justifyContent: "center" }}>
          <CircularProgress sx={{ marginTop: '20px' }} />
        </ListItem>
        :
        <>
          {newsList.length > 0 ?
            <>
              {newsList.map((item) => (
                <NewsNotificationListItem key={item.announcement_id} news={item}
                  handleClick={() => { handleClickNews(item); }} />
              ))}
            </>
            :
            <ListItem sx={{ justifyContent: "center", height: '230px' }}>
              <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                <SvgComponent iconName={'ico-no-results'} style={{ width: '48px', height: '49px' }} />
                <Box sx={{ fontFamily: "SF Pro Text", fontSize: "14px", fontWeight: 500, lineHeight: "16.71px", color: "#717171", marginTop: "10px" }}>{t("app.alert.no-data")}</Box>
              </Box>
            </ListItem>
          }
        </>
      }
    </Box>
  )
}

export default NewsTab;