import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { settings } from "../settings";



interface RefreshFunctionInfo {
  pathname: string;
  refreshFunction: () => void;
}

interface RefreshList {
  refreshFunction: null | (() => void);
  refreshFunctionRecord: Record<string, () => void>;
}

export function _loadState(): RefreshList {
  return {
    refreshFunction: null,
    refreshFunctionRecord: {}
  }
}

const refreshList = createSlice({
  name: settings.keys.refreshList,
  initialState: _loadState(),
  reducers: {
    setRefreshFunction(state, action: PayloadAction<RefreshFunctionInfo>) {
      state.refreshFunctionRecord[action.payload.pathname] = action.payload.refreshFunction;
    },
    delRefreshFunction(state) {
      state.refreshFunction = null;
    },
  }
});


export const buildRefreshFunctionInfo = (refreshFunction: () => void) => {
  return {
    pathname: window.location.pathname,
    refreshFunction
  }
}



export const {
  setRefreshFunction,
  delRefreshFunction
} = refreshList.actions;
export default refreshList.reducer;