import { createTheme } from "@mui/material/styles";

// define theme
export const theme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 960,
            lg: 1440,
            xl: 2560
        }
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: `
                body {
                    line-height: 1;
                }
            `
        }
    }
});
