import { createApi } from "@reduxjs/toolkit/query/react";
import { Response, Result, Value } from "../../models/common";
import { baseQueryWithReauth } from "../../tools/toolkit/helper";

export const signUpApi = createApi({
  reducerPath: "signUp",
  baseQuery: baseQueryWithReauth,
  endpoints(builder) {
    return {
      checkUserID: builder.mutation<Result<any>, { userId: string }>({
        query: ({ userId }) => ({
          url: `signupcheckname`,
          method: "POST",
          body: { user_id: userId },
        }),
        transformResponse: (res: Response<any>) => new Value(res),
      }),
      signUpSendCode: builder.mutation<
        Result<any>,
        {
          userId: string;
          uuid: string;
          pubkey?: string;
          signature: string;
          clientSalt: string;
          passwordHashInfo: string;
          phone?: string;
          email?: string;
        }
      >({
        query: ({
          userId,
          uuid,
          pubkey,
          signature,
          clientSalt,
          passwordHashInfo,
          phone,
          email,
        }) => ({
          url: `signup`,
          method: "POST",
          body: {
            user_id: userId,
            reserved_name_id: uuid,
            public_key: pubkey,
            signature: signature,
            client_salt: clientSalt,
            password_hash: passwordHashInfo,
            phone,
            email,
            // gps: "",
            client_type: 3,
          },
        }),
        transformResponse: (res: Response<any>) => new Value(res),
      }),
      signUpVerifyCode: builder.mutation<
        Result<any>,
        { userId: string; verifyCode: string }
      >({
        query: ({ userId, verifyCode }) => ({
          url: `signupverifycode`,
          method: "POST",
          body: {
            user_id: userId,
            code: verifyCode,
            client_type: 3,
          },
        }),
        transformResponse: (res: Response<any>) => new Value(res),
      }),
    };
  },
});

export const {
  useCheckUserIDMutation,
  useSignUpSendCodeMutation,
  useSignUpVerifyCodeMutation,
} = signUpApi;
