import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import HttpApi from 'i18next-http-backend'
import { defaultLanguage, defaultLanguages } from '../models/locale'

i18n
  // learn more: https://github.com/i18next/i18next-xhr-backend
  .use(HttpApi)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    fallbackLng: defaultLanguage,
    supportedLngs: defaultLanguages.map(l => l.lang),
    interpolation: {
      escapeValue: false // react already safes from xss
    },
    backend: {
      requestOptions: {
        cache: "no-cache"
      }
    }
  })

export default i18n
