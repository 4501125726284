import { Box, Divider, Dialog, DialogTitle, DialogContent, type Theme, Button, CircularProgress } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { makeStyles } from "tss-react/mui";
import ConfirmButton from "src/components/styled/ConfirmButton";
import CancelButton from "src/components/styled/CancelButton";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles()((theme) => {
  return {
    dialogContent: {
      width: "540px",
      boxSizing: "border-box",
      paddingTop: "20px",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        height: "100%",
      },
      display: "flex",
      flexDirection: "column"
    },
    contentTitle: {
      minWidth: "80px",
      margin: "10px 0px 30px",
      [theme.breakpoints.down("sm")]: {
        minWidth: "50px"
      }
    },
    actionBoxRight: {
      display: "flex",
      justifyContent: "flex-end"
    }
  };
});


interface Props {
  singleButton?: boolean;
  loading?: boolean;
  open: boolean;
  handleClose: () => void;
  dialogTitle: string;
  dialogContent: string;
  cancelText?: string;
  confirmText?: string;
  handleCancel?: () => void;
  handleConfirm: () => void;
}

const ConfirmDialog = (props: Props) => {
  const { classes } = useStyles();
  const { t } = useTranslation();

  const {
    singleButton,
    loading,
    dialogTitle,
    dialogContent,
    open,
    handleClose,
    cancelText,
    confirmText,
    handleCancel,
    handleConfirm
  } = props;

  return (
    <>
      <Dialog open={open}>
        <DialogTitle
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between"
          }}
        >
          <span
            style={{
              fontWeight: "800",
              fontSize: "16px"
            }}
          >
            {dialogTitle}
          </span>
          <CloseIcon onClick={handleClose} style={{ cursor: "pointer" }} />
        </DialogTitle>
        <DialogContent>
          <Divider />
          <Box className={classes.dialogContent}>
            <Box className={classes.contentTitle}>{dialogContent}</Box>
            <Box className={classes.actionBoxRight}>
              {!singleButton && <CancelButton onClick={handleCancel} >{cancelText ?? t("app.button.cancel")}</CancelButton>}
              <ConfirmButton onClick={handleConfirm} sx={{ textTransform: "none", marginLeft: "20px" }} disabled={loading}>
                {loading ? <CircularProgress sx={{ width: "15px !important", height: "15px !important", color: "white" }} /> : (confirmText ?? t("app.button.confirm"))}
              </ConfirmButton>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ConfirmDialog;
