import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { settings } from '../settings'
const keys = settings.keys;

interface MapLoadState {
  bingLoad: boolean;
  googleLoad: boolean;
  baiduLoad : boolean;
}


function _initialState(): MapLoadState {
  return {
    bingLoad: false,
    googleLoad: false,
    baiduLoad: false
  }
}

const mapLoadSlice = createSlice({
  name: keys.mapLoad,
  initialState: _initialState(),
  reducers: {
    setBingLoad(state, action: PayloadAction<boolean>) {
      state.bingLoad = action.payload;
    },
    setGoogleLoad(state, action: PayloadAction<boolean>) {
      state.googleLoad = action.payload;
    },
    setBaiduLoad(state, action: PayloadAction<boolean>) {
      state.baiduLoad = action.payload;
    },
  }
})


export const { setBingLoad, setGoogleLoad, setBaiduLoad } = mapLoadSlice.actions;
export default mapLoadSlice.reducer;