import { settings } from "src/service/settings";

//存储
export const setStoreValue = (key: string, token: string) => {
  localStorage.setItem(key, token);
};

//获取
export const getStoredValue = (key: string): string | null => {
  const token = localStorage.getItem(key);
  return token;
};

//删除
export const removeStoredValue = (key: string) => {
  localStorage.removeItem(key);
};

//清空
export const clearStoredValue = () => {
  localStorage.clear();
};

//获取token
export const getToken = () => {
  return getStoredValue(settings.keys.accessToken);
};

//获取refreshToken
export const getRefreshToken = () => {
  return getStoredValue(settings.keys.refreshToken);
};
