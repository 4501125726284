import { Box, ListItem } from "@mui/material";
import { FC, } from "react";
import { howLongAgo } from "src/tools/utils/stringUtil";
import DefaultPicture from '../../images/defaultIcons/default-pic-post.png'
import { NewsEntity } from "src/models/news";
import { useTranslation } from "react-i18next";

interface Props {
  news: NewsEntity;
  handleClick: () => void;
}

const NewsNotificationListItem: FC<Props> = ({ news, handleClick }) => {
  const { t } = useTranslation();

  return (
    <ListItem
      key={news.announcement_id}
      alignItems="flex-start"
      sx={{
        marginTop: "5px",
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
      }}
      button
      onClick={handleClick}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          width: "calc(100% - 64px)",
          gap: "5px"
        }}
      >

        <Box
          sx={{
            fontSize: "14px",
            fontWeight: 500,
            color: "#000000",
            fontFamily: "Helvetica Neue",
            overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap',
          }}
        >
          {news.title}
        </Box>
        <Box
          sx={{
            fontSize: "12px",
            fontWeight: 400,
            color: "#000000",
            fontFamily: "Helvetica Neue",
            lineHeight: '18px',
            // maxHeight: "36px",
            // height: "36px",
            // display: "-webkit-box",
            // overflow: "hidden",
            // boxOrient: 'vertical',
            // WebkitBoxOrient: "vertical",
            // lineClamp: 2,
            // WebkitLineClamp: 2,
            overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap',
          }}
        >
          {news.introduction}
        </Box>

        <Box
          sx={{
            fontFamily: "Helvetica Neue",
            fontSize: "12px",
            fontWeight: 400,
            color: "#999999",
          }}
        >
          {howLongAgo(parseInt(news.release_time), t)}
        </Box>
      </Box>


      <img alt='post_image' src={news.image || DefaultPicture}
        style={{
          width: "56px",
          height: "56px",
          marginLeft: "8px",
          objectFit: 'cover',
          borderRadius: '5px',
          border: "1px solid #f3f3f3"
        }} />

    </ListItem>
  );
};

export default NewsNotificationListItem;
