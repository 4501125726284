import { Box, Button, CircularProgress, Divider, MenuItem, Typography } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import React, { useState, useEffect, useCallback } from 'react';
import { Result } from 'src/models/common';
import { Salt } from 'src/models/user';
import { useGetPasscodeMutation, useLoginByPasswordMutation } from 'src/service/api/auth-api';
import { useSelector } from 'src/tools/toolkit/store';
import { createPasswordHash, ErrorCode } from 'src/tools/utils/auth';
import * as ECKey from 'ec-key';
import { useDispatch } from 'react-redux';
import { setRefreshToken, setToken } from 'src/service/redux/access-slice';
import GoogleLoginButton from './thirdPartyLogin/GoogleButton';
import { useThirdPartyLoginStep1Mutation, useThirdPartyLoginStep2Mutation, useWechatPartyLoginMutation } from 'src/service/api/thirdPartyLogin-api';
import FacebookLoginButton from './thirdPartyLogin/FacebookButton';
import AppleLoginButton from './thirdPartyLogin/AppleButton';
import WechatLoginButton from './thirdPartyLogin/WechatButton';
import { isCN } from 'src/tools/utils/appUtil';
import { useTranslation } from 'react-i18next';
import CommonTextField from 'src/components/styled/CommonTextField';
import { makeStyles } from "tss-react/esm/mui";
import ConfirmButton from "src/components/styled/ConfirmButton";
import OutlineButton from "src/components/styled/OutlineButton";
import BackButton from "src/components/button/BackButton"
import ErrorIcon from '@mui/icons-material/Error';
import { Controller, useForm } from "react-hook-form";
import { getStoredValue, setStoreValue } from "src/tools/utils/storage"

const useStyles = makeStyles()((theme) => {
    return {
        loadingBox: {
            // margin: "200px",
            // [theme.breakpoints.down("sm")]: {
            //     margin: "200px 50px"
            // }
        },
        signUpTitle: {
            position: "relative",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: "37px",
            fontFamily: "Helvetica Neue",
            fontSize: "18px",
            fontWeight: 500,
        },
        loginBox: {
            marginTop: "104px",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
        },
        loginText1: {
            fontFamily: "Helvetica Neue",
            fontSize: "14px",
            fontWeight: 400,
        },
        loginText2: {
            fontFamily: "Helvetica Neue",
            fontSize: "14px",
            fontWeight: 400,
            color: "#3A92F9",
            textDecoration: "underline",
            cursor: "pointer"
        }
    }
})

interface PropsForm {
    onClickForgotPassword: () => void;
    onClickSignUp: () => void;
    onLoginSuccess: (userId: string) => void;
}

interface Props {
    userId: string;
    passwordHash: string;
    gps: string;
    serverSalt?: string;
    pubKey?: string;
    signature?: string;
    verify_code?: string;
}

interface VerificationCodeInputs {
    verifyCode: string;
}

export const SignInForm: React.FC<PropsForm> = ({ onLoginSuccess, onClickSignUp, onClickForgotPassword }) => {
    const { t } = useTranslation();
    const { classes } = useStyles();

    const LoginType = {
        wechat: 5,
        google: 2,
        facebook: 3,
        apple: 4,
    }

    const currentGps = useSelector(state => state.location);
    const dispatch = useDispatch();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const [getPasscode, { isLoading: loadingPasscode }] = useGetPasscodeMutation();
    const [loginByPassword, { isLoading: loadingSignin }] = useLoginByPasswordMutation();
    // thirdPartyLogin
    const [thirdPartyLogin1, { isLoading: loading1 }] = useThirdPartyLoginStep1Mutation()
    const [thirdPartyLogin2, { isLoading: loading2 }] = useThirdPartyLoginStep2Mutation()
    const [wechatPartyLogin, { isLoading: loadingWechat }] = useWechatPartyLoginMutation();

    const [isVerify, setIsVerify] = useState<boolean>(false);
    const [countdown, setCountdown] = useState(0);
    const [phoneNumber, setPhoneNumber] = useState<string>("")
    const [verifyCode, setVerifyCode] = useState<string>("")
    const [numberType, setNumberType] = useState<string>("phone");
    const formFunctions = useForm<VerificationCodeInputs>({
        reValidateMode: "onBlur"
    });

    useEffect(() => {
        formFunctions.reset();
    }, [])

    useEffect(() => {
        const currentCount = countdown;
        const intervalID = setInterval(() => {
            if (currentCount > 0) {
                setCountdown(count => count - 1)
            }
        }, 1000)
        return () => clearInterval(intervalID);
    }, [countdown])


    const handleUsernameChange = (e) => {
        setUsername(e.target.value);
    }
    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    }

    const handleClickLogin = () => {
        getPasscode({ userId: username })
            .unwrap()
            .then(authenticate)
            .finally(() => { })
    }
    const handleKeyDown = (e) => {
        if (e.keyCode === 13) {
            handleClickLogin();
        }
    }

    const handleVerifyMobileNumber = (res) => {
        const value = res?.result?.auth_addr;
        const type = res?.result?.type === 1 ? "phone" : "email"
        setCountdown(60);
        setPhoneNumber(value);
        setNumberType(type);
        setVerifyCode("");
        setIsVerify(true);
    }

    const handleVerifyCode = (evt: VerificationCodeInputs) => {
        handleClickLogin();
    }

    const authenticate = (res: Result<Salt>) => {
        if (res?.isFailed) {
            if (res?.resultCode === ErrorCode.SIGN_IN_USER_ID_NOT_EXIST) {
                enqueueSnackbar(t("app.login.userExist"), { variant: 'error' })
            }
            else {
                enqueueSnackbar(res?.message);
            }
        }
        else {
            const { latitude, longitude } = currentGps;
            const gps = currentGps && `${latitude},${longitude}`;
            const key = ECKey.createECKey('prime256v1');
            // const pubKey = key.asPublicECKey().toString('spki');
            const serverSalt = res?.result.server_salt;
            const msg = Buffer.from(username).toString('base64') + serverSalt;
            const signature = key.createSign('SHA256').update(msg).sign('base64');

            let param: Props = {
                userId: username,
                passwordHash: createPasswordHash(password, res.result),
                gps,
                // serverSalt: res?.result.server_salt,
                // signature,
                // pubKey:""
            }

            const publicKey = key.asPublicECKey().toString('spki') as string;
            const pubKey = getStoredValue(username) ?? "";

            if (isVerify && verifyCode) {
                param.verify_code = verifyCode;
            }
            if (!pubKey) {
                param.pubKey = publicKey;
                param.serverSalt = res?.result.server_salt;
                param.signature = signature;
                setStoreValue(username, publicKey);
            }

            loginByPassword(param)
                .unwrap()
                .then(res => {
                    const token = res.result?.token;
                    const errorCode = res.resultCode;
                    if (token) {
                        onLoginSuccess(username)
                        dispatch(setToken(token))
                        dispatch(setRefreshToken(res?.result?.refresh_token))
                    }
                    else if (errorCode === ErrorCode.SING_IN_TRAFFIC_LIMIT) {
                        enqueueSnackbar(t("app.login.system-busy"), { variant: 'error' })
                    }
                    else if (errorCode === ErrorCode.SING_IN_MULTIPLE_ERRORS) {
                        enqueueSnackbar(t("app.login.many-error"), { variant: 'error' })
                    }
                    else if (res?.result?.type || res?.result?.auth_addr) {
                        handleVerifyMobileNumber(res)
                    }
                    else if (errorCode === ErrorCode.SIGN_IN_PASSWORD_INCORRECT) {
                        enqueueSnackbar(t("app.login.password-error"), { variant: 'error' })
                        setPassword('')
                    } else {
                        const errorMessage = res?.message || "Login failed"
                        enqueueSnackbar(errorMessage, { variant: "error" })
                    }
                })
        }
    }

    const thirdPartyLoginStep1 = (thirdToken: string, loginType: number,) => {
        thirdPartyLogin1({ thirdToken: thirdToken, loginType: loginType, })
            .unwrap()
            .then((res) => {
                checkStep1AndStartStep2(res, loginType);
            })
            .catch(error => {
                enqueueSnackbar("Login failed", { variant: "error" })
            })
            .finally(() => { })
    }
    const checkStep1AndStartStep2 = (res: Result<any>, loginType: number) => {
        if (res && !res.isFailed && res.result) {
            const { user_id, verify_message } = res?.result;
            const key = ECKey.createECKey('prime256v1');
            const pubkey = key.asPublicECKey();
            const msg = Buffer.from(user_id).toString('base64');

            thirdPartyLogin2({
                loginType: loginType,
                userId: user_id,
                publicKey: pubkey.toString('spki'),
                verifyMessage: verify_message,
                signature: key.createSign('SHA256').update(msg).sign('base64')
            })
                .unwrap()
                .then((res) => {
                    checkThirdPartyLoginStep2(res, user_id)
                })
                .catch(error => { enqueueSnackbar("Login failed", { variant: "error" }) })
                .finally(() => { })
        }
    }

    const checkThirdPartyLoginStep2 = (res: Result<any>, userId: string) => {
        if (res && !res.isFailed && res.result) {
            dispatch(setToken(res?.result?.token))
            dispatch(setRefreshToken(res?.result?.refresh_token))
            onLoginSuccess(userId);
        }
    }

    const handleGoogleSuccess = async (res: any) => {
        if ('tokenObj' in res) {
            thirdPartyLoginStep1(res.tokenObj.id_token, LoginType.google);
        }
    }
    const handleFail = async (res: any) => {
        console.error(res)
    }
    const handleFacebookLoginResult = async (res) => {
        if ('accessToken' in res) {
            thirdPartyLoginStep1(res.accessToken, LoginType.facebook);
        }
    }

    const handleAppleSuccess = async (res: any) => {
        if ('authorization' in res) {
            thirdPartyLoginStep1(res.authorization.id_token, LoginType.apple);
        }
    }

    const handleWeChatLogin = useCallback((code: string) => {
        wechatPartyLogin({ code }).unwrap()
            .then((res) => {
                checkStep1AndStartStep2(res, LoginType.wechat);
            })
            .catch(error => {
                enqueueSnackbar("Login failed", { variant: "error" })
            })
            .finally(() => { })
    }, [])

    return (
        <Box sx={{ width: "100%" }}>
            {isVerify ? <Box sx={{
                width: "100%",
                margin: "30px 0"
            }}>
                <BackButton handleClick={() => setIsVerify(false)} />
                <CommonTextField
                    select
                    fullWidth
                    size='small'
                    value={numberType}
                    disabled
                    sx={{
                        marginTop: "5px"
                    }}
                >
                    <MenuItem value="phone">{t("app.login.phone")}</MenuItem>
                    <MenuItem value="email">{t("app.login.email")}</MenuItem>
                </CommonTextField>
                <CommonTextField
                    type="text"
                    fullWidth
                    size='small'
                    value={phoneNumber}
                    disabled
                    sx={{
                        margin: "17px 0"
                    }}
                />
                <Box sx={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                    <Controller
                        control={formFunctions.control}
                        name="verifyCode"
                        rules={{
                            required: t("app.settings.account.verification-code-is-required")
                        }}
                        defaultValue=""
                        render={({ field, fieldState: { error } }) => (
                            <CommonTextField
                                {...field}
                                fullWidth
                                type="text"
                                size='small'
                                placeholder={t("app.settings.account.verification-code")}
                                error={error !== undefined}
                                helperText={error ? error.message : ""}
                                onChange={(e) => {
                                    field.onChange(e);
                                    setVerifyCode(e.target.value);
                                }}
                            />
                        )}
                    />
                    <OutlineButton
                        sx={{ marginLeft: "12px" }}
                        onClick={handleClickLogin}
                        disabled={countdown > 0 || loadingSignin || loadingPasscode}
                    >
                        {countdown > 0 ? `${countdown} s` : t("app.button.send")}
                    </OutlineButton>
                </Box>
                <Box sx={{
                    display: "flex",
                    alignItems: "center",
                    margin: "17px 0"
                }}>
                    <ErrorIcon sx={{
                        width: "16px",
                        height: "16px",
                        marginRight: "9px",
                        color: "#4799FA"
                    }} />
                    <Typography sx={{
                        fontSize: "14px",
                        fontWeight: 400
                    }}>
                        {t("app.login.send-verify-code")}
                    </Typography>
                </Box>
                <ConfirmButton
                    sx={{ width: "100%", }}
                    onClick={formFunctions.handleSubmit(handleVerifyCode)}
                    disabled={loadingSignin || loadingPasscode}
                >
                    {t("app.login.verify")}
                </ConfirmButton>
            </Box> : <Box sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                minHeight: "400px",
            }}>
                {(loading1 || loadingSignin || loading2 || loadingPasscode || loadingWechat) ?
                    <CircularProgress className={classes.loadingBox} />
                    :
                    <Box sx={{ marginTop: "20px", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}>
                        <Box
                            component="form"
                            noValidate
                            autoComplete="off"
                        >
                            <CommonTextField placeholder={t("app.login.username")} autoFocus fullWidth size='small'
                                sx={{ marginBottom: "20px" }}
                                value={username}
                                onChange={handleUsernameChange}
                            />
                            <CommonTextField placeholder={t("app.login.password")} type="password" fullWidth size='small'
                                value={password}
                                onChange={handlePasswordChange}
                                onKeyDown={handleKeyDown}
                            />
                            <Button size="large" variant="contained"
                                sx={{
                                    textTransform: 'none', color: 'white', fontFamily: 'Helvetica Neue', fontStyle: 'normal', fontWeight: 500, fontSize: '14px',
                                    height: '44px', whiteSpace: 'nowrap', width: '100%', marginTop: "20px",
                                    background: 'linear-gradient(90deg, #60D6FF 0%, #3A92F9 100%)',
                                    mixBlendMode: 'normal',
                                    boxShadow: '0px 4px 6px rgba(96, 214, 255, 0.3)',
                                    borderRadius: '24px',
                                }}
                                onClick={handleClickLogin}>
                                {t("app.login.login")}
                            </Button>
                        </Box>
                        <Box sx={{
                            marginTop: "10px", marginBottom: "14px", color: '#555', fontFamily: 'Helvetica Neue', fontStyle: 'normal', fontWeight: 500, fontSize: '14px',
                            cursor: "pointer", lineHeight: "30px", width: "fit-content", "&:hover": { color: "#000" }
                        }} onClick={onClickForgotPassword}>{t("app.login.forgot-password")}</Box>
                        <br />
                        <Box sx={{ display: "flex", alignItems: "center", width: "100%", flexDirection: "row", color: '#555', fontFamily: 'Helvetica Neue', fontStyle: 'normal', fontWeight: 400, fontSize: '12px', }}>
                            <Divider sx={{ flexGrow: 1 }}>OR</Divider>
                        </Box>
                        <br />
                        <Box sx={{
                            width: '100%',
                            display: "flex", flexDirection: "column", alignItems: "center",
                        }}>
                            {!isCN && <GoogleLoginButton onSuccess={handleGoogleSuccess} onFailure={handleFail} />}
                            {!isCN && <FacebookLoginButton ResponseFacebook={handleFacebookLoginResult} />}
                            <AppleLoginButton SuccessResponseApple={handleAppleSuccess} ErrorResponseApple={handleFail} />
                            <WechatLoginButton handleSuccess={handleWeChatLogin} />
                        </Box>
                        {/* <Divider></Divider>
                    <Box sx={{
                        fontFamily: "Helvetica Neue", fontSize: "14px", fontWeight: 400, color: "#000000", textAlign: "center",
                        padding: "20px",
                    }}>
                        Don’t have an account? Sign up
                    </Box> */}

                    </Box>
                }
                {/* } */}
            </Box>}

        </Box>

    )
}

export default SignInForm;