import { Response, Result, Value } from "../../models/common";
import { createApi } from "@reduxjs/toolkit/query/react";
import { buildQueryWithReAuth } from "../../tools/toolkit/helper";
import { ListResponse } from "src/models/admin-knowledge";

export const adminKnowledgeApi = createApi({
    reducerPath: "adminKnowledge",
    baseQuery: buildQueryWithReAuth(process.env.REACT_APP_ADMIN_API_URL),
    endpoints(builder) {
        return {
            // getList
            getList: builder.mutation<
                Result<ListResponse>,
                {
                    start_key: string;
                    search_content: string;
                    page_size: number;
                }
            >({
                query: ({ start_key, search_content, page_size }) => ({
                    url: "adminmanamgement",
                    method: "POST",
                    body: {
                        request_type: "list_ai_document",
                        request_data: {
                            start_key,
                            search_content,
                            page_size,
                            forward: true
                        },
                    },
                }),
                transformResponse: (res: Response<ListResponse>) => new Value(res),
            }),
            createKnowledge: builder.mutation<
                Result<undefined>,
                {
                    file_url?: string;
                    question: string;
                    answer?: string;
                    location: string;
                }
            >({
                query: ({ file_url, question, answer, location }) => ({
                    url: "adminmanamgement",
                    method: "POST",
                    body: {
                        request_type: "upload_ai_document",
                        request_data: {
                            file_url,
                            question,
                            answer,
                            location
                        },
                    },
                }),
                transformResponse: (res: Response<undefined>) => new Value(res),
            }),
            deleteKnowledge: builder.mutation<
                Result<undefined>,
                {
                    file_id:string;
                }
            >({
                query: ({ file_id }) => ({
                    url: "adminmanamgement",
                    method: "POST",
                    body: {
                        request_type: "delete_ai_document",
                        request_data: {
                            file_id
                        },
                    },
                }),
                transformResponse: (res: Response<undefined>) => new Value(res),
            }),
        };
    },
});

export const {
    useGetListMutation,
    useCreateKnowledgeMutation,
    useDeleteKnowledgeMutation,
} = adminKnowledgeApi;
