import { useState, useEffect, useRef } from "react";
import {
  Box,
  Drawer,
  Avatar,
  Typography,
  CircularProgress,
} from "@mui/material";
import { styled } from "@mui/system";
import logo from "src/images/logo/logo108.png";
import ConfirmButton from "src/components/styled/ConfirmButton";
import { useTranslation } from "react-i18next";
import { isWeixinBrowser } from "src/tools/utils/appUtil";
import WechatOpenAppBtn from "src/layout/openApp/WechatOpenAppBtn";
import { openApp } from "src/layout/openApp/openApp";
import getSignature from "src/service/api/wechatSignature-api";

const LogoWrapper = styled("div")(() => ({
  position: "absolute",
  top: "-24px",
  left: "50%",
  transform: "translateX(-50%)",
  zIndex: 1300,
  width: "54px",
  height: "54px",
  background: "#fff",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "50%",
}));

const StyledDrawer = styled(Drawer)(({ theme }) => ({
  ".MuiDrawer-paper": {
    borderTopLeftRadius: "12px",
    borderTopRightRadius: "12px",
    overflow: "visible",
    boxSizing: "center-box",
  },
}));

const openBtnStyles = {
  width: "100%",
  height: "44px",
  background: "linear-gradient(90deg,#60D6FF 0%,#3A92F9 100%)",
  boxShadow: "0px 4px 6px rgba(96,214,255,0.3)",
  borderRadius: "20px",
  fontFamily: "SF Pro Text",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "12px",
  textAlign: "center",
  color: "#FFFFFF",
  cursor: "pointer",
  textTransform: "none",
  textDecoration: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  border: "none",
};

const DownloadPopup = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const initWexinRef = useRef<boolean>(false);

  useEffect(() => {
    if (isWeixinBrowser && initWexinRef.current === false) {
      initWexinRef.current = true;
      getSignature(setLoading);
    }
  }, [isWeixinBrowser]);

  const toggleDrawer = (open) => (event) => {
    setOpen(open);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setOpen(true);
    }, 10000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <StyledDrawer
      anchor="bottom"
      open={open}
      onClose={toggleDrawer(false)}
      ModalProps={{ keepMounted: true }}
    >
      <LogoWrapper>
        <Avatar
          src={logo}
          sx={{
            width: "48px",
            height: "48px",
          }}
        />
      </LogoWrapper>
      <Box
        sx={{
          padding: "37px 13px 23px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: 500,
          }}
        >
          {t("app.footer.footer_dialog.anylocal")}
        </Typography>
        <Typography
          sx={{
            fontSize: "12px",
            fontWeight: 400,
            color: "#555555",
            margin: "9px 0 16px",
          }}
        >
          {t("app.footer.footer_dialog.more")}
        </Typography>
        {isWeixinBrowser ? (
          loading ? (
            <CircularProgress sx={{ width: "20px", height: "20px" }} />
          ) : (
            <WechatOpenAppBtn
              openBtnStyles={openBtnStyles}
              text={t("app.footer.footer_dialog.open")}
            />
          )
        ) : (
          <ConfirmButton
            style={{
              width: "100%",
              height: "44px",
            }}
            onClick={() => openApp(false, {})}
          >
            {t("app.footer.footer_dialog.open")}
          </ConfirmButton>
        )}
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: 400,
            color: "#555555",
            marginTop: "11px",
            cursor: "pointer",
          }}
          onClick={toggleDrawer(false)}
        >
          {t("app.footer.footer_dialog.not_now")}
        </Typography>
      </Box>
    </StyledDrawer>
  );
};

export default DownloadPopup;
