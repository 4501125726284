import { TextField } from "@mui/material";
import { styled } from "@mui/material/styles"

const CommonTextField = styled(TextField)({
  width: "100%",
  height: "44px",
  "& .MuiInputBase-root": {
    borderRadius: "24px",
    fontSize: "14px",
  },
  "& .MuiOutlinedInput-input": {
    padding: "12px 17px !important"
  },
  "& input": {
    padding: "12px 17px !important"
  },
})

export default CommonTextField;