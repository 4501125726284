import { type FC, useMemo } from 'react';
import { Outlet, useLocation } from "react-router-dom";
import OpenAppBox from './openApp/OpenAppBox';
import { hideOpenAppSet } from 'src/app-config';
import { isWebview } from 'src/tools/utils/deviceUtil';
import { isBaiduBoxApp } from 'src/tools/utils/appUtil';
import { Box, useMediaQuery, useTheme } from "@mui/material";
import { onTableOrMobile } from 'src/tools/utils/appUtil';
import DownloadPopup from "src/layout/footer/DownloadPopup"
import { onMobile } from "src/tools/utils/appUtil";
import { isAnylocalApp } from "src/tools/utils/appUtil";

const IsOnTableOrMobile = onTableOrMobile();

const IsOnMobile = onMobile();

const EmptyLayout: FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const global = params.get('global');
  const isGlobalSource = window.location.host.endsWith("cn") && global === "1";
  const isInIframe = useMemo(() => {
    return window != window.top
  }, [])

  const iframeSrc = useMemo(() => {
    if (isGlobalSource) {
      return window.location.href.replace(".cn", ".com").replace("global=1", "iframe=1");
    } else {
      return window.location.href;
    }
  }, [isGlobalSource])

  const showOpenApp = useMemo(() => {
    return (isMobile || IsOnTableOrMobile)
      && !isInIframe
      && !hideOpenAppSet.has(window.location.pathname)
      && (!isWebview() || isBaiduBoxApp)
    // && !(isWeixinBrowser && window.location.host !== WEBSITE_PRODUCTION_CN)
  }, [isMobile, isInIframe]);


  return (
    <>
      {/* 手机布局显示打开app的选项 */}
      {showOpenApp && <OpenAppBox />}

      {IsOnMobile && !isAnylocalApp() && <DownloadPopup />}

      {isGlobalSource ?
        <iframe src={iframeSrc} title="content"
          style={{
            border: "none",
            width: "100%",
            height: "100dvh",
          }}
        />
        :
        <Box
          sx={{
            width: "100%",
            height: showOpenApp ? "calc(100vh - 50px)" : "100vh",
            marginTop: showOpenApp ? "50px" : "0px"
          }}
        >
          <Outlet />
        </Box>
      }
    </>
  )
}

export default EmptyLayout