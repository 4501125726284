import { useEffect } from "react";
import { isCN } from "src/tools/utils/appUtil";
import { useDispatch } from "react-redux";
import { setPromotion } from "src/service/redux/promotion-slice";
import { BAIDU_KEYWORD, BAIDU_BDVID, PROMOTION } from './const';


const useLandingBaidu = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (isCN) {
      const searchParams = new URLSearchParams(window.location.search);
      const keyword = searchParams.get('keyword');
      const bd_vid = searchParams.get('bd_vid');

      if (keyword && bd_vid) {
        // 存储数据到session storage
        sessionStorage.setItem(BAIDU_KEYWORD, keyword);
        sessionStorage.setItem(BAIDU_BDVID, bd_vid);
        sessionStorage.setItem(PROMOTION, "1");
        dispatch(setPromotion(true));

        let decodeKeyword = keyword
        try {
          decodeKeyword = decodeURI(keyword)
        } catch (e) {
          console.error('decodeURI error', e);
        }
        (async () => {
          await CreateCollectVisit(decodeKeyword, bd_vid, window.location.href);
          await CreateCollectAction(bd_vid, 'click', decodeKeyword);
        })()
      }
    }
  }, [])
}

const host = process.env.REACT_APP_API_URL;

async function CreateCollectVisit(keyword: string, bd_vid: string, url: string) {
  await fetch(host + '/ads_referral_tracking', {
    method: 'POST',
    mode: "cors",
    cache: "no-cache",
    body: JSON.stringify({
      operation: "create_ads_referral_tracking",
      session_id: bd_vid,
      keyword,
      url,
      platform: 'baidu',
      agent: window.navigator.userAgent,
    })
  })
}

async function CreateCollectAction(bd_vid: string, action: string, keyword: string) {
  const href = '';
  const text = '';
  const id = '';


  const actionData = { href, text, id };
  await fetch(host + '/ads_referral_tracking', {
    method: 'POST',
    mode: "cors",
    cache: "no-cache",
    body: JSON.stringify({
      operation: "create_user_action_tracking",
      session_id: bd_vid,
      action_type: action,
      platform: 'baidu',
      action_details: actionData,
      keyword
    })
  })
}


export function CollectDownloadAction(cb?: () => void) {
  const baiduKeyword = sessionStorage.getItem(BAIDU_KEYWORD);
  const baiduBdvid = sessionStorage.getItem(BAIDU_BDVID);

  if (baiduKeyword && baiduBdvid) {
    CreateCollectAction(baiduBdvid, 'download', baiduKeyword);
    sessionStorage.removeItem(BAIDU_KEYWORD);
    sessionStorage.removeItem(BAIDU_BDVID);
    if (cb) {
      setTimeout(() => {
        cb()
      }, 200)
    }
  } else if (cb) {
    cb()
  }
}


export default useLandingBaidu;