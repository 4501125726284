import { createApi } from "@reduxjs/toolkit/query/react";
import { Response, Result, Value } from "../../models/common";
import { baseQueryWithReauth } from "../../tools/toolkit/helper";
import { SendRewardEntity } from "../../models/reward";
import { SendCommentEntity } from "../../models/comment";
import {
  MessageTypeEnum,
  PostMessageContent,
  ThumbType,
  RecommendResponse
} from "../../models/post";
import { WebsocketSendMessage } from "src/models/chat";
import { ReportCategoryItem } from "src/tools/utils/appUtil";
import { GetCategoryListResponse } from "src/models/category";

export const postApi = createApi({
  reducerPath: "post",
  baseQuery: baseQueryWithReauth,
  endpoints(builder) {
    return {
      sendReward: builder.mutation<Result<any>, { entity: SendRewardEntity }>({
        query: ({ entity }) => ({
          url: `sendmessage`,
          method: "POST",
          body: entity,
        }),
        transformResponse: (res: Response<any>) =>
          new Value(res, "send_message"),
      }),

      sendComment: builder.mutation<Result<any>, { entity: SendCommentEntity }>(
        {
          query: ({ entity }) => ({
            url: `sendmessage`,
            method: "POST",
            body: entity,
          }),
          transformResponse: (res: Response<any>) =>
            new Value(res, "send_message"),
        }
      ),

      getTranslate: builder.mutation<Result<any>, { id: string; type: string }>(
        {
          query: ({ id, type }) => ({
            url: `translate`,
            method: "POST",
            body: {
              request_data: {
                targe_language: "en",
                content_type: type,
                content_id: id,
              },
              request_type: "translate",
            },
          }),
          transformResponse: (res: Response<any>) => new Value(res),
        }
      ),

      updateThumb: builder.mutation<
        Result<any>,
        {
          add: boolean;
          message_id: string;
          message_type: MessageTypeEnum;
          thumbinfo: boolean;
          type: ThumbType;
          url_type: number;
          message_url?: string;
        }
      >({
        query: ({
          add,
          message_id,
          message_type,
          thumbinfo,
          type,
          message_url,
          url_type,
        }) => ({
          url: `updatethumbinfo`,
          method: "POST",
          body: {
            add,
            message_id,
            message_type,
            thumbinfo,
            type,
            message_url,
            url_type,
          },
        }),
        transformResponse: (res: Response<any>) => new Value(res),
      }),

      getUTStampURL: builder.mutation<Result<any>, { hash: string }>({
        query: ({ hash }) => ({
          url: `getutstampurl`,
          method: "POST",
          body: {
            hash,
          },
        }),
        transformResponse: (res: Response<any>) => new Value(res),
      }),

      resellPost: builder.mutation<
        Result<any>,
        { messageID: string; postContent: PostMessageContent }
      >({
        query: ({ messageID, postContent }) => ({
          url: `post_resell`,
          method: "POST",
          body: {
            message_id: messageID,
            message_content: postContent,
          },
        }),
        transformResponse: (res: Response<any>) => new Value(res),
      }),

      requestPurchasePost: builder.mutation<
        Result<any>,
        {
          messageID: string;
          requestPrice: string;
          ownerID: string;
          description: string;
        }
      >({
        query: ({ messageID, requestPrice, ownerID, description }) => ({
          url: `requestprice`,
          method: "POST",
          body: {
            messageid: messageID,
            request_price: requestPrice,
            owner_user_id: ownerID,
            description,
            operation: "requestprice",
          },
        }),
        transformResponse: (res: Response<any>) => new Value(res),
      }),

      acceptRequestPurchase: builder.mutation<
        Result<any>,
        {
          messageID: string;
          requestPrice: string;
          requestUserID: string;
          timestamp: string;
        }
      >({
        query: ({ messageID, requestPrice, requestUserID, timestamp }) => ({
          url: `requestprice`,
          method: "POST",
          body: {
            messageid: messageID,
            request_price: requestPrice,
            request_user_id: requestUserID,
            timestamp,
            operation: "acceptprice",
          },
        }),
        transformResponse: (res: Response<any>) => new Value(res),
      }),
      rejectRequestPurchase: builder.mutation<
        Result<any>,
        {
          messageID: string;
          requestUserID: string;
          timestamp: string;
        }
      >({
        query: ({ messageID, requestUserID, timestamp }) => ({
          url: `requestprice`,
          method: "POST",
          body: {
            messageid: messageID,
            request_user_id: requestUserID,
            timestamp,
            operation: "rejectprice",
          },
        }),
        transformResponse: (res: Response<any>) => new Value(res),
      }),

      ownerGetRequestPurchaseHistory: builder.mutation<
        Result<any>,
        {
          messageID: string;
          ownerID: string;
          pageSize?: number;
          startKey?: string;
        }
      >({
        query: ({ messageID, ownerID, pageSize, startKey }) => ({
          url: `requestprice`,
          method: "POST",
          body: {
            messageid: messageID,
            owner_user_id: ownerID,
            operation: "getrequestpricehistorylistbyowner",
            pagesize: pageSize ?? 20,
            startkey: startKey,
          },
        }),
        transformResponse: (res: Response<any>) => new Value(res),
      }),
      requesterGetRequestPurchaseHistory: builder.mutation<
        Result<any>,
        {
          messageID: string;
          ownerID: string;
          requestUserID: string;
          pageSize?: number;
          startKey?: string;
        }
      >({
        query: ({ messageID, ownerID, requestUserID, pageSize, startKey }) => ({
          url: `requestprice`,
          method: "POST",
          body: {
            messageid: messageID,
            owner_user_id: ownerID,
            request_user_id: requestUserID,
            operation: "getrequestpricehistorylistbyrequestuser",
            pagesize: pageSize ?? 20,
            startkey: startKey,
          },
        }),
        transformResponse: (res: Response<any>) => new Value(res),
      }),
      sharePostToUsers: builder.mutation<
        Result<any>,
        {
          message: WebsocketSendMessage;
          time: number;
          user_ids: string[];
        }
      >({
        query: ({ message, time, user_ids }) => ({
          url: `sharepost`,
          method: "POST",
          body: {
            message,
            time,
            user_ids,
          },
        }),
        transformResponse: (res: Response<any>) => new Value(res),
      }),

      deleteMessage: builder.mutation<Result<any>, { messageId: string }>({
        query: ({ messageId }) => ({
          url: `deletemessage`,
          method: "POST",
          body: {
            message_type: MessageTypeEnum.Message,
            messageid: messageId,
          },
        }),
        transformResponse: (res: Response<any>) =>
          new Value(res, "delete_message"),
      }),

      // get category list
      adminGetCategoryList: builder.mutation<
        Result<GetCategoryListResponse>,
        {
          start_key: string;
          page_size: number;
        }
      >({
        query: ({ start_key, page_size }) => ({
          url: `tag`,
          method: "POST",
          body: {
            type: "get_tag_list",
            page_size,
            start_key,
          },
        }),
        transformResponse: (res: Response<GetCategoryListResponse>) =>
          new Value(res),
      }),

      // set post category
      adminSetPostCategory: builder.mutation<
        Result<undefined>,
        {
          message_id: string;
          tag_id_list: string[];
          type: string;
          picture_url?: string;
        }
      >({
        query: ({ message_id, tag_id_list, picture_url, type }) => ({
          url: `tag`,
          method: "POST",
          body: {
            type,
            input: {
              message_id,
              tag_id_list,
              picture_url,
            },
          },
        }),
        transformResponse: (res: Response<undefined>) => new Value(res),
      }),

      // add category
      adminAddCategory: builder.mutation<
        Result<undefined>,
        { name: string; cn_name: string }
      >({
        query: ({ name, cn_name }) => ({
          url: `tag`,
          method: "POST",
          body: {
            type: "create_tag",
            input: {
              name,
              cn_name,
            },
          },
        }),
        transformResponse: (res: Response<undefined>) => new Value(res),
      }),

      // get category
      adminGetPostCategory: builder.mutation<
        Result<GetCategoryListResponse>,
        {
          message_id?: string;
          type: string;
          picture_url?: string;
        }
      >({
        query: ({ message_id, picture_url, type }) => ({
          url: `tag`,
          method: "POST",
          body: {
            type,
            input: {
              message_id,
              picture_url,
            },
          },
        }),
        transformResponse: (res: Response<any>) => new Value(res),
      }),

      // recommend post
      adminRecommendPost: builder.mutation<
        Result<any>,
        {
          content_id: string;
          source: string;
          priority?: number;
          business_type: "hide_post" | "recommend" | "recommend_trade";
          post_time?: number;
          end_timestamp?: number;
          share_to_loc_room?: boolean;
        }
      >({
        query: ({
          content_id,
          source,
          priority,
          business_type,
          post_time,
          end_timestamp,
          share_to_loc_room
        }) => ({
          url: `recommend`,
          method: "POST",
          body: {
            content_id,
            source,
            priority,
            business_type,
            post_time,
            end_timestamp,
            share_to_loc_room
          },
        }),
        transformResponse: (res: Response<GetCategoryListResponse>) =>
          new Value(res),
      }),

      // get tag detail by tag id
      getTagDetail: builder.mutation<
        Result<any>,
        {
          tag_id: string;
        }
      >({
        query: ({ tag_id }) => ({
          url: `tag`,
          method: "POST",
          body: {
            type: "get_tag_by_id",
            input: {
              id: tag_id,
            },
          },
        }),
        transformResponse: (res: Response<any>) => new Value(res),
      }),

      //获取个人推荐列表
      getRecommendList: builder.mutation<
        Result<RecommendResponse>,
        {
          page_size: number;
          start_key: string;
          user_id: string;
          sort_type: string
        }
      >({
        query: ({ page_size, start_key, user_id, sort_type }) => ({
          url: `getrecommendlist`,
          method: "POST",
          body: {
            page_size,
            start_key,
            user_id,
            sort_type,
          },
        }),
        transformResponse: (res: Response<RecommendResponse>) => new Value(res),
      }),

      // get ai post list
      getAIPostList: builder.mutation<
        Result<any>,
        {
          forward?: boolean;
          id: string;
          page_size?: number;
          start_key?: string;
        }
      >({
        query: ({ forward, id, page_size, start_key }) => ({
          url: `loc_chat/list_post_by_ai_id`,
          method: "POST",
          body: {
            forward,
            id,
            page_size,
            start_key,
          },
        }),
        transformResponse: (res: Response<any>) => new Value(res),
      }),

      // get ai chatroom list
      getAIChatroomList: builder.mutation<
        Result<any>,
        {
          forward: boolean;
          id: string;
          page_size: number;
          search_name: string;
          start_key: string;
        }
      >({
        query: ({ forward, id, page_size, search_name, start_key }) => ({
          url: `loc_chat/get_room_by_type`,
          method: "POST",
          body: {
            forward,
            id,
            page_size,
            start_key,
            search_name
          },
        }),
        transformResponse: (res: Response<any>) => new Value(res),
      }),
    };
  },
});

export const {
  useSendRewardMutation,
  useSendCommentMutation,
  useGetTranslateMutation,
  useUpdateThumbMutation,
  useGetUTStampURLMutation,
  useResellPostMutation,
  useRequestPurchasePostMutation,
  useAcceptRequestPurchaseMutation,
  useRejectRequestPurchaseMutation,
  useOwnerGetRequestPurchaseHistoryMutation,
  useRequesterGetRequestPurchaseHistoryMutation,
  useSharePostToUsersMutation,
  useDeleteMessageMutation,
  useAdminSetPostCategoryMutation,
  useAdminGetCategoryListMutation,
  useAdminAddCategoryMutation,
  useAdminGetPostCategoryMutation,
  useAdminRecommendPostMutation,
  useGetTagDetailMutation,
  useGetRecommendListMutation,
  useGetAIPostListMutation,
  useGetAIChatroomListMutation,
} = postApi;
