import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { settings } from "../settings";


export interface VideoPost {
  id: string;
  volume: number;
  progress: number;
}




interface VideoState {
  videoInfoList: VideoPost[];
  isOpenVolume: boolean;
}

export function _loadState(): VideoState {
  return {
    videoInfoList: [],
    isOpenVolume: false,
  }
}

const videoSlice = createSlice({
  name: settings.keys.video,
  initialState: _loadState(),
  reducers: {
    setVideoInfoList(state, action: PayloadAction<VideoPost[]>) {
      state.videoInfoList = action.payload;
    },
    setIsOpenVolume(state, action: PayloadAction<boolean>) {
      state.isOpenVolume = action.payload;
    }
  }
})


export const {
  setVideoInfoList,
  setIsOpenVolume
} = videoSlice.actions;
export default videoSlice.reducer;