import { Box, IconButton, Menu, Tooltip, useMediaQuery, useTheme } from "@mui/material";
import { makeStyles } from 'tss-react/mui';
import { useSelector } from "src/tools/toolkit/store";
import { MODE_GRID, MODE_LARGE_PHOTO, setLanguage, setPostPhotoDisplayMode } from "../../service/redux/app-slice";
import IcoModeLarge from "src/images/layout/ico-large-mode.svg";
import IcoModeLargeActive from "src/images/layout/ico-large-mode-active.svg";
import IcoModeGrid from "src/images/layout/ico-gird-mode.svg";
import IcoModeGridActive from "src/images/layout/ico-gird-mode-active.svg";
import { useDispatch } from "react-redux";
import { useTranslation } from 'react-i18next';


const useStyles = makeStyles()((theme) => {
  return {
    root: {
      position: 'relative',
    },
    languageButton: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
    },
    content: {
      position: 'absolute',
      top: '26px',
      right: '10px',
      borderRadius: '8px',
      background: '#fff',
      boxShadow: '0px 4px 20px 0px rgba(0, 0, 0, 0.10)',
      transition: 'all 0.4s',
      zIndex: 100
    },
    item: {
      width: '100%',
      padding: "10px 0",
      display: 'flex',
      flexDirection: "column",
      alignItems: 'center',
      justifyContent: 'center',
    },
    menuIcon: {
      cursor: "pointer"
    },
    menuText: {
      fontFamily: "Helvetica Neue",
      fontSize: "12px",
      fontWeight: 400,
    },
    menuTextSelected: {
      fontFamily: "Helvetica Neue",
      fontSize: "12px",
      fontWeight: 400,
      background: "#3B92F9",
      color: "white",
      borderRadius: "12px",
      width: "128px",
      textAlign: "center",
      padding: "6px 0"
    }

  };
});

interface Props {
  closeDialog: () => void,
}

const PhotoModeList = ({ closeDialog }: Props) => {
  const { classes } = useStyles();
  const { postPhotoDisplayMode } = useSelector(state => state.app);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <>
      <Box
        className={classes.item}
      >
        <img className={classes.menuIcon} alt="large_image"
          src={postPhotoDisplayMode === MODE_LARGE_PHOTO ? IcoModeLargeActive : IcoModeLarge}
          onClick={() => {
            dispatch(setPostPhotoDisplayMode(MODE_LARGE_PHOTO));
            closeDialog();
          }} />
        <Box className={postPhotoDisplayMode === MODE_LARGE_PHOTO ? classes.menuTextSelected : classes.menuText}>{t("app.header.large-image-mode")}</Box>
      </Box>
      <Box
        className={classes.item}
      >
        <img className={classes.menuIcon} alt="large_image"
          src={postPhotoDisplayMode === MODE_GRID ? IcoModeGridActive : IcoModeGrid}
          onClick={() => {
            dispatch(setPostPhotoDisplayMode(MODE_GRID));
            closeDialog();
          }} />
        <Box className={postPhotoDisplayMode === MODE_GRID ? classes.menuTextSelected : classes.menuText}>{t("app.header.grid-mode")}</Box>
      </Box>
    </>
  )
}

export default PhotoModeList;