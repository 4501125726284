import { useRef, useEffect, ReactNode, useState } from 'react';
import { type Location, useLocation } from "react-router-dom";
import { createContext, useContext, } from "react";

interface RouterLocationContextType {
  prevLocation: Location | null;
  currentLocation: Location | null;
}

const RouterLocationContext = createContext<RouterLocationContextType>({
  prevLocation: null,
  currentLocation: null
})

interface Props {
  children: ReactNode;
}


export const RouterLocationProvider = ({ children }: Props) => {
  const location = useLocation();
  const currentLocationRef = useRef<Location | null>(null);
  const [prevLocation, setPrevLocation] = useState<Location | null>(null);

  useEffect(() => {
    if (location && location.pathname !== currentLocationRef.current?.pathname) {
      setPrevLocation(currentLocationRef.current)
      currentLocationRef.current = location;
    }
  }, [location])

  const data = {
    prevLocation: prevLocation,
    currentLocation: currentLocationRef.current
  }
  return (
    <RouterLocationContext.Provider value={data}>
      {children}
    </RouterLocationContext.Provider>
  )
}


export const useRouterLocation = (): RouterLocationContextType => {
  const context = useContext(RouterLocationContext);

  if (!context) {
    throw new Error(
      'Use useRouterLocation hook inside RouterLocationProvider.'
    );
  }
  return context;
}