import { RefObject } from 'react';
import { PostEntity } from "../../models/post";
import { getDateFormat1 } from "./timeUtil";
import { PaymentCurrency } from "src/models/payment";
import { appConfig } from "src/app-config";

interface DateGroup {
  date: string;
  list: PostEntity[];
}
export const getDateGroupList = (list: PostEntity[]): DateGroup[] => {
  const resultList: DateGroup[] = [];
  if (!list || list.length === 0) {
    return [];
  }
  list.forEach((item) => {
    const dateStr = getDateFormat1(item.timestamp * 1000);
    const findDateGroup = resultList.find(
      (dateObj) => dateObj.date === dateStr
    );
    if (findDateGroup) {
      findDateGroup.list.push(item);
    } else {
      const newDateGroupObj: DateGroup = { date: dateStr, list: [item] };
      resultList.push(newDateGroupObj);
    }
  });
  return resultList;
};


interface DatePostItem {
  date?: string;
  post: PostEntity;
}


export const getDatePostList = (list: PostEntity[]): DatePostItem[] => {
  const result: DatePostItem[] = [];
  if (!list || list.length === 0) {
    return result;
  }

  const dateGroupList: DateGroup[] = [];
  list.forEach((item) => {
    const dateStr = getDateFormat1(item.timestamp * 1000);
    const findDateGroup = dateGroupList.find(
      (dateObj) => dateObj.date === dateStr
    );
    if (findDateGroup) {
      findDateGroup.list.push(item);
    } else {
      const newDateGroupObj: DateGroup = { date: dateStr, list: [item] };
      dateGroupList.push(newDateGroupObj);
    }
  });

  dateGroupList.forEach(group => {
    let first = true;
    group.list.forEach(item => {
      if (first) {
        result.push({
          date: group.date,
          post: item
        });
        first = false;
      } else {
        result.push({
          post: item
        })
      }
    })
  })

  return result;
}


export const computeMaxHeight = (theme, ref?: RefObject<HTMLElement>) => {
  let maxHeight = "calc(680px * 1.2)";
  if (window.innerWidth <= theme.breakpoints.values.sm) {
    maxHeight = "calc((100vw - 24px) * 1.2)";
  } else if (
    window.innerWidth > theme.breakpoints.values.sm &&
    window.innerWidth < theme.breakpoints.values.xl
  ) {
    let cardWidth = window.innerWidth * 0.4;
    if (cardWidth > 680) cardWidth = 680;
    if (cardWidth < 500) cardWidth = 500;
    if (window.innerHeight - 200 >= cardWidth * 1.2) {
      maxHeight = `calc(${cardWidth}px * 1.2)`;
    } else {
      maxHeight = "calc(100vh - 200px)";
    }
  } else if (window.innerWidth >= theme.breakpoints.values.xl) {
    maxHeight = "calc(680px * 1.2)";
  }

  if (ref && ref.current) {
    ref.current.style.maxHeight = maxHeight;
  } else {
    return maxHeight;
  }
};

export const calculatePriceStr = (price, currency, t) => {
  if (!price) return "";
  let priceStr = price?.toString();
  let decimalIndex = price?.toString()?.indexOf(".");
  if (decimalIndex !== -1) {
    // 如果小数点后位数超过两位，则进行截断
    if (priceStr.length - decimalIndex > 3) {
      price = price?.toFixed(2);
    }
  }
  if (currency === PaymentCurrency.COIN || !currency) {
    return price === 0 || price === 1
      ? `${t("app.post.cash-coin", { price: price })}`
      : `${t("app.post.cash-coins", { price: price })}`;
  } else if (currency === PaymentCurrency.USD) {
    return `${t("app.post.USD", { price: price })}`;
  } else if (currency === PaymentCurrency.CNY) {
    return `${t("app.post.CNY", { price: price })}`;
  }
};

export enum InViewPortAdjustType {
  Percent,
  Fixed
}

export interface InViewPortOptions {
  type: InViewPortAdjustType;
  value: number;
}

export interface ViewPortValue {
  fixed?: number;
  percent?: number;
}

export const isInViewport = (element: Element, topViewPortValue?: ViewPortValue, bottomViewPortValue?: ViewPortValue) => {
  const rect = element.getBoundingClientRect();
  let topOff = 0;
  let bottomOff = 0;

  if (topViewPortValue) {
    if (topViewPortValue.fixed) {
      topOff += topViewPortValue.fixed;
    }
    if (topViewPortValue.percent) {
      topOff += rect.height * topViewPortValue.percent;
    }
  }


  if (bottomViewPortValue) {
    if (bottomViewPortValue.fixed) {
      bottomOff += bottomViewPortValue.fixed;
    }
    if (bottomViewPortValue.percent) {
      bottomOff += rect.height * bottomViewPortValue.percent;
    }
  }


  return (
    rect.top >= topOff &&
    rect.left >= 0 &&
    rect.bottom <=
    (window.innerHeight || document.documentElement.clientHeight) + bottomOff &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
};


export const goToUserProfile = (userID: string) =>{
  if (!userID) return;
  window.open(
    `${appConfig.paths.user}?id=${userID}`,
    "_blank"
  );
}