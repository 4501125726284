import { createApi } from '@reduxjs/toolkit/query/react'
import { Response, Result, Value } from '../../models/common';
import { baseQueryWithReauth } from '../../tools/toolkit/helper';
import { ProductEntity } from "src/models/product";


interface getProductResult {
    product_info: ProductEntity[]
}

export const productApi = createApi({
    reducerPath: 'product',
    baseQuery: baseQueryWithReauth, 
    endpoints(builder) {
        return {
            getProduct: builder.mutation<Result<getProductResult>, {
                productIds?: number[], 
                userId?: string, 
                pageSize?: number, 
                startKey?: string, 
                forwardOrder?: boolean
            }>({
                query: ({ productIds, userId, pageSize, startKey, forwardOrder }) => ({
                    url: 'product',
                    method: 'POST',
                    body: {
                        request_type:"get",
                        request_data: {
                            product_ids: productIds, 
                            user_id: userId,
                            pagesize: pageSize,
                            start_key: startKey,
                            forward_order: forwardOrder,
                        }
                    }
                }),
                transformResponse: (res: Response<getProductResult>) => new Value<getProductResult>(res)
            })
        }
    }
})


export const { useGetProductMutation
} = productApi