import { createApi } from "@reduxjs/toolkit/query/react";
import { Response, Result, Value } from "../../models/common";
import { buildQueryWithReAuth } from "../../tools/toolkit/helper";
import { MessageListProps, VisitorsListProps } from "src/models/admin-chatroom";

export const adminChatroomApi = createApi({
  reducerPath: "adminChatroom",
  baseQuery: buildQueryWithReAuth(process.env.REACT_APP_ADMIN_API_URL),
  endpoints(builder) {
    return {
      getMessageList: builder.mutation<
        Result<MessageListProps>,
        {
          start_key: string;
          page_size: number;
          forward: boolean;
        }
      >({
        query: ({ start_key, page_size, forward }) => ({
          url: `adminmanamgement`,
          method: "POST",
          body: {
            request_type: "loc_room_message",
            request_data: {
              page_size,
              start_key,
              forward,
            },
          },
        }),
        transformResponse: (res: Response<MessageListProps>) => new Value(res),
      }),
      getVisitorsList: builder.mutation<
        Result<VisitorsListProps>,
        {
          start_key: string;
          page_size: number;
          forward: boolean;
        }
      >({
        query: ({ start_key, page_size, forward }) => ({
          url: `adminmanamgement`,
          method: "POST",
          body: {
            request_type: "list_room_visit_history",
            request_data: {
              page_size,
              start_key,
              forward,
            },
          },
        }),
        transformResponse: (res: Response<VisitorsListProps>) => new Value(res),
      }),
    };
  },
});

export const { useGetMessageListMutation, useGetVisitorsListMutation } =
  adminChatroomApi;
