import { createApi } from "@reduxjs/toolkit/query/react";
import { Response, Result, Value } from "../../models/common";
import { buildQueryWithReAuth } from "../../tools/toolkit/helper";
import { DashboardResponse } from "src/models/admin-dashboard";

export const adminDashBoardApi = createApi({
  reducerPath: "adminDashBoard",
  baseQuery: buildQueryWithReAuth(process.env.REACT_APP_ADMIN_API_URL),
  endpoints(builder) {
    return {
      getDashboard: builder.mutation<
        Result<DashboardResponse>,
        {
          start_time?: number;
          end_time?: number;
          region?: string;
        }
      >({
        query: ({ start_time, end_time, region }) => ({
          url: `statistics`,
          method: "POST",
          body: {
            start_time,
            end_time,
            region,
          },
        }),
        transformResponse: (res: Response<DashboardResponse>) => new Value(res),
      }),
    };
  },
});

export const { useGetDashboardMutation } = adminDashBoardApi;
