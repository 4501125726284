import { FC, useMemo, useCallback, useState, useEffect } from "react";
import { UserEntity } from "src/models/chatroom";
import {
  Dialog,
  Box,
  Typography,
  Divider,
  Avatar,
  IconButton,
} from "@mui/material";
import { PaperComponent } from "src/components/styled/Paper";
import bg from "src/images/user/profile_bg.png";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import { useGetUserProfileMutation } from "src/service/api/auth-api";
import { UserProfile } from "src/models/user";

interface Props {
  open: boolean;
  user: UserEntity | null;
  onClickClose: () => void;
}

const ProfileDialog: FC<Props> = ({ open, user, onClickClose }) => {
  const { t, i18n } = useTranslation();
  const [getUserProfile] = useGetUserProfileMutation();
  const [userInfo, setUserInfo] = useState<UserProfile>();

  const gethUserInfo = useCallback(() => {
    getUserProfile({ userId: user?.user_id || "" })
      .unwrap()
      .then((res) => {
        if (res && !res.isFailed && res.result) {
          const userProfile = res?.result?.user_profile;
          setUserInfo(userProfile);
        }
      });
  }, [getUserProfile, user?.user_id]);

  useEffect(() => {
    gethUserInfo();
  }, [gethUserInfo]);
  const userName = useMemo(() => {
    return (
      (i18n.language === "zh-CN" ? user?.user_name_cn : user?.user_name) ||
      user?.user_id
    );
  }, [user, i18n]);
  return (
    <Dialog open={open} maxWidth="lg" PaperComponent={PaperComponent}>
      <Box
        sx={{
          width: "480px",
          display: "flex",
          flexDirection: "column",
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: "160px",
            backgroundImage: `url(${bg})`,
            backgroundSize: "100% 100%",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            position: "relative",
          }}
        >
          <IconButton
            aria-label="close"
            sx={{
              width: "32px",
              height: "32px",
              position: "absolute",
              right: 8,
              top: 10,
              color: "#fff",
              background: "rgb(0,0,0,0.5)",
              "&:hover": {
                background: "rgb(0,0,0,0.5)",
              },
            }}
            onClick={onClickClose}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "0 20px",
          }}
        >
          <Box
            sx={{
              width: "100px",
              height: "100px",
              borderRadius: "50%",
              background: "#fff",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              transform: "translateY(-50px)",
              flex: "none",
            }}
          >
            <Avatar
              sx={{
                width: "92px",
                height: "92px",
              }}
              src={user?.user_avatar}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              transform: "translateY(-50px)",
              width: "100%",
            }}
          >
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 700,
                marginBottom: "10px",
              }}
            >
              {userName}
            </Typography>
            <Typography
              sx={{
                width: "100%",
                fontSize: "14px",
                fontWeight: 400,
                height: "150px",
                overflow: "scroll",
              }}
            >
              {Boolean(userInfo?.personalized_signature) ? <Typography>{userInfo?.personalized_signature}</Typography> : <Typography>{t("app.user.no_signature")}</Typography>}
            </Typography>
          </Box>

          <Divider sx={{ width: "100%" }} />
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: 400,
              height: "46px",
              lineHeight: "46px"
            }}
          >
            {
              user?.user_id?.endsWith('_com') ? t("app.user.international") : t("app.user.china")
            }
          </Typography>
        </Box>
      </Box>
    </Dialog>
  );
};

export default ProfileDialog;
