import React, { useCallback, SyntheticEvent } from "react"
import { Box, Avatar } from '@mui/material';
import { ChatMessage } from 'src/models/chat';
import { WebsocketSendMessage } from "src/models/chat";
import { MessageType, getDisplayUserName, welcomeMessageID } from 'src/tools/utils/chatUtil';
import { useTranslation } from 'react-i18next';
import icoPlay from "src/images/chatDialog/ico-play.svg";
import { UserEntity } from "src/models/chatroom";
import DefaultPicture from '../../images/defaultIcons/default-pic-post.png';

interface Props {
  row: ChatMessage,
  previewPicture: (str: string) => void,
  previewVideo: (str: string) => void,
  isOwn: boolean,
}

const TextMessage = ({ row, previewPicture, previewVideo, isOwn }: Props) => {
  const { t, i18n } = useTranslation();

  const formatText = (text: string, mentionList: UserEntity[]) => {
    if (mentionList.length === 0) {
      return text;
    } else {
      // @ts-ignore
      const regex = /@([^@\u{2004}]+)["\u{2004}"]/gu;
      let replacedText = text;
      let match;
      while ((match = regex.exec(text)) !== null) {
        const replacement = `<span style="font-weight: 600">${match[0]}</span>`;
        replacedText = replacedText.replace(match[0], replacement);
      }
      return replacedText;
    }
  }

  const handleImageLoadError = useCallback((e: SyntheticEvent<HTMLImageElement>) => {
    const target = e.target as HTMLImageElement;
    if (target) {
      if (!target.src?.includes(DefaultPicture)) {
        target.src = DefaultPicture;
      }
    }
  }, [])

  return (
    <>
      {
        // 如果有origin_message， 则表示是引用的样式
        row.origin_message
          ?
          <Box
            sx={{
              background: isOwn ? "rgba(217, 239, 255, 1)" : "#E9EDF2",
              borderRadius: isOwn ? "10px 0 10px 10px" : "0 10px 10px 10px",
              maxWidth: "350px",
              padding: "10px",
              textAlign: 'left'
            }}
          >
            <Box sx={{
              lineHeight: '18px', fontFamily: "SF Pro Text",
              fontSize: '14px', fontWeight: '400',
              wordBreak: "break-word",
              whiteSpace: "pre-line"
            }}>
              <Box sx={{ color: "#666666", fontStyle: 'italic', display: "flex", alignItems: 'flex-end', gap: '6px' }}>
                {(row.origin_message as WebsocketSendMessage).type === MessageType.Text &&
                  <>
                    {
                      <Box
                        component="span"
                        sx={{
                          WebkitLineClamp: 5,
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          display: '-webkit-box',
                          '-webkit-box-orient': 'vertical',
                          paddingRight: '3px'
                        }}
                        dangerouslySetInnerHTML={{
                          __html: `${getDisplayUserName(row.origin_message, i18n)}:&nbsp;${(row.origin_message as WebsocketSendMessage)?.content.replace(/ /g, '&nbsp;')}`
                        }}
                      />
                    }
                  </>
                }
                {(row.origin_message as WebsocketSendMessage).type === MessageType.Image &&
                  <>
                    {getDisplayUserName(row.origin_message, i18n)}:
                    {
                      <>
                        <Avatar
                          sx={{
                            width: "60px !important",
                            height: "60px !important",
                            cursor: "pointer",
                          }}
                          variant="square"
                          src={(row.origin_message as WebsocketSendMessage)?.content}
                          onClick={() => previewPicture((row.origin_message as WebsocketSendMessage).content)}
                          onError={handleImageLoadError}
                        />
                      </>
                    }
                  </>
                }
                {(row.origin_message as WebsocketSendMessage).type === MessageType.Video &&
                  <>
                    {getDisplayUserName(row.origin_message, i18n)}:
                    {
                      <Box
                        sx={{ position: 'relative', cursor: 'pointer' }}
                        onClick={() => previewVideo((row.origin_message as WebsocketSendMessage)?.content)}
                      >
                        <img src={(row.origin_message as WebsocketSendMessage)?.thumbnail} width={60} height={60} />
                        <Box sx={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          <img src={icoPlay} style={{ width: '18px', height: '18px' }} />
                        </Box>
                      </Box>
                    }
                  </>
                }
              </Box>
              <Box sx={{ width: "100%", borderBottom: "1px solid #D7DCE3", margin: "10px 0" }}></Box>
              <Box
                component="span"
                sx={{
                  color: "#000000",
                  borderRadius: "0 10px 10px 10px",
                  width: "fit-content",
                  maxWidth: "350px",
                  fontSize: '14px',
                  textAlign: "left",
                  wordBreak: "break-word",
                  fontWeight: '400',
                  whiteSpace: "pre-line",
                  lineHeight: '18px',
                }}
                dangerouslySetInnerHTML={{
                  __html: row.mentions && row.mentions.length > 0
                    ? formatText(row?.content?.replace(/ /g, '&nbsp;'), row.mentions)
                    : row?.content?.replace(/ /g, '&nbsp;')
                }}
              />
            </Box>
          </Box>
          :
          <Box
            component="span"
            sx={{
              color: "#000000",
              background: isOwn ? "rgba(217, 239, 255, 1)" : "#E9EDF2",
              borderRadius: isOwn ? "10px 0 10px 10px" : "0 10px 10px 10px",
              width: "fit-content",
              maxWidth: "350px",
              padding: "8px 12px",
              fontSize: '14px',
              textAlign: "left",
              wordBreak: "break-word",
              fontWeight: '400',
              whiteSpace: "pre-line",
              lineHeight: '18px',
              fontFamily: "SF Pro Text",
            }}
            dangerouslySetInnerHTML={{
              __html: row.mentions && row.mentions.length > 0
                // 为了不让多个连续空格合并，所以将空格替换成&nbsp;
                ? formatText(row?.content?.replace(/ /g, '&nbsp;'), row.mentions)
                : row.id === welcomeMessageID ? row?.content : row?.content?.replace(/ /g, '&nbsp;')
            }}
          />
      }
    </>
  )
}


export default TextMessage;