import React from 'react'
import { BrowserRouter, Route } from 'react-router-dom'
import { RouteOption } from './types'
import { isCN, mobileRedirect } from 'src/tools/utils/appUtil';
import { RouterLocationProvider } from 'src/providers/RouterLocationProvider';
import Routes from './Routes'

interface RouterProps {
  fallback?: React.ReactNode
}

const buildElement = (opt: RouteOption) => {
  const Component = opt.component;
  if (opt.layout) {
    const Layout = opt.layout;
    return <Layout><Component /></Layout>
  } else {
    return <Component />;
  }
}

const Routers: React.FC<RouterProps> = () => {
  const redirectResult = React.useMemo(() => {
    // return mobileRedirect();
    return false;
  }, []);
  React.useEffect(() => {
    if (redirectResult === true) {
      window.location.href = '/about-us';
      // navigate('/');
    }
  }, [redirectResult]);

  React.useEffect(() => {
    if (isCN) {
      document.title = "随迹";
    }
  }, [])

  if (redirectResult) {
    return (<></>)
  } else {
    return (
      <Routes />
    )
  }
}


const WrapRouter: React.FC<RouterProps> = ({ fallback }) => {

  return (
    <React.Suspense fallback={fallback ?? null}>
      <BrowserRouter>
        <RouterLocationProvider>
          <Routers />
        </RouterLocationProvider>
      </BrowserRouter>
    </React.Suspense>
  )
}

export default WrapRouter
