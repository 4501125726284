import { Box, Link, Skeleton } from "@mui/material";
import { FC, useEffect, useState, useRef, useCallback } from "react";
import { openApp } from "./openApp";
import { useTranslation } from "react-i18next";
import { useSelector } from "src/tools/toolkit/store";
import IcoLogo from "src/images/header/ico-logo.svg";

const openBtnStyles = {
  height: "32px",
  backgroundColor: "rgba(235, 245, 255, 1)",
  borderRadius: "16px",
  fontFamily: 'Helvetica Neue',
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: '12px',
  textAlign: "center",
  color: "rgba(0, 0, 0, 1)",
  cursor: "pointer",
  textTransform: "none",
  textDecoration: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: '6px',
  border: "none",
  padding: '0 9px'
}

const OpenAppBtn = () => {
  const { t } = useTranslation();
  const { isPromotion } = useSelector(state => state.promotion);

  return (
    <Link onClick={() => openApp(false, {})} sx={openBtnStyles}
    >
      <img src={IcoLogo} width={24} height={24} />
      {t(isPromotion ? "app.button.download" : "app.button.open")}
    </Link>
  )
}

export default OpenAppBtn;