import { Box, Link, Skeleton, Drawer } from "@mui/material";
import { FC, useEffect, useState, useRef, useCallback, useMemo } from "react";
import WechatOpenAppBtnNew from "./WechatOpenAppBtnNew";
import { openApp } from "./openApp";
import { configWx } from 'src/tools/utils/wechat'
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { isWeixinBrowser } from "../../tools/utils/appUtil";
import { useSnackbar } from "notistack";
import Axios from 'axios';
import Logo from 'src/images/logo/logo36.png';
import { useTranslation } from "react-i18next";
import { useSelector } from "src/tools/toolkit/store";
import getSignature from "src/service/api/wechatSignature-api";
import IcoMenu from "src/images/header/ico-menu.svg";
import IcoSearch from "src/images/header/ico-search.svg";
import IcoLogo from "src/images/header/ico-logo.svg";
import OpenAppBtn from "./OpenAppBtn";
import { paths } from "src/app-config";
import { useNavigate } from 'react-router-dom';
import CollapsedMenuList from "./CollapsedMenuList";

// 微信公众平台的 app id 和 app secret
export const WX_PUBLIC_APP_ID = "wxe872a1db0f540ca4";
export const OpenAppBoxHeight = '50px';


const OpenAppBox: FC = () => {
  const initWexinRef = useRef<boolean>(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  // const theme = useTheme();
  // const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const isDev = process.env.REACT_APP_ENV === 'dev';
  const { isPromotion } = useSelector(state => state.promotion);
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const pathName = location.pathname;

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  }

  useEffect(() => {
    if (isWeixinBrowser && initWexinRef.current === false) {
      initWexinRef.current = true
      getSignature(setLoading);
    }
  }, [isWeixinBrowser])

  const showMessageOnDev = (message: string, variant: "info" | "warning" | "error" | "success") => {
    if (isDev) {
      enqueueSnackbar(message, { variant });
    }
  }

  const get_signature = async () => {
    setLoading(true);
    showMessageOnDev('start to request /wechat-signature', 'info')
    const configUrl = window.location.href.split('#')[0];
    Axios.create({
      baseURL: 'https://global-rest.anylocal.cn',
      headers: {
        'Content-Type': 'application/json'
      },
    }).post<any>('/wechat/wechat-signature', {
      url: configUrl
    })
      .then(resp => {
        setLoading(false);
        if (resp && resp.data && resp.status === 200) {
          const res = resp.data.data;
          /** 返回res示例:
            appid: "wxe872a1db0f540ca4"
            nonceStr: "kvi7ng"
            signature: "f258c91db91854fb4c6d6fa57b83d1de942f4e3d"
            timestamp: 1681454905
            jsApiTicket: "LIKLckvwlJT9cWIhEQTwfNO6JRD5-7zyQOfNh9PRM8LGpv5HoUesfLg1Lomd54YyPbf5msNn-FmYL9bsbXLEmA"
          */
          if (res) {
            configWx(WX_PUBLIC_APP_ID, res.timestamp, res.nonceStr, res.signature)
            showMessageOnDev('Get Wechat signature success:' + JSON.stringify(resp), 'success');

            /* for test
            const signature_param: SignatureParams = {
              appId: WX_PUBLIC_APP_ID,
              timestamp: res.timestamp,
              nonceStr: res.nonceStr,
              url: configUrl,
              jsApiTicket: res.jsApiTicket,
              secret: WX_PUBLIC_APP_SECRET,
            };
            console.log("signature_param->", signature_param);
            generateSignature(signature_param).then(signature => {
  
              console.log("signature->", signature);
              console.log("local signature === api signature? ", signature === res.signature );
              alert("signature 比较结果："+ (signature === res.signature));
            });
            */
          } else {
            showMessageOnDev('Get empty Wechat signature:' + JSON.stringify(resp), 'error');
          }
        } else {
          showMessageOnDev('Get Wechat signature failed:' + JSON.stringify(resp), 'error');
        }
      }).catch(error => {
        setLoading(false);
        showMessageOnDev('Get Wechat signature error:' + JSON.stringify(error), 'error');
      })
  }

  /*
  const configLocalSignature = async () => {
    const jsApiTicket = await getJsApiTicket(WX_PUBLIC_APP_ID, WX_PUBLIC_APP_SECRET);
    // const jsApiTicket = 'LIKLckvwlJT9cWIhEQTwfNO6JRD5-7zyQOfNh9PRM8LGpv5HoUesfLg1Lomd54YyPbf5msNn-FmYL9bsbXLEmA';

    const configUrl =  window.location.href.split('#')[0];
    // const paramUrl = encodeURIComponent(configUrl);
    const paramUrl = configUrl;
    const timeStamp = Math.floor(Date.now() / 1000);
    const noStr = Math.random().toString(36).slice(-6);
    const signature_param: SignatureParams = {
      appId: WX_PUBLIC_APP_ID,
      timestamp: timeStamp,
      nonceStr: noStr,
      url: paramUrl,
      jsApiTicket: jsApiTicket,
      secret: WX_PUBLIC_APP_SECRET,
    };
    console.log("signature_param->", signature_param);
    const signature = await generateSignature(signature_param);
    configWx(WX_PUBLIC_APP_ID, timeStamp, noStr, signature)
  }
  */

  /*
  useEffect(() => {
    configLocalSignature();
  }, [])
  */

  useCallback(() => {
    // 如果session storage 有 keyword 和 bd_vid 则发送下载action
  }, [])


  return (
    <Box sx={{
      width: "100%",
      height: OpenAppBoxHeight,
      background: "rgba(255, 255, 255, 1)",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "9px 12px",
      position: 'fixed',
      top: 0,
      boxSizing: "border-box",
      zIndex: 1001,
      borderBottom: 'solid 1px #eee'
    }}>
      <Box onClick={toggleDrawer(true)}>
        <img src={IcoMenu} width={18} height={16} />
      </Box>


      <Drawer open={open} onClose={toggleDrawer(false)}>
        <CollapsedMenuList closeDrawer={setOpen} />
      </Drawer>
      <Box>
        {isWeixinBrowser ? (
          loading
            ? <Skeleton variant="rounded" sx={{ width: "64px", height: "32px", borderRadius: "16px", }} />
            : <WechatOpenAppBtnNew />
        )
          :
          <OpenAppBtn />
        }
      </Box>
      <Box sx={{ cursor: 'pointer' }} onClick={() => navigate(paths.searchHistory)}>
        <img src={IcoSearch} width={22} height={22} />
      </Box>
    </Box >
  )
}

export default OpenAppBox;