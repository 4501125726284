import { type FC } from "react";
import {
  Avatar,
  Dialog,
  IconButton,
  useMediaQuery,
  useTheme,
  Typography,
  Box,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { UserEntity } from "src/models/chatroom";
import { useTranslation } from "react-i18next";

interface Props {
  user: UserEntity | null;
  open: boolean;
  handleClose: () => void;
}

const PreviewAvatar: FC<Props> = ({ user, open, handleClose }) => {
  const theme = useTheme();
  const { i18n } = useTranslation();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            backgroundColor: "transparent",
            boxShadow: "none",
          },
        }}
        sx={{
          "& .MuiDialog-container": {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          },
          "& .MuiBackdrop-root": {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          },
        }}
      >
        <Box
          sx={{
            padding: 0,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <Avatar
            src={user?.user_avatar}
            alt="User Avatar"
            style={{
              width: isMobile ? "300px" : "500px",
              height: isMobile ? "300px" : "500px",
            }}
          />
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: 700,
              color: "#FFFFFF",
            }}
          >
            {i18n.language === "zh-CN" ? user?.user_name_cn : user?.user_name || user?.user_name_cn}
          </Typography>
        </Box>
      </Dialog>
      {open && (
        <IconButton
          onClick={handleClose}
          style={{
            position: "fixed",
            top: 8,
            left: 8,
            color: "#fff",
            zIndex: 1301,
            background: "#333333",
            borderRadius: "50%",
          }}
        >
          <CloseIcon />
        </IconButton>
      )}
    </>
  );
};

export default PreviewAvatar;
