import { SimpleUser } from "./user";

export interface Collection {
  collection_id: string;
  collection_name: string;
  collection_avatar: string;
  cover: string;
  collection_type: CollectionTypeEnum;
  create_time: number;
  default: true;
  description: string;
  message_count: number;
  owner: SimpleUser;
  owner_id: string;
  privacy: number;
  update_time: number;
  message_images?: string[];
  to_all_people: boolean;
}

export enum CollectionTypeEnum {
  User = "user",
  Post = "post",
  Media = "media",
  Folder = "folder",
  Collection = "collection",
}
