import { isCN } from 'src/tools/utils/appUtil';

export interface Locale {
  lang: string;
  label?: string;
  country: string;
  title?: string;
  icon?: string;
}

export const defaultLanguage = isCN ? "zh-CN" : "en";

export const defaultLanguages: Locale[] = [
  {
    lang: "en",
    label: "English",
    country: "US",
    title: "Language",
    icon: "🇺🇸",
  },
  {
    lang: "zh-CN",
    label: "中文简体",
    country: "CN",
    title: "语言",
    icon: "🇨🇳",
  },
];
