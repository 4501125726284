import { appConfig } from "src/app-config";
import { MessageTypeEnum } from "src/models/post";
import { isCN } from "src/tools/utils/appUtil";
import { isWeixinBrowser } from "src/tools/utils/appUtil";
import { CollectDownloadAction, } from 'src/hooks/landing/baidu';
import { PROMOTION } from 'src/hooks/landing/const';

export interface ParamsObj {
  message_id?: string,
  user_id?: string,
  request_id?: string,
  room_id?: string,
}

// export function configWx(appId, timestamp, nonceStr, signature) {
//   wx.config({
//     debug: false,
//     appId,
//     timestamp,
//     nonceStr,
//     signature,
//     jsApiList: [
//       'updateAppMessageShareData',
//       'updateTimelineShareData'
//     ],
//     openTagList: ["wx-open-launch-app"],
//   });
//   wx.ready(function () {
//     console.log("wechat readied");
//   });
//   wx.error(function (res) {
//     // console.log("wechat error:", res);
//     console.error('wechat config error: ' + JSON.stringify(res))
//   });
// }


// interface initWeChatShareOptions {
//   title?: string,
//   desc?: string,
//   link?: string,
//   imgUrl?: string,
// }

// export const initWeChatShare = (options?: initWeChatShareOptions) => {
//   const shareTitle = options?.title ? options.title : i18n.t('app.title');
//   const shareDesc = options?.desc ? options.desc : i18n.t('app.desc');
//   const shareLink = options?.link ? options.link : getWeChatValidUrl(window.location.href);
//   const shareImgUrl = options?.imgUrl ? options.imgUrl : 'https://www.anylocal.cn/logo512.png';
//   wx.ready(function () {
//     // 自定义“分享给朋友”及“分享到QQ”按钮的分享内容
//     wx.updateAppMessageShareData({
//       title: shareTitle, // 分享标题
//       desc: shareDesc, // 分享描述
//       link: shareLink, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
//       imgUrl: shareImgUrl, // 分享图标
//       success: function () {
//         // 设置成功
//         console.log("wechat set success");
//       },
//     });
//     // 自定义“分享到朋友圈”及“分享到QQ空间”按钮的分享内容
//     wx.updateTimelineShareData({
//       title: `${shareTitle}  ${options?.desc ? options.desc : ''}`, // 分享标题
//       link: shareLink, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
//       imgUrl: shareImgUrl, // 分享图标
//       success: function () {
//         // 设置成功
//         console.log("wechat set success");
//       },
//     });
//   });
// };

export const getAppDownloadUrl = () => {
  if (isIOSBrowser()) {
    return isCN
      ? "https://apps.apple.com/cn/app/%E9%9A%8F%E8%BF%B9/id1519641523"
      : "https://apps.apple.com/us/app/anylocal/id1519641523";
  } else if (navigator.userAgent.indexOf("Android") > -1) {
    if (!isCN) {
      return "https://play.google.com/store/apps/details?id=com.sktlab.android.anylocal";
    } else {
      return getAndroidDownloadUrl();
    }
  }
  return `${window.location.origin}${appConfig.paths.aboutUs}`;
};

export const getAndroidDownloadUrl = () => {
  if (isWeixinBrowser) {
    return 'https://sj.qq.com/appdetail/com.sktlab.android.anylocal';
  } else {
    const userAgent = navigator.userAgent.toLowerCase();
    if (/huawei/.test(userAgent)) {
      return 'https://appgallery.huawei.com/#/app/C104824289';
    } else if (/vivo/.test(userAgent)) {
      return 'https://h5coml.vivo.com.cn/h5coml/appdetail_h5/browser_v2/index.html?appId=3635823';
    } else if (/xiaomi|mi/.test(userAgent)) {
      return 'https://app.mi.com/details?id=com.sktlab.android.anylocal';
    } else if (/oppo|heytapbrowser/.test(userAgent)) {
      // OPPO
      return 'https://app.cdo.oppomobile.com/home/detail?app_id=31506063';
    } else {
      return 'https://www.anylocal.cn/anylocal.apk';
    }
  }
}



export const isIOSBrowser = () => {
  return (
    navigator.userAgent.toLowerCase().indexOf("iphone") > -1 ||
    navigator.userAgent.toLowerCase().indexOf("ipad") > -1 ||
    // 从iOS13.5 开始，iPad设备获取的userAgent值中不再包含“iPad”字段了，而变成了“Macintosh”字段, 所以会识别成Mac OS.
    // 所以通过maxTouchPoints 和ontouchend来判断。
    (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1 && 'ontouchend' in document) ||
    navigator.userAgent.toLowerCase().indexOf("ipod") > -1
  );
};


export const IsIOSBrowser = isIOSBrowser();

// 非微信浏览器里点击打开app
export const openApp = (flag, paramsObj: ParamsObj) => {
  // 手机下载事件

  // iOS 平台
  const fromBaidu = sessionStorage.getItem(PROMOTION);
  let timer: number = 0;
  if (isIOSBrowser()) {
    try {
      if (fromBaidu) {
        CollectDownloadAction(() => {
          window.location.href = getAppDownloadUrl();
        });
      } else {
        if (flag) {
          window.location.href = openIOSAppLinkWithID(paramsObj);
        } else {
          window.location.href = openIOSAppLink();
        }
        timer = window.setTimeout(() => {
          window.location.href = getAppDownloadUrl();
        }, 2500);
      }
    } catch (e) {
      console.error(JSON.stringify(e));
    }


  } else if (navigator.userAgent.indexOf("Android") > -1) {
    // Android 平台
    if (fromBaidu) {
      CollectDownloadAction(() => {
        window.location.href = getAppDownloadUrl();
      });
    } else {
      if (flag) {
        window.location.href = openAndroidAppLinkWithID(paramsObj);
      } else {
        window.location.href = openAndroidAppLink();
      }
      // const iframe = document.createElement("iframe");
      // iframe.src = "anylocal://anylocal.com";
      // iframe.style.display = "none";
      // document.body.appendChild(iframe);
      timer = window.setTimeout(() => {
        window.location.href = getAppDownloadUrl();
      }, 2500);
    }
  } else {
    console.error("Can not open app in current browser");
  }
  if (timer) {
    document.addEventListener('visibilitychange', () => window.clearTimeout(timer), false)
  }
};

export const getFormatSearchParam = () => {
  // 所有的跳转链接global必须是最后一个参数, 因为手机端是用的'&global='截取的。
  const searchParams = window.location.search;
  const params = new URLSearchParams(window.location.search);
  const global = params.get('global');
  const isGlobalSource = window.location.host.endsWith("cn") && global === "1" ? 1 : 0;
  if (window.location.pathname === appConfig.paths.sharedUser || window.location.pathname === appConfig.paths.user) {
    return searchParams.replace("id=", "user_id=");
  } else if (window.location.pathname === appConfig.paths.details || window.location.pathname === appConfig.paths.webPostDetail) {
    return searchParams.replace("id=", "message_id=");
  } else if (window.location.pathname === appConfig.paths.sharedLocation || window.location.pathname === appConfig.paths.location) {
    return searchParams;
  } else if (window.location.pathname === appConfig.paths.sharedLocationArea || window.location.pathname === appConfig.paths.locationArea) {
    let index = searchParams.indexOf('&global=');
    let newURL = index > 0 ? searchParams.substring(0, index): searchParams;
    return newURL + `&message_type=9&global=${isGlobalSource}`;
  } else if (window.location.pathname === appConfig.paths.sharedCollection || window.location.pathname === appConfig.paths.collection) {
    return searchParams.replace("id=", "collection_id=");
  } else if (window.location.pathname === appConfig.paths.requestDetail) {
    return searchParams.replace("id=", "request_id=");
  } else if (window.location.pathname === appConfig.paths.chatroomList) {
    const params = new URLSearchParams(searchParams);
    const keywordValue = params.get('keyword');
    if (keywordValue) {
      const encodedKeyword = encodeURI(keywordValue);
      params.set('keyword', encodedKeyword);
    }
    const updatedSearchParams = "?" + params.toString();
    return updatedSearchParams + `&message_type=12&global=${isGlobalSource}`;
  }else {
    const pathnameSplit = window.location.pathname.split('/');
    if (pathnameSplit.length > 0) {
      const pathname = `/${pathnameSplit[1]}`;
      if (pathname === appConfig.paths.topic) {
        const value = pathnameSplit[2];
        return `?topic=${value}&global=${isGlobalSource}`;
      } else if (pathname === appConfig.paths.autoTopic) {
        const value = pathnameSplit[2];
        return `?auto_topic=${decodeURI(value)}&global=${isGlobalSource}`;
        // return searchParams.replace("id=", "collection_id=");
      }
    }

    return searchParams;
  }
};

const IOSAppUrlScheme = "anylocal://anylocal.com";

const openIOSAppLink = (): string => {
  const formatSearchParam = getFormatSearchParam();
  return IOSAppUrlScheme + formatSearchParam;
};

export const openIOSAppLinkWithID = (paramsObj: ParamsObj): string => {
  let searchParam = '';
  if (paramsObj.message_id) {
    searchParam = `?message_id=${paramsObj.message_id}`;
  } else if (paramsObj.user_id) {
    searchParam = `?user_id=${paramsObj.user_id}`;
  } else if (paramsObj.request_id) {
    searchParam = `?request_id=${paramsObj.request_id}`;
  } else if (paramsObj.room_id) {
    searchParam = `?room_id=${encodeURI(paramsObj.room_id)}&message_type=12`;
  }

  return IOSAppUrlScheme + searchParam;
}

const hrefStart =
  "intent://#Intent;scheme=anylocal;category=android.intent.category.DEFAULT;category=android.intent.category.BROWSABLE;";
const hrefEnd = "end";

export const openAndroidAppLinkWithID = (paramsObj: ParamsObj): string => {
  let appendHref = "";
  if (paramsObj.message_id) {
    const message_type = MessageTypeEnum.Message;
    appendHref = `S.message_id=${paramsObj.message_id};S.message_type=${message_type}`
  } else if (paramsObj.user_id) {
    appendHref = `S.user_id=${paramsObj.user_id}`;
  } else if (paramsObj.request_id) {
    // Confirm with Android side, request message type is 11.
    appendHref = `S.message_id=${paramsObj.request_id};S.message_type=11`;
  } else if (paramsObj.room_id) {
    appendHref = `S.room_id=${encodeURI(paramsObj.room_id)};S.message_type=12`;
  }
  const href = hrefStart + (appendHref ? appendHref + ";" : "") + hrefEnd;
  console.log("android app href: ", href);
  return href;
}

export const openAndroidAppLink = (): string => {
  let appendHref = "";
  if (window.location.pathname === appConfig.paths.sharedUser || window.location.pathname === appConfig.paths.user) {
    const userId = getParamFromUrl("id");
    appendHref = `S.user_id=${userId}`;
  } else if (window.location.pathname === appConfig.paths.details || window.location.pathname === appConfig.paths.webPostDetail) {
    const message_id = getParamFromUrl("id");
    const message_type =
      getParamFromUrl("message_type") || MessageTypeEnum.Message;
    appendHref = `S.message_id=${message_id};S.message_type=${message_type}`;
  } else if (window.location.pathname === appConfig.paths.sharedLocation || window.location.pathname === appConfig.paths.location) {
    const loc_id = getParamFromUrl("loc_id");
    const loc_name = getParamFromUrl("loc_name");
    appendHref =
      `S.loc_id=${loc_id}` + (loc_name ? `;S.loc_name=${loc_name}` : "");
  } else if (window.location.pathname === appConfig.paths.sharedLocationArea || window.location.pathname === appConfig.paths.locationArea) {
    const message_id = getParamFromUrl("message_id");
    const loc_name = getParamFromUrl("loc_name");
    if (message_id) {
      const level = getParamFromUrl("level");
      appendHref = `S.message_id=${message_id};S.loc_name=${loc_name};S.level=${level};S.message_type=9`;
    } else {
      appendHref = `S.loc_name=${loc_name};S.message_type=9`;
    }
  } else if (window.location.pathname === appConfig.paths.sharedCollection || window.location.pathname === appConfig.paths.collection) {
    const collectionId = getParamFromUrl("id");
    appendHref = `S.collection_id=${collectionId}`;
  } else if (window.location.pathname === appConfig.paths.requestDetail) {
    const requestId = getParamFromUrl("id");
    appendHref = `S.request_id=${requestId}`;
  } else if (window.location.pathname === appConfig.paths.chatroomList) {
    const keyword = getParamFromUrl("keyword");
    const type = getParamFromUrl("type");
    const gps = getParamFromUrl("gps");
    appendHref = `S.type=${type};S.gps=${gps};S.message_type=12${keyword ? `;S.keyword=${encodeURI(keyword)}` : ''}`;
  } else {
    const pathnameSplit = window.location.pathname.split('/');
    if (pathnameSplit.length > 0) {
      const pathname = `/${pathnameSplit[1]}`;
      if (pathname === appConfig.paths.topic) {
        const value = pathnameSplit[2];
        appendHref = `S.message_id=${value};S.message_type=10;S.sub_type=topic`;
      } else if (pathname === appConfig.paths.autoTopic) {
        const value = pathnameSplit[2];
        appendHref = `S.message_id=${decodeURI(value)};S.message_type=10;S.sub_type=tag`;
      }
    }
  }
  const href = hrefStart + (appendHref ? appendHref + ";" : "") + hrefEnd;
  console.log("android app href: ", href);
  return href;
};

export const getParamFromUrl = (key: string) => {
  const params = new URLSearchParams(window.location.search);
  return params.get(key);
};
