import { createApi } from "@reduxjs/toolkit/query/react";
import { Response, Result, Value } from "../../models/common";
import { MessageTypeEnum } from "../../models/post";
import { baseQueryWithReauth } from "../../tools/toolkit/helper";
import {
  SendRequestParamEntity,
  UpdateRequestParamEntity,
} from "../../models/request";

export const requestApi = createApi({
  reducerPath: "request",
  baseQuery: baseQueryWithReauth,
  endpoints(builder) {
    return {
      getRequestLiveResponseList: builder.mutation<
        Result<any>,
        {
          parentMessageId: string;
          messageType: MessageTypeEnum;
          pageSize?: number;
          startKey?: string;
        }
      >({
        query: ({ messageType, parentMessageId, pageSize, startKey }) => ({
          url: `getmessage`,
          method: "POST",
          body: {
            action: "getmessage",
            pagesize: pageSize,
            forwardorder: false,
            countonly: false,
            startkey: startKey,
            message_type: messageType,
            parent_message_id: parentMessageId,
          },
        }),
        transformResponse: (res: Response<any>) =>
          new Value(res, "get_message_list"),
      }),

      acceptResponse: builder.mutation<
        Result<any>,
        { parentMessageId: string; messageId: string; messageType: number }
      >({
        query: ({ messageType, parentMessageId, messageId }) => ({
          url: `sendmessage`,
          method: "POST",
          body: {
            message_type: messageType,
            parent_message_id: parentMessageId,
            messageid: messageId,
          },
        }),
        transformResponse: (res: Response<any>) => new Value(res),
      }),
      rejectResponse: builder.mutation<
        Result<any>,
        { messageId: string; reason: string }
      >({
        query: ({ reason, messageId }) => ({
          url: `refuse_request`,
          method: "POST",
          body: {
            type: "response",
            reason: reason,
            message_id: messageId,
          },
        }),
        transformResponse: (res: Response<any>) => new Value(res),
      }),
      sendRequest: builder.mutation<
        Result<any>,
        { entity: SendRequestParamEntity }
      >({
        query: ({ entity }) => ({
          url: `sendmessage`,
          method: "POST",
          body: entity,
        }),
        transformResponse: (res: Response<any>) =>
          new Value(res, "send_message"),
      }),
      updateRequest: builder.mutation<
        Result<any>,
        { entity: UpdateRequestParamEntity }
      >({
        query: ({ entity }) => ({
          url: `updatemessage`,
          method: "POST",
          body: entity,
        }),
        transformResponse: (res: Response<any>) =>
          new Value(res, "send_message"),
      }),
      deleteRequest: builder.mutation<
        Result<any>,
        { messageType: MessageTypeEnum.Request; messageId: string }
      >({
        query: ({ messageType, messageId }) => ({
          url: `deletemessage`,
          method: "POST",
          body: {
            message_type: messageType,
            messageid: messageId,
          },
        }),
        transformResponse: (res: Response<any>) =>
          new Value(res, "deletemessage"),
      }),
      getCity: builder.mutation<
        Result<any>,
        {
          secretValue: string;
          expirationTime: number;
        }
      >({
        query: ({ secretValue, expirationTime }) => ({
          url: `get_city`,
          method: "POST",
          body: {
            secret_value: secretValue,
            expiration_time: expirationTime,
          },
        }),
        transformResponse: (res: Response<any>) => new Value(res, "location"),
      }),
      getCityQuery: builder.query<
        Result<any>,
        {
          secretValue: string;
          expirationTime: number;
        }
      >({
        query: ({ secretValue, expirationTime }) => ({
          url: `get_city`,
          method: "POST",
          body: {
            secret_value: secretValue,
            expiration_time: expirationTime,
          },
        }),
        transformResponse: (res: Response<any>) => new Value(res, "location"),
      }),
    };
  },
});

export const {
  useGetRequestLiveResponseListMutation,
  useAcceptResponseMutation,
  useRejectResponseMutation,
  useSendRequestMutation,
  useUpdateRequestMutation,
  useDeleteRequestMutation,
  useGetCityMutation,
  useLazyGetCityQueryQuery,
} = requestApi;
