import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { settings } from "../settings";
import { PROMOTION } from 'src/hooks/landing/const';


export interface PromotionState {
  isPromotion: boolean;
}


const _loadState = (): PromotionState => {
  return {
    isPromotion: !!sessionStorage.getItem(PROMOTION),
  }
}


const promotionSlice = createSlice({
  name: settings.keys.video,
  initialState: _loadState(),
  reducers: {
    setPromotion(state, action: PayloadAction<boolean>) {
      state.isPromotion = action.payload;
    },
  }
})

export const {
  setPromotion
} = promotionSlice.actions;
export default promotionSlice.reducer;