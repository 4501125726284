import UAParser from "ua-parser-js";
import { isWeixinBrowser } from "./appUtil";
export const isMobileDevice = () => {
  return (
    navigator.userAgent.match(/iPhone/i) ||
    navigator.userAgent.match(/Android/i) ||
    navigator.userAgent.match(/iPod/i) ||
    navigator.userAgent.match(/iPad/i)
  );
};

export const isAppleMobileDevice = () => {
  return (
    navigator.userAgent.match(/iPhone/i) ||
    navigator.userAgent.match(/iPod/i) ||
    navigator.userAgent.match(/iPad/i)
  );
};

export const getExploreName = () => {
  var userAgent = navigator.userAgent;
  if (userAgent.indexOf("Opera") > -1 || userAgent.indexOf("OPR") > -1) {
    return "Opera";
  } else if (
    userAgent.indexOf("compatible") > -1 &&
    userAgent.indexOf("MSIE") > -1
  ) {
    return "IE";
  } else if (userAgent.indexOf("Edge") > -1) {
    return "Edge";
  } else if (userAgent.indexOf("Firefox") > -1) {
    return "Firefox";
  } else if (
    userAgent.indexOf("Safari") > -1 &&
    userAgent.indexOf("Chrome") === -1
  ) {
    return "Safari";
  } else if (
    userAgent.indexOf("Chrome") > -1 &&
    userAgent.indexOf("Safari") > -1
  ) {
    return "Chrome";
  } else if ("ActiveXObject" in window) {
    return "IE>=11";
  } else {
    return "Unkonwn";
  }
};

export const isSafari = (): boolean => {
  const parser = new UAParser().getBrowser();
  return parser.name === "Safari" || parser.name === "Mobile Safari";
};

export const isWebview = (): boolean => {
  if (isWeixinBrowser) {
    return false;
  }
  if (typeof window === undefined) {
    return false;
  }

  let navigator = window.navigator;

  const standalone =
    "standalone" in window.navigator && window.navigator["standalone"];
  const userAgent = navigator.userAgent.toLowerCase();
  const safari = /safari/.test(userAgent);
  const ios = /iphone|ipod|ipad|macintosh/.test(userAgent);
  const ios_ipad_webview = ios && !safari;

  return ios
    ? (!standalone && !safari) || ios_ipad_webview
    : userAgent.includes("wv");
};

export const iosDetect = (deviceModel: string): boolean => {
  return deviceModel.startsWith('iPhone') || deviceModel.startsWith('iPad')
}