import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";

const CancelButton = styled(Button)({
    borderRadius: "20px",
    border: "1px solid #ddd",
    height: "40px",
    minWidth: "88px",
    padding: "10px 19px",
    color: "#aaa",
    textAlign: "center",
    fontSize: "14px",
    textTransform: "none",
})

export default CancelButton;