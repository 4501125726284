import * as React from "react";
import { CircularProgress, Link, Tab, Tabs, useMediaQuery, useTheme, } from "@mui/material";
import { Box, IconButton } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import CloseIcon from '@mui/icons-material/Close';
import { useSnackbar } from 'notistack'
import { useDispatch } from "react-redux";
import { useSelector } from "../../../tools/toolkit/store";
import { setIdentity } from "../../../service/redux/app-slice";
import { Result } from "../../../models/common";
import { Loader } from "../../loader";
import { closeSignInUpDialog } from "../../../service/redux/signinup-slice";
import { useGenerateQRCodeMutation, useGenerateTokenMutation, useGetUserProfileMutation, useLoginByQRCodeMutation } from "../../../service/api/auth-api";
import QRCode from 'qrcode.react';
import IcoRefresh from '../../../images/login/ico-refresh.png';
import { UserProfile } from "../../../models/user";
import { setRefreshToken, setToken } from "../../../service/redux/access-slice";
// import { getCountryName } from "src/tools/utils/locationUtil";
import { CustomTab, CustomTabs, TabPanel } from "src/components/tab/tab";
import SignInForm from "./SignInForm";
import Crypto from "crypto-js";
import { useGetCityMutation } from "src/service/api/request-api";
import { useTranslation } from 'react-i18next';
import { isCN, onTableOrMobile } from 'src/tools/utils/appUtil';
import BackButton from "src/components/button/BackButton";
import ForgotPasswordContent from "src/pages/settings/ForgotPasswordContent";
import { makeStyles } from "tss-react/esm/mui";
import SignUpForm from "./SignUpForm";
import { PaperComponent } from "src/components/styled/Paper";
import { useGetUserInterestsMutation } from "src/service/api/questionnaire-api";
import { QuestionnaireEntity } from "src/models/questionnaire";
import { useNavigate } from "react-router-dom";
import { appConfig } from "src/app-config";

const isOnTableOrMobile = onTableOrMobile();

const useStyles = makeStyles()((theme) => {
  return {
    loadingBox: {
      margin: "200px",
      [theme.breakpoints.down("sm")]: {
        margin: "200px 50px"
      }
    },
    contentBox: {
      width: '480px',
      minHeight: '690px',
      height: "690px",
      paddingBottom: "27px",
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'flex-start',
      [theme.breakpoints.down('sm')]: {
        width: '350px',
      }
    },
    content: {
      width: "100%",
      height: "100%",
      display: "flex",
      flexDirection: "column"
    },
    signUpTitle: {
      position: "relative",
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginTop: "10px",
      marginBottom: "26px",
      fontFamily: "Helvetica Neue",
      fontSize: "18px",
      fontWeight: 500,
    },
    loginBox: {
      flexGrow: 1,
      width: "100%",
      display: "flex",
      alignItems: "end",
      justifyContent: "center"
    },
    bottomBox: {
      flexGrow: 1,
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "end"
    },
    loginText1: {
      fontFamily: "Helvetica Neue",
      fontSize: "14px",
      fontWeight: 400,
    },
    loginText2: {
      fontFamily: "Helvetica Neue",
      fontSize: "14px",
      fontWeight: 400,
      color: "#3A92F9",
      textDecoration: "underline",
      cursor: "pointer"
    },
    formBox: {
      width: "100%",
      maxWidth: '100%',
      padding: "0 20px",
      // [theme.breakpoints.down('sm')]: {
      //   padding: "0px",
      // }
    }
  }
})

const enum SignPage {
  SignIn = 0,
  SignUp = 1,
  ForgetPassword = 2
}

interface PropsForm {
  onSignInSuccess?: () => void;
  onClickClose?: () => void;
}

interface LoginResponse {
  uuid: string,
  expiration_time: number,
  client_ip: string,
  authorization: string,
  refresh_token: string,
  user_id: string,
  is_expiration: boolean,
}

const SignInUpDialog: React.FC<PropsForm> = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { classes } = useStyles();
  const { onSignInSuccess, onClickClose } = props;
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { showSignInDialog } = useSelector(state => state.signinup);
  const [QRCodeLink, setQRCodeLink] = React.useState('');
  const [UUID, setUUID] = React.useState('');
  const [isQRCodeExpired, setIsQRCodeExpired] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const [stopLoop, setStopLoop] = React.useState(false);
  const [loopCount, setLoopCount] = React.useState(0);
  const [generatingQRCode, setGeneratingQRCode] = React.useState(true);
  const [tabValue, setTabValue] = React.useState(0);
  const [locationName, setLocationName] = React.useState('');
  const [page, setPage] = React.useState<SignPage>(SignPage.SignIn);
  const HASH_KEY = 'AnyLocalValid';
  const FLAG_QUESTIONNAIRE_OPENED = "questionnaire_opened";


  const [getCityRequest] = useGetCityMutation();
  const [getQRCode] = useGenerateQRCodeMutation();
  const [generateToken] = useGenerateTokenMutation();
  const [loginByQRCode] = useLoginByQRCodeMutation();
  const [getUserInterest, { isLoading: loadingGet }] = useGetUserInterestsMutation();
  const [getUserProfile, { isLoading: loadingUser }] = useGetUserProfileMutation();

  React.useEffect(() => {
    getLocationByApi();
  }, [])

  React.useEffect(() => {
    startLoopLogin();
  }, [UUID, loopCount])

  const getLocationByApi = React.useCallback(async () => {
    const expirationTime = Math.trunc(Date.now() / 1000) + 7200;
    const hash = window.btoa(HASH_KEY) + expirationTime.toString();
    const result = Crypto.algo.SHA256.create().update(hash).finalize();
    const secretValue = result.toString(Crypto.enc.Base64);
    const response = await getCityRequest({ secretValue, expirationTime }).unwrap();
    let location = '';
    if (response && !response.isFailed && response.result) {
      const data = response.result;
      location = data.city + "," + data.country;
      setLocationName(location);
    }
    getQRCodeData(location);
  }, [getCityRequest])

  const getQRCodeData = (locationName: string) => {
    setStopLoop(false);
    setLoopCount(0);
    getQRCode({ locationDesc: locationName }).unwrap().then(res => {
      if (res && !res.isFailed && res.result) {
        setUUID(res.result.uuid);
        setQRCodeLink(res.result.login_link);
        setIsQRCodeExpired(false);
        setGeneratingQRCode(false);
      } else {
        setErrorMessage(res.message || 'Generate QR Code failed')
      }
    })
  }

  const startLoopLogin = () => {
    if (!UUID) {
      console.log(' loop: UUID empty return', UUID)
      return;
    }
    if (stopLoop) {
      console.log(' loop: stopLoop return')
      return;
    }
    if (tabValue === 1) {
      return;
    }
    setTimeout(() => {
      loginByQRCode({ uuid: UUID }).unwrap().then(res => {
        if (res && !res.isFailed && res.result) {
          const loginData: LoginResponse = res.result;
          const userId = loginData.user_id;
          const token = loginData.authorization;
          const refreshToken = loginData.refresh_token;// TODO
          if (loginData.is_expiration) {
            setIsQRCodeExpired(true);
            setStopLoop(true);
            setUUID('');
          } else if (userId && token) {
            setStopLoop(true);
            dispatch(closeSignInUpDialog());
            dispatch(setToken(token));
            dispatch(setRefreshToken(refreshToken));
            onSignInSuccess && onSignInSuccess();
            getUserProfileInfo(userId);
          } else {
            setStopLoop(false);
            setLoopCount(count => count + 1);
          }
        } else {
          setStopLoop(true);
        }
      })
    }, 3 * 1000)
  }

  const getUserProfileInfo = (userID: string) => {
    getUserProfile({ userId: userID })
      .unwrap()
      .then(goEnjoying)
      .finally(() => {
        // onClose()
        // window.location.reload()
      })
      .catch(error => { enqueueSnackbar("Can not get user information", { variant: "error" }) })
  }

  // store user info
  const goEnjoying = (res: Result<{ user_profile: UserProfile }>) => {
    if (res && !res.isFailed && res.result) {
      const userProfile = res?.result?.user_profile
      // dispatch(closeSignInUpDialog());
      localStorage.setItem('isLoggedIn', String(Date.now())); // 设置登录标识符
      dispatch(setIdentity(userProfile));
      if (isMobile || isOnTableOrMobile) {
        // If user is on table or mobile, need to redirect to user profile page.
        navigate(appConfig.paths.root);
      } else if (localStorage.getItem(FLAG_QUESTIONNAIRE_OPENED) !== "1") {
        getUserInterestData();
      }
    } else {
      enqueueSnackbar(res?.message || 'Get user info failed', { variant: 'error' });
    }
  }

  const getUserInterestData = () => {
    getUserInterest({}).unwrap().then(res => {
      if (res && !res.isFailed) {
        const data: QuestionnaireEntity = res.result.user_interesting;
        if (!data || ((!data.region || data.region?.length === 0) && (!data.type_content || data.type_content?.length === 0))) {
          navigate(appConfig.paths.interestQuestions);
          localStorage.setItem(FLAG_QUESTIONNAIRE_OPENED, "1");
        }
      }
    })
  }

  const handleClickCloseButton = () => {
    setStopLoop(true);
    dispatch(closeSignInUpDialog());
    onClickClose && onClickClose();
  }

  const handleRefreshQRCode = () => {
    getQRCodeData(locationName);
  }

  const simulateAppSideScan = () => {
    generateToken({ uuid: UUID })
    // .unwrap().then(res => {
    //   if (res && !res.isFailed) {
    //     enqueueSnackbar("Scan successfully")
    //   }
    // })
  }

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const passwordLoginTabContent = () => {
    return (
      <>
        {(loadingUser || loadingGet) ?
          <CircularProgress className={classes.loadingBox} />
          :
          <Box className={classes.formBox}>
            <SignInForm
              onLoginSuccess={(userId) => {
                onSignInSuccess && onSignInSuccess();
                getUserProfileInfo(userId);
              }}
              onClickSignUp={() => setPage(SignPage.SignUp)}
              onClickForgotPassword={() => setPage(SignPage.ForgetPassword)}
            />
          </Box>
        }
      </>
    )
  }

  const pageContent = () => {
    return (
      <>
        {page === SignPage.ForgetPassword &&
          <Box className={classes.content}>
            <Box sx={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "space-between", marginBottom: "37px" }}>
              <Box sx={{ marginLeft: "8px" }}>
                <BackButton handleClick={() => setPage(SignPage.SignIn)} />
              </Box>
              <IconButton onClick={() => {
                handleClickCloseButton()
              }}>
                <CloseIcon />
              </IconButton>
            </Box>
            <Box className={classes.formBox}>
              <ForgotPasswordContent onUpdateSuccess={() => {
                enqueueSnackbar(t("app.settings.account.update-password-success"), { variant: "success" });
                setPage(SignPage.SignIn);
              }} />
            </Box>
            <Box className={classes.loginBox}>
              <Box className={classes.loginText1}>{t("app.login.already-have-account")}</Box>&nbsp;
              <Box className={classes.loginText2} onClick={() => setPage(SignPage.SignIn)}>{t("app.login.sign-in")}</Box>
            </Box>
          </Box>
        }
        {page === SignPage.SignUp &&
          <Box className={classes.content}>
            <Box className={classes.signUpTitle}>
              {t("app.login.sign-up")}
              <IconButton
                sx={{ position: "absolute", right: 0 }}
                onClick={() => {
                  handleClickCloseButton()
                }}>
                <CloseIcon />
              </IconButton>
            </Box>
            <Box className={classes.formBox}>
              <SignUpForm onSignUpSuccess={() => {
                enqueueSnackbar(t("app.login.sign-up-success"), { variant: "success" });
                setPage(SignPage.SignIn);
              }} />
            </Box>
            <Box className={classes.loginBox}>
              <Box className={classes.loginText1}>{t("app.login.already-have-account")}</Box>&nbsp;
              <Box className={classes.loginText2} onClick={() => setPage(SignPage.SignIn)}>{t("app.login.sign-in")}</Box>
            </Box>
          </Box>
        }
        {page === SignPage.SignIn &&
          <>
            {
              isOnTableOrMobile &&
              <>{passwordLoginTabContent()}</>
            }
            {
              !isOnTableOrMobile &&
              <>
                <Box className={classes.content}>
                  <Box textAlign={'center'} sx={{ position: "relative", width: '100%', display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <CustomTabs value={tabValue} onChange={handleChange}>
                      <CustomTab label={t("app.login.scan-login")} />
                      <CustomTab label={t("app.login.password-login")} />
                    </CustomTabs>
                    <IconButton sx={{ position: "absolute", right: "10px", top: "0px" }} onClick={() => {
                      handleClickCloseButton()
                    }}>
                      <CloseIcon />
                    </IconButton>
                  </Box>

                  <TabPanel value={tabValue} index={0}>
                    <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", }}>
                      <Box sx={{ width: '200px', height: '200px', marginTop: '22px', position: "relative" }}>
                        {!QRCodeLink && generatingQRCode &&
                          <Box sx={{ display: 'flex', alignItems: "center", flexDirection: 'column', justifyContent: 'center', height: '100%', width: '100%' }}>
                            <CircularProgress sx={{ width: '40px !important', height: '40px !important' }} />
                            <Box sx={{ fontSize: '14px', color: '#666666', marginTop: '10px' }}>{t("app.loginDialog.generatingQRCode")}</Box>
                          </Box>

                        }
                        {QRCodeLink &&
                          <QRCode
                            id="qr_code_login"
                            value={QRCodeLink}
                            size={200}
                            fgColor="#65B1C1"
                          />
                        }

                        {!QRCodeLink && errorMessage &&
                          <Box sx={{ textAlign: 'center', verticalAlign: 'middle', color: '#666666' }}>{errorMessage}</Box>
                        }

                        {isQRCodeExpired &&
                          <Box sx={{ position: 'absolute', top: 0, width: '100%', height: '100%', background: 'rgba(255, 255, 255, 0.7)', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <img src={IcoRefresh} alt='refresh' style={{ width: '48px', height: '48px', cursor: 'pointer' }}
                              onClick={handleRefreshQRCode} />
                          </Box>
                        }
                      </Box>

                      {isQRCodeExpired ?
                        <Box sx={{ color: '#D0021B', fontFamily: 'Helvetica Neue', marginTop: '26px' }}>{t("app.loginDialog.QRCodeExpire")}</Box>
                        :
                        <Box sx={{ textAlign: 'left', fontFamily: 'Helvetica Neue', fontSize: '14px', fontWeight: "400", lineHeight: "30px", maxWidth: "400px" }}>
                          <Box sx={{ marginTop: '26px', fontSize: '16px', color: "#000", fontWeight: "500" }}>{t("app.loginDialog.scanCode")}</Box>
                          <Box sx={{}}>{t("app.loginDialog.scanCodeStep1")}</Box>
                          <Box sx={{}}>{t("app.loginDialog.scanCodeStep2")}</Box>
                          <Box sx={{}}>{t("app.loginDialog.scanCodeStep3")}</Box>
                          <Link href="/app/screenshot.jpg" target="_blank" sx={{ marginTop: '9px', color: '#3A92F9', fontWeight: "500" }}>{t("app.loginDialog.view-screenshot")}</Link>
                        </Box>
                      }

                    </Box>
                  </TabPanel>
                  <TabPanel value={tabValue} index={1}>
                    {passwordLoginTabContent()}
                  </TabPanel>
                </Box>
              </>
            }
            <Box className={classes.bottomBox}>

              <Box className={classes.loginBox} >
                <Box className={classes.loginText1}>{t("app.login.do-not-have-account")}</Box>&nbsp;
                <Box className={classes.loginText2} onClick={() => setPage(SignPage.SignUp)}>{t("app.login.sign-up")}</Box>

              </Box>
              <Box sx={{ fontFamily: 'Helvetica Neue', marginTop: "20px", fontSize: '12px', color: '#555555', lineHeight: '24px', textAlign: 'center', width: '100%' }}>
                {t("app.login.login-privacy-terms")}&nbsp;
                <Link href={isCN ? '/doc/terms-zh.html' : '/terms.pdf'}>{t("app.login.terms")}</Link>
                &nbsp;{t("app.login.and")}&nbsp;
                <Link href={isCN ? '/doc/privacy-zh.html' : '/privacy.pdf'}>{t("app.login.privacy")}</Link>
              </Box>
            </Box>
          </>
        }
      </>
    )
  }

  if (isOnTableOrMobile) {
    return (
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '70vh',
        maxWidth: '100%'
      }}>
        {pageContent()}
      </Box>
    )
  } else {
    return (
      <>
        <Dialog
          open={showSignInDialog}
          PaperComponent={PaperComponent}
        >
          <DialogContent className={classes.contentBox}>
            {pageContent()}
          </DialogContent>
        </Dialog>
        <Loader isLoading={loadingUser || loadingGet} />
      </>
    )
  }


}
export default SignInUpDialog;