import { FC } from 'react';
import { Box, Tab, Tabs } from "@mui/material";
import { styled } from '@mui/system';


interface TabPanelProps {
  children?: React.ReactNode;
  index: number | string;
  value: number | string;
}

export const TabPanel: FC<TabPanelProps> = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>{children}</Box>
      )}
    </div>
  );
}


export const CustomTab = styled(Tab)({
  color: '#717171',
  background: 'white',
  fontSize: '14px !important',
  textTransform: 'none',
  fontFamily: 'Helvetica Neue !important',
  '&.Mui-selected': {
    color: 'black',
  }
})



export const CustomTabs = styled(Tabs)({
  '& .MuiTabs-indicator': {
    backgroundColor: '#3A92F9',
  },
  '& .MuiTabs-flexContainer': {
    overflowX: "scroll"
  },
  '& ::-webkit-scrollbar': {
    display: 'none'
  },
})

