import { Box, TextField, useMediaQuery, useTheme, CircularProgress } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { styled } from '@mui/system'
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import ConfirmButton from "src/components/styled/ConfirmButton";
import { makeStyles } from "tss-react/esm/mui";
import { Controller, useForm } from "react-hook-form";
import { useForgetPasswordResetPasswordMutation, useForgetPasswordSendCodeMutation } from "src/service/api/auth-api";
import { useSelector } from "src/tools/toolkit/store";
import genRandom, { createHash } from "src/tools/utils/auth";
import OutlineButton from "src/components/styled/OutlineButton";
import ConfirmDialog from "src/components/dialog/confirm/ConfirmDialog";
import { PASSWORD_FORMAT } from "src/tools/utils/stringUtil";
import CommonTextField from "src/components/styled/CommonTextField";

const useStyles = makeStyles()((theme) => {
  return {
    rulesTitle: {
      color: "#555",
      fontFamily: "Helvetica Neue",
      fontSize: "14px",
      fontWeight: 700,
      marginTop: "15px",
      lineHeight: "24px"
    },
    rulesText: {
      color: "#555",
      fontFamily: "Helvetica Neue",
      fontSize: "12px",
      fontWeight: 400,
      lineHeight: "24px"
    },
    forgotPassword: {
      color: "#000",
      fontFamily: "Helvetica Neue",
      fontSize: "14px",
      fontWeight: 500,
      cursor: "pointer",
      "&:hover": {
        color: "#555"
      }
    },
  }
})

interface FormEditPasswordInputs {
  userId: string;
  verifyCode: string;
  newPassword: string;
  newPasswordConfirm: string;
}
interface Props {
  onUpdateSuccess: () => void;
}

const ForgotPasswordContent: FC<Props> = ({ onUpdateSuccess }) => {
  const theme = useTheme();
  const { credential } = useSelector(state => state.app);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [countdown, setCountdown] = useState(0);
  const [openAlertDialog, setOpenAlertDialog] = useState(false);
  const [dialogContent, setDialogContent] = useState("");
  const { control, handleSubmit, reset, watch } = useForm<FormEditPasswordInputs>({
    reValidateMode: "onBlur"
  });
  const [sendCode, { isLoading: loadingSend }] = useForgetPasswordSendCodeMutation();
  const [updatePassword, { isLoading: loadingUpdate }] = useForgetPasswordResetPasswordMutation();

  useEffect(() => {
    reset();
  }, [])

  useEffect(() => {
    const currentCount = countdown;
    const intervalID = setInterval(() => {
      if (currentCount > 0) {
        setCountdown(count => count - 1)
      }
    }, 1000)
    return () => clearInterval(intervalID);
  }, [countdown])

  const handleClickSend = () => {
    const inputUserID = watch("userId");
    if (!credential && !inputUserID) {
      enqueueSnackbar(t("app.settings.account.user-id-is-required"), { variant: "warning" });
      return;
    }
    const userID = credential ? credential.user_id : inputUserID;
    sendCode({ user_id: userID })
      .unwrap()
      .then(res => {
        if (res && !res.isFailed) {
          const data = res.result;
          if (data.phone) {
            const desc = t("app.settings.account.verification-code-sent-desc", { account: t("app.settings.account.phone"), value: data.phone });
            setDialogContent(desc);
            setOpenAlertDialog(true);
          } else if (data.email) {
            const desc = t("app.settings.account.verification-code-sent-desc", { account: t("app.settings.account.email"), value: data.email });
            setDialogContent(desc);
            setOpenAlertDialog(true);
          } else {
            enqueueSnackbar(t("app.settings.account.verification-code-sent"), { variant: "success" })
          }
          setCountdown(60);
        } else if (res?.resultCode === 201010) {
          enqueueSnackbar(t("app.settings.account.no-phone-email"), { variant: "error" })
        } else {
          enqueueSnackbar(res?.message || "Send code failed", { variant: "error" });
        }
      })
  }

  const handleClickConfirm = async (evt: FormEditPasswordInputs) => {
    if (evt.newPassword !== evt.newPasswordConfirm) {
      enqueueSnackbar(t("app.settings.account.new-password-not-same"), { variant: "warning" });
      return;
    }
    const userID = credential ? credential.user_id : evt.userId;
    const paramClientSalt = credential ? credential?.client_salt : await genRandom();;
    const newPasswordHash = createHash(evt.newPassword, paramClientSalt);
    updatePassword({ user_id: userID, client_salt: paramClientSalt, password_hash: newPasswordHash, code: evt.verifyCode })
      .unwrap()
      .then(res => {
        if (res && !res.isFailed) {
          onUpdateSuccess();
        } else {
          enqueueSnackbar(res?.message || "Update password failed", { variant: "error" });
        }
      })
  }

  return (
    <>
      <Box sx={{ width: "100%", display: "flex", flexDirection: "column", justifyContent: "start", alignItems: "start" }}>
        <Box sx={{ width: "100%" }}>
          {!credential &&
            <Controller
              control={control}
              name="userId"
              rules={{
                required: { value: !credential, message: t("app.settings.account.user-id-is-required") }
              }}
              defaultValue=""
              render={({ field, fieldState: { error } }) => (

                <CommonTextField
                  {...field}
                  sx={{ marginBottom: "24px" }}
                  type="text"
                  fullWidth
                  size='small'
                  placeholder={t("app.settings.account.account-id")}
                  error={error !== undefined}
                  helperText={error ? error.message : ""}
                />
              )}
            />
          }
          <Box sx={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "space-between" }}>

            <Controller
              control={control}
              name="verifyCode"
              rules={{
                required: t("app.settings.account.verification-code-is-required")
              }}
              defaultValue=""
              render={({ field, fieldState: { error } }) => (

                <CommonTextField
                  {...field}
                  sx={{ marginBottom: "24px" }}
                  fullWidth
                  type="text"
                  size='small'
                  placeholder={t("app.settings.account.verification-code")}
                  error={error !== undefined}
                  helperText={error ? error.message : ""}
                />
              )}
            />
            <OutlineButton sx={{ marginBottom: "24px", marginLeft: "12px" }} onClick={handleClickSend} disabled={countdown > 0}>
              {loadingSend ? <CircularProgress sx={{ width: "15px !important", height: "15px !important", color: "#3A92F9" }} /> : (countdown > 0 ? `${countdown} s` : t("app.button.send"))}
            </OutlineButton>
          </Box>
          <Controller
            control={control}
            name="newPassword"
            rules={{
              required: t("app.settings.account.new-password-is-required"),
              minLength: { value: 6, message: t("app.settings.account.password-min-length") },
              maxLength: { value: 50, message: t("app.settings.account.password-max-length") },
              pattern: { value: PASSWORD_FORMAT, message: t("app.settings.account.password-format-not-valid") }
            }}
            defaultValue=""
            render={({ field, fieldState: { error } }) => (

              <CommonTextField
                {...field}
                sx={{ marginBottom: "24px" }}
                type="password"
                fullWidth
                size='small'
                placeholder={t("app.settings.account.new-password")}
                error={error !== undefined}
                helperText={error ? error.message : ""}
              />
            )}
          />

          <Controller
            control={control}
            name="newPasswordConfirm"
            rules={{
              required: t("app.settings.account.confirm-password-is-required")
            }}
            defaultValue=""
            render={({ field, fieldState: { error } }) => (

              <CommonTextField
                {...field}
                sx={{ marginBottom: "24px" }}
                type="password"
                fullWidth
                size='small'
                placeholder={t("app.settings.account.confirm-new-password")}
                error={error !== undefined}
                helperText={error ? error.message : ""}
              />
            )}
          />
        </Box>

        <ConfirmButton sx={{ width: "100%" }} onClick={handleSubmit(handleClickConfirm)}>
          {loadingUpdate ? <CircularProgress sx={{ width: "15px !important", height: "15px !important", color: "white" }} /> : t("app.button.confirm")}
        </ConfirmButton>

        <Box className={classes.rulesTitle}>{t("app.settings.account.password-rules")}</Box>
        <Box className={classes.rulesText}>•&nbsp;{t("app.settings.account.password-rules-1")}</Box>
        <Box className={classes.rulesText}>•&nbsp;{t("app.settings.account.password-rules-2")}</Box>
        <Box className={classes.rulesText}>•&nbsp;{t("app.settings.account.password-rules-3")}</Box>


      </Box>
      <ConfirmDialog
        singleButton={true}
        open={openAlertDialog}
        handleClose={() => setOpenAlertDialog(false)}
        handleConfirm={() => setOpenAlertDialog(false)}
        dialogTitle={t("app.alert.alert-dialog-title-prompt")}
        dialogContent={dialogContent} />
    </>
  )

}

export default ForgotPasswordContent;