import { createApi } from "@reduxjs/toolkit/query/react";
import { Response, Result, Value } from "../../models/common";
import { buildQueryWithReAuth } from "../../tools/toolkit/helper";
import {
  TravelListProps,
  TravelListResponse,
  AdminPromoteTravelProps,
  AdminCancelPromoteTravelProps,
} from "src/models/travel";

export const adminTravelApi = createApi({
  reducerPath: "adminTravel",
  baseQuery: buildQueryWithReAuth(process.env.REACT_APP_ADMIN_API_URL),
  endpoints(builder) {
    return {
      // 获取旅游攻略列表
      getTravelList: builder.mutation<
        Result<TravelListResponse>,
        TravelListProps
      >({
        query: (TravelListProps) => ({
          url: `get_travel_list`,
          method: "POST",
          body: TravelListProps,
        }),
        transformResponse: (res: Response<TravelListResponse>) =>
          new Value(res),
      }),
      //推荐
      promoteTravel: builder.mutation<
        Result<undefined>,
        AdminPromoteTravelProps
      >({
        query: (AdminPromoteTravelProps) => ({
          url: `recommend_travel`,
          method: "POST",
          body: AdminPromoteTravelProps,
        }),
        transformResponse: (res: Response<undefined>) => new Value(res),
      }),
      //取消推荐
      cancelPromoteTravel: builder.mutation<
        Result<undefined>,
        AdminCancelPromoteTravelProps
      >({
        query: (AdminCancelPromoteTravelProps) => ({
          url: `cancel_recommend_travel`,
          method: "POST",
          body: AdminCancelPromoteTravelProps,
        }),
        transformResponse: (res: Response<undefined>) => new Value(res),
      }),
    };
  },
});

export const {
  useGetTravelListMutation,
  usePromoteTravelMutation,
  useCancelPromoteTravelMutation,
} = adminTravelApi;
