import { createApi } from "@reduxjs/toolkit/query/react";
import { Response, Result, Value } from "../../models/common";
import { buildQueryWithReAuth } from "../../tools/toolkit/helper";
import { ReportContentTypeEnum, SendPostEntity } from "src/models/post";
import { ReportCategoryItem } from "src/tools/utils/appUtil";
import { ReportsListResponseData } from "src/models/reports";

export const adminPostApi = createApi({
  reducerPath: "adminPost",
  baseQuery: buildQueryWithReAuth(process.env.REACT_APP_ADMIN_API_URL),
  endpoints(builder) {
    return {
      adminSendPost: builder.mutation<Result<any>, { entity: SendPostEntity }>({
        query: ({ entity }) => ({
          url: `adminmanamgement`,
          method: "POST",
          body: entity,
        }),
        transformResponse: (res: Response<any>) =>
          new Value(res, "send_message"),
      }),

      adminGetReportList: builder.mutation<
        Result<ReportsListResponseData>,
        {
          content_type: ReportContentTypeEnum;
          category: ReportCategoryItem | undefined;
          pageSize: number;
          startKey: string;
          forwardorder?: boolean;
        }
      >({
        query: ({
          content_type,
          category,
          pageSize,
          startKey,
          forwardorder,
        }) => ({
          url: `admin_report`,
          method: "POST",
          body: {
            type: 2,
            content_type,
            category,
            pagesize: pageSize,
            startkey: startKey,
            forwardorder,
          },
        }),
        transformResponse: (res: Response<ReportsListResponseData>) =>
          new Value(res),
      }),

      adminDeleteReport: builder.mutation<Result<any>, { content_id: string }>({
        query: ({ content_id }) => ({
          url: `admin_report`,
          method: "POST",
          body: {
            type: 1,
            content_id,
          },
        }),
        transformResponse: (res: Response<any>) => new Value(res),
      }),
      adminSetDes: builder.mutation<Result<any>, { 
        message_id: string,
        update_desc:string,
        url:string
       }>({
         query: ({ message_id, update_desc, url }) => ({
          url: `adminmanamgement`,
          method: "POST",
          body: {
            request_type:"update_ai_desc",
            request_data:{
              message_id,
              update_desc:{
                [url]: update_desc
              }
            }
          },
        }),
        transformResponse: (res: Response<any>) => new Value(res),
      }),
      // set offline
      setOffline: builder.mutation<
        Result<any>,
        {
          type: number;
          // type 1:set offline; 2:cancel offline
          message_id: string
        }
      >({
        query: ({ type, message_id }) => ({
          url: `adminmanamgement`,
          method: "POST",
          body: {
            request_type: "offline",
            request_data: {
              type,
              message_id
            }
          },
        }),
        transformResponse: (res: Response<any>) => new Value(res),
      }),
    };
  },
});

export const {
  useAdminSendPostMutation,
  useAdminGetReportListMutation,
  useAdminDeleteReportMutation,
  useAdminSetDesMutation,
  useSetOfflineMutation
} = adminPostApi;
